import {Component, Injector, Input, OnInit} from '@angular/core';
import {ModelLookupTableDto, ModelsServiceProxy, PowerPlant} from "@shared/service-proxies/service-proxies";
import {EditWidgetConfigDto} from "@app/state-management/models/edit-widget-config";
import {AppComponentBase} from "@shared/common/app-component-base";
import {Store} from "@node_modules/@ngrx/store";
import * as _ from "lodash";
import {EditWidgetAction} from "@app/state-management/actions/edit-widget.action";
import {UnitModelWidgetConfigDto} from "@app/shared/models/UnitModelWidgetConfigDto";
import {GeneralWidgetConfigDto} from "@app/shared/models/GeneralWidgetConfigDto";

@Component({
    selector: 'edit-unit-model',
    templateUrl: './unit-model.component.html',
    styleUrls: ['./unit-model.component.css']
})
export class EditUnitModelComponent extends AppComponentBase implements OnInit {

    @Input() powerPlant: PowerPlant;

    config: EditWidgetConfigDto;
    unitModels: ModelLookupTableDto[] = [];
    selectedUnitModelId: number;
    defaultWidgetName = "";

    constructor(
        injector: Injector,
        private _modelsService: ModelsServiceProxy,
        private _store: Store
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this._store.select((state) => state).subscribe((state: any) => {
            if (!_.isNil(state.editWidget.data.unitModel)) {
                this.config = state.editWidget.data;
                if (this.config.unitModel.visible) {
                    this._modelsService.getAllModelsForLookupTable(this.powerPlant.id).subscribe((result) => {
                        this.unitModels = result;
                        if (this.config.unitModel.unitModelId) {
                            this.selectedUnitModelId = this.config.unitModel.unitModelId;
                        } else {
                            this.selectedUnitModelId = this.unitModels[0].id;
                        }
                    });

                    if (this.config.defaultWidgetName)
                        this.defaultWidgetName = this.config.defaultWidgetName;
                }
            }
        });
    }

    onUnitModelChange(unitModelId: number) {
        let dto: UnitModelWidgetConfigDto = {
            visible: true,
            unitModelId: Number(unitModelId)
        };

        const generalDto = <GeneralWidgetConfigDto>{
            visible: this.config.general.visible,
            description: this.config.general.description
        };
        const unitModel = this.unitModels.find(x => x.id == unitModelId);
        if (unitModel) {
            generalDto.name = this.defaultWidgetName + " - " + unitModel.name;
        }

        let item = Object.assign(new EditWidgetConfigDto(), {
            divId: this.config.divId,
            widgetId: this.config.widgetId,
            general: generalDto,
            datasource: this.config.datasource,
            series: this.config.series,
            format: this.config.format,
            axex: this.config.axex,
            unitModel: dto,
            baseComponent: this.config.baseComponent

        });

        this._store.dispatch(EditWidgetAction(item));
        abp.event.trigger(this.config.divId + "-unit-model", dto);
        abp.event.trigger(this.config.divId + "-general", generalDto);
    }
}
