import { Component, Injector, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import * as Highcharts from "@node_modules/highcharts";
import { AxisLabelsFormatterCallbackFunction, TooltipOptions } from "@node_modules/highcharts";
import HighchartsData from "highcharts/modules/data";
import HighchartsAccessibility from "highcharts/modules/accessibility";
import { SeriesOptionsType } from "@node_modules/highcharts/highstock";
import { DashboardItemWidgetType } from "@app/shared/common/widget/widget-consts";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ChartService } from "@app/shared/common/dashboard-charts/chart.service";

HighchartsData(Highcharts);
HighchartsAccessibility(Highcharts);

export interface StackedColumnData {
    subtitle?: string;
    categories?: string[];
    series?: SeriesOptionsType[];
    formatter?: AxisLabelsFormatterCallbackFunction;
    tooltip?: TooltipOptions;
}

@Component({
    selector: "app-stacked-column-chart",
    templateUrl: "./stacked-column-chart.component.html",
    styleUrls: ["./stacked-column-chart.component.scss"],
})
export class StackedColumnChartComponent extends AppComponentBase implements OnInit, OnChanges {
    @Input() divId: string;
    @Input() chartName: string;
    @Input() stackedColumnData: StackedColumnData;
    @Input() completedEventId: string;
    @Input() legendEnabled: boolean = true;

    chart: Highcharts.Chart;
    options: Highcharts.Options;

    constructor(injector: Injector, private _chartService: ChartService) {
        super(injector);
    }

    ngOnInit(): void {
        this.setOptions();
        let chartElement = document.querySelector("#" + this.divId + " #" + this.chartName);
        // @ts-ignore
        this.chart = Highcharts.chart(chartElement, this.options);

        // Retry işlemini gerçekleştiren bir fonksiyon tanımlayın
        this._chartService.retryUntilSuccess(
            1000, // İnterval (ms)
            10, // Maksimum deneme sayısı
            () => Highcharts.charts.find((v) => v["renderTo"].id == this.chartName) !== undefined, // Koşul
            () => {
                const StackedColumnChart = Highcharts.charts.find((v) => v["renderTo"].id == this.chartName);
                if (StackedColumnChart) {
                    StackedColumnChart.reflow();
                }
            }
        );

        setTimeout(() => {
            abp.event.trigger(this.completedEventId, {
                isCompleted: true,
                highChart: this.chart,
                type: DashboardItemWidgetType.chart,
            });
        }, 2000);
    }

    ngOnChanges(changes: SimpleChanges) {
        if ("stackedColumnData" in changes && !changes.stackedColumnData.firstChange) {
            this.chart.update({
                ...this.options,
                xAxis: {
                    categories: this.stackedColumnData.categories,
                },
                tooltip: this.stackedColumnData.tooltip,
                series: this.stackedColumnData.series,
            });
        }
    }

    setOptions() {
        this.options = {
            chart: {
                type: "column",
            },
            title: {
                text: null,
            },
            subtitle: {
                text: this.stackedColumnData.subtitle,
            },
            xAxis: {
                categories: this.stackedColumnData.categories,
            },
            yAxis: {
                min: 0,
                labels: {
                    formatter: this.stackedColumnData.formatter,
                },
                title: null,
            },
            legend: {
                enabled: this.legendEnabled,
                align: "left",
                verticalAlign: "bottom",
                floating: false,
                backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || "white",
                borderColor: "#CCC",
                borderWidth: 1,
                shadow: false,
            },
            tooltip: this.stackedColumnData.tooltip,
            plotOptions: {
                column: {
                    stacking: "normal",
                    dataLabels: {
                        enabled: true,
                    },
                },
            },
            series: this.stackedColumnData.series,
            credits: {
                enabled: false,
            },
        };
    }
}
