<div bsModal #createOrEditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="createOrEditModal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="reviewu47productu47dialogform screen">
                <div class="dialog dialog-2">
                    <div class="ic_tabler_database-plus"></div>
                    <div class="title">Add New Data</div>
                    <div class="close" (click)="close()" >
                        <div class="icon-button-2 icon-button-3" >
                            <img class="iconsic_close"
                                 src="/assets/common/images/edit-widget/data/new-data/icons-ic-close-1.svg"
                                 alt="icons/ic_close"/>
                        </div>
                    </div>
                </div>
                <div class="stack-1 stack-2">
                    <div class="frame-149 frame-1">
                        <div class="text-field" onclick="" onmouseover="">
                            <input type="text" class="input" placeholder="Search data..."
                                   (input)="getBaseComponent()" [(ngModel)]="this.filterText"/>
                        </div>
                    </div>
                    <button class="button-1" (click)="clearSearch()"
                            *ngIf="filterText != ''"
                            style="border: none; background-color: rgba(255, 86, 48, 0.161); padding: 6px 12px; height: 35px;">
                        <img class="x-icon" src="/assets/new-widget/start-icon-14.svg" alt="start icon"/>
                        <div class="label-55 label-9">{{l("Clear")}}</div>
                    </button>
                </div>
                <div class="frame-151 frame-1">
                    <div class="frame-3 frame-1">
                        <div class="data-grid-row" style="cursor: pointer" *ngFor="let content of baseComponents;let index = index"
                             [class]="content.isSelected ? 'data-grid-row select-active ':'data-grid-row'" >
                            <div class="table-cell" (click)="changeSelection(content)">
                                <div class="stack stack-2">
                                    <div class=""  [class]="content.isSelected ? 'name2-3 select-active-text publicsans-semi-bold-pale-sky-14px':'name2-3 publicsans-semi-bold-pale-sky-14px'" >{{content.name}}</div>
                                </div>
                            </div>
                            <div class="table-cell-2 table-cell-3">
                                <div class="icon-button-1 icon-button-3">
                                    <div class="checkbox">
                                        <div class="icon-container icon">
                                            <input id='select-{{content.name}}' type="checkbox"
                                                   name="ppu_content" [checked]="content.isSelected"
                                                   (change)="changeSelection(content)"
                                                   class="form-check-input"
                                                   [disabled]="disabled && !content.isSelected"
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dialog-1 dialog-2">
                    <div class="button button-2">
                        <div class="label valign-text-middle label-2" (click)="close()">Cancel</div>
                    </div>
                    <div class="button-1 button-2">
                        <div class="label-1 valign-text-middle label-2" (click)="addDataAxis()">Add Data</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

