<!-- role=list necessary because WebKit removes list semantic when list-style-type: none -->
<ol class="toc-list" [style]="containerStyle" role="list" id="{{pageId}}">
    <li *ngFor="let page of this.tocTree">
        <a>
            <span class="title">{{page.header}}<span class="leaders" aria-hidden="true"></span></span>
            <span data-href="#Promise-Basics" class="page"><span
                class="visually-hidden">Page&nbsp;</span>{{page.index + 1}}</span>
        </a>
        <ol role="list">
            <li *ngFor="let section of page.children">
                <a>
                    <span class="title">{{section.header}}<span class="leaders"
                                                                                  aria-hidden="true"></span></span>
                    <span data-href="#Promise-Basics-The-Promise-Lifecycle" class="page"><span
                        class="visually-hidden">Page&nbsp;</span>{{section.index + 1}}</span>
                </a>
            </li>
        </ol>
    </li>
</ol>
