import { AssetDetail } from "@app/main/map/map.model";

export class MapModel {
    isDetailDrawerOpen?: boolean = false;
    isCompanyViewActive?: boolean = false;
    isUnitViewActive?: boolean = false;
    isActiveViewActive?: boolean = false;
    location?: [number, number] = [0, 0];
    assetDetail?: AssetDetail;
}

export class MapLocationModel {
    location: [number, number] = [0, 0];
}

