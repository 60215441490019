export function ShowOverlay(overlayName: any, animationName: any) {
    overlayName = "overlay-" + overlayName;
    let cssClasses = document.getElementById(overlayName).className.split(" ");
    let last = cssClasses.slice(-1)[0];
    if (last.lastIndexOf("animate") == -1) {
        document.getElementById(overlayName).className =
            document.getElementById(overlayName).className + " " + animationName;
    }
}

export function HideOverlay(overlayName, animationName) {
    overlayName = "overlay-" + overlayName;
    let cssClasses = document.getElementById(overlayName).className.split(" ");
    let last = cssClasses.slice(-1)[0];
    if (last.lastIndexOf("animate") != -1) {
        cssClasses.splice(-1);
        cssClasses.push(animationName);
        document.getElementById(overlayName).className = cssClasses.join(" ");

        cssClasses.splice(-1);
        setTimeout(function () {
            document.getElementById(overlayName).className = cssClasses.join(" ");
        }, 0);
    }
    let vids = document.getElementsByTagName("video");
    if (vids) {
        for (let i = 0; i < vids.length; i++) {
            let video = vids.item(i);
            video.pause();
        }
    }
}

export function runEveryFullHours(callbackFn: Function) {
    const Hour = 60 * 60 * 1000;
    const currentDate = new Date();
    const firstCall = Hour - (currentDate.getMinutes() * 60 + currentDate.getSeconds()) * 1000 - currentDate.getMilliseconds();
    console.log("Second call is after " + firstCall / 1000 + " seconds.");
    callbackFn();
    setTimeout(() => {
        setInterval(callbackFn, Hour);
    }, firstCall);
}
