<div class="format-content screen">
    <div class="stack">
        <div class="col-md-3">
            <div class="user-account-update-user-form">
                <div class="specification publicsans-bold-charade-12px">TEXT</div>
                <div class="stack-1 stack-5">
                    <div class="stack-2 stack-5">
                        <div class="slider">
                            <div class="custom-slider">
<!--                                <ngx-slider [(value)]="value" [options]="options"></ngx-slider>-->

                            </div>
                        </div>
                        <div class="text-field">
                            <div class="input">
                                <div class="container-1">
                                    <div class="label-focus label-4 publicsans-normal-manatee-12px">Font Size</div>
                                    <div class="content">
                                        <input value="12" class="name1 publicsans-normal-charade-16px input-form">
                                    </div>
                                </div>
                                <div class="space-m"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-9">
            <div class="user-account-update-user-form-1">
                <div class="specification publicsans-bold-charade-12px">LEGEND</div>
                <div class="format-legend">
                    <div class="switch">
                        <label class="toggle-switch">
                            <input type="checkbox" [(ngModel)]="selected">
                            <div class="f-slider"></div>
                        </label>
                        <div class="label-end label-4 publicsans-normal-charade-14px">Match title case</div>
                    </div>
                    <div class="text-field-1">
                        <div class="input-1">
                            <div class="container-1">
                                <div class="label-focus">Position</div>
                                <div class="content"><div class="name1">Top Right</div></div>
                            </div>
                            <div class="space-s space"></div>
                        </div>
                        <img class="select-arrow" src="assets/common/images/edit-widget/format/select-arrow.svg" alt="select arrow">
                    </div>
                </div>
                <div class="format">
                    <div class="specification-1 publicsans-bold-charade-12px">ORIENTATION</div>
                    <div class="stack-3 stack-5">
                        <article class="radio-button">
                            <div class="icon-container">
                                <label>
                                    <input name="orientation" [checked]="true" value="1" type="radio" />
                                    <svg class="svg" fill="currentColor" preserveAspectRatio="xMidYMid meet" height="20px" width="20px" viewBox="0 0 34 34">
                                        <circle class="radioOutline" cx="17" cy="17" r="15" fill="none" stroke="#890C58" stroke-width="3" />
                                        <circle class="radioDot" cx="17" cy="17" r="8" fill="#890C58" />
                                    </svg>
                                </label>
                            </div>
                            <div class="label publicsans-normal-charade-14px">Horizontal</div>
                        </article>
                        <article class="radio-button">
                            <div class="icon-container">
                                <label>
                                    <input name="orientation" value="1" type="radio" />
                                    <svg class="svg" fill="currentColor" preserveAspectRatio="xMidYMid meet" height="20px" width="20px" viewBox="0 0 34 34">
                                        <circle class="radioOutline" cx="17" cy="17" r="15" fill="none" stroke="#890C58" stroke-width="3" />
                                        <circle class="radioDot" cx="17" cy="17" r="8" fill="#890C58" />
                                    </svg>

                                </label>
                            </div>
                            <div class="label publicsans-normal-charade-14px">Vertical</div>
                        </article>
                    </div>
                </div>
                <div class="format">
                    <div class="specification-1 publicsans-bold-charade-12px">PLACEMENT</div>
                    <div class="stack-4 stack-5">
                        <div class="group">
                            <article class="radio-button">
                                <div class="icon-container">
                                    <label>
                                        <input name="placament" [checked]="true" value="1" type="radio" />
                                        <svg class="svg" fill="currentColor" preserveAspectRatio="xMidYMid meet" height="20px" width="20px" viewBox="0 0 34 34">
                                            <circle class="radioOutline" cx="17" cy="17" r="15" fill="none" stroke="#890C58" stroke-width="3" />
                                            <circle class="radioDot" cx="17" cy="17" r="8" fill="#890C58" />
                                        </svg>
                                    </label>                                </div>
                                <div class="label publicsans-normal-charade-14px">Inside Chart</div>
                            </article>
                            <article class="radio-button">
                                <div class="icon-container">
                                    <label>
                                        <input name="placament" value="1" type="radio" />
                                        <svg class="svg" fill="currentColor" preserveAspectRatio="xMidYMid meet" height="20px" width="20px" viewBox="0 0 34 34">
                                            <circle class="radioOutline" cx="17" cy="17" r="15" fill="none" stroke="#890C58" stroke-width="3" />
                                            <circle class="radioDot" cx="17" cy="17" r="8" fill="#890C58" />
                                        </svg>
                                    </label>                                </div>
                                <div class="label publicsans-normal-charade-14px">Outside Chart</div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
