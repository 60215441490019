<div *ngIf="isTooltipEnabled" class="range-slider-tooltip position-absolute rounded text-nowrap text-white p-2"></div>
<div class="range-slider-container h-100 w-100">
    <input
        [(ngModel)]="leftValue"
        (mouseup)="onLeftHandleSlide($event)"
        (keydown)="$event.preventDefault()"
        [min]="min"
        [max]="max"
        [step]="step"
        id="left-handle-input"
        name="LeftHandleInput"
        class="form-range h-100 w-100 position-absolute pe-none"
        type="range">
    <input
        [(ngModel)]="rightValue"
        (mouseup)="onRightHandleSlide($event)"
        (keydown)="$event.preventDefault()"
        [min]="min"
        [max]="max"
        [step]="step"
        id="right-handle-input"
        name="RightHandleInput"
        class="form-range h-100 w-100 position-absolute pe-none"
        type="range">
</div>
