<div class="table-of-content-context screen">
    <div class="stack">
        <div class="docs-small-title02">
            <div class="title">SECTIONS</div>
            <div class="space"></div>
        </div>
        <div class="frame-181 publicsans-normal-charade-13px">
            <article *ngFor="let item of sections" (click)="clickSectionChips(item)"
                     [className]="getChipsClassName(item)">
                <div class="label-container">
                    <div class="label">{{item.title}} {{item.header}}</div>
                </div>
                <img class="iconsic" alt="{{item.header}}" [src]="getChipsIcon(item)">
            </article>
        </div>
    </div>
    <div class="stack-1">
        <div class="docs-small-title02">
            <div class="title">PAGES</div>
            <div class="space"></div>
        </div>
        <div class="frame-181 publicsans-normal-charade-13px">
            <article *ngFor="let item of pages" (click)="clickPagesChips(item)"
                     [className]="getChipsClassName(item)">
                <div class="label-container">
                    <div class="label">{{item.title}} {{item.header}}</div>
                </div>
                <img class="iconsic" [src]="getChipsIcon(item)"
                     alt="icons/ic_remove">
            </article>

        </div>
    </div>
</div>
