<div class="menu menu-column menu-rounded menu-sub-indention px-3"
     id="#kt_app_sidebar_menu"
     data-kt-menu="true">
    <div class="top">
        <ng-container [ngTemplateOutlet]="menuListTemplate"></ng-container>
    </div>
</div>
<div class="sub">
    <ng-container [ngTemplateOutlet]="menuSubListTemplate"></ng-container>
    <user-menu></user-menu>
</div>


<ng-template #menuSubListTemplate>
    <ng-container *ngFor="let child of subMenu.items">
        <ng-container
            *ngIf="showMenuItem(child)"
            [ngTemplateOutlet]="mMenuItem"
            [ngTemplateOutletContext]="{ item: child, parentItem: null }"
        ></ng-container>
    </ng-container>
</ng-template>

<ng-template #menuListTemplate>
    <ng-container *ngFor="let child of menu.items">
        <ng-container
            *ngIf="showMenuItem(child)"
            [ngTemplateOutlet]="mMenuItem"
            [ngTemplateOutletContext]="{ item: child, parentItem: null }"
        ></ng-container>
    </ng-container>
</ng-template>

<ng-template #mMenuItem let-item="item" let-parentItem="parentItem">
    <div
        *ngIf="showMenuItem(item)"
        [attr.data-kt-menu-trigger]="item.items.length ? 'click' : null"
        [ngClass]="getItemCssClasses(item, parentItem)"
        [id]="item.route"
    >
        <!-- if menu item hasn't submenu -->
        <a
            *ngIf="!item.items.length && !item.external"
            [queryParams]="!item.isUnitNotification ? item.parameters: null"
            [routerLink]="!item.isUnitNotification && item.route? item.route: null"
            routerLinkActive="active"
            class="menu-link"
            id="{{item.name}}"
            [ngClass]="{ 'without-sub': !item.items.length }"
            [tooltip]="iconMenu && parentItem == null ? l(item.name): ''"
        >
            <ng-container
                [ngTemplateOutlet]="mMenuItemText"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem}"
            ></ng-container>
        </a>
        <a
            *ngIf="!item.items.length && item.external"
            [attr.href]="item.route"
            id="{{item.name}}"
            target="_blank"
            class="menu-link"
        >
            <ng-container
                [ngTemplateOutlet]="mMenuItemText"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"
            ></ng-container>
        </a>

        <!-- if menu item has submenu child  -->
        <a
            href="javascript:;"
            *ngIf="item.items.length"
            class="menu-link menu-toggle"
            [class]="{ active: isMenuItemIsActive(item) }"
        >
            <ng-container
                [ngTemplateOutlet]="mMenuItemText"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"
            ></ng-container>
        </a>

        <div class="line" *ngIf="(item.name == 'Unit Notification')">
            <div class="line-inner"></div>
        </div>
        <div *ngIf="item.items.length" [class]="getSubMenuItemCssClass(item, parentItem)">
            <div class="menu-subnav">
                <ng-container *ngFor="let child of item.items">
                    <ng-container
                        [ngTemplateOutlet]="mMenuItem"
                        [ngTemplateOutletContext]="{ item: child, parentItem: item }"
                    ></ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #mMenuItemText let-item="item" let-parentItem="parentItem">
    <div class="item-sub" (click)="openUnitStatusDrawer(item)" *ngIf="item.isSubMenu">

        <ul class="sub-menu-list">
            <ng-container *ngIf="!item.badge; else mMenuLinkBadge">
                <li [class]="{ active: isMenuItemIsActive(item) }">
                    <div class="text-sub text-nowrap" *ngIf="!iconMenu || parentItem != null">
                        <div *ngIf="item.icon" [innerHTML]="getIsUnitActiveIcon(item)">
                        </div>
                        <div class="sub-menu-text"
                             [class]="{ active: isMenuItemIsActive(item) }"> {{ item.name | localize }}
                            <span *ngIf="item.isAlerts && alertDataCount>0" class="menu-number-detail">{{alertDataCount}}</span>
                        </div>
                    </div>
                </li>
            </ng-container>
        </ul>
        <span *ngIf="item.items.length" class="arrow"></span>

    </div>

    <div *ngIf="!item.isSubMenu" class="item-menu-1" (click)="openUnitStatusDrawer(item)"
         [class]="{ active: isMenuItemIsActive(item) }">
        <div class="item-menu-1-left">
            <div class="menu-icon" *ngIf="(!item.isUnitNotification&& item.icon)"
                 style="margin-left: auto !important; margin-right: auto !important;"
                 [innerHTML]="isMenuItemIsActive(item)===false ? (item.icon | safe) : ((item.activeIcon===undefined ?  item.icon : item.activeIcon) | safe) ">
            </div>

            <div class="menu-icon" *ngIf="item.isUnitNotification && item.icon" [innerHTML]="getIsUnitActiveIcon(item)"
                 style="margin-left: auto !important; margin-right: auto !important;">
            </div>
            <span *ngIf="item.isAlerts && alertDataCount>0" class="menu-number">{{alertDataCount}}</span>
        </div>

        <div class="item-menu-1-right">
            <ng-container *ngIf="!item.badge; else mMenuLinkBadge">
                <div class="menu-title" *ngIf="!iconMenu || parentItem != null"
                     [class]="{ active: isMenuItemIsActive(item) }">{{ item.name | localize }}
                    <span *ngIf="item.isAlerts && alertDataCount>0" class="menu-number-detail">{{alertDataCount}}</span>
                    <span *ngIf="item.items.length && (!iconMenu || parentItem != null)" class="arrow">
                       <span class="down"> ↓ </span>
                       <span class="up"> ↑ </span>
                    </span>
                </div>
            </ng-container>
            <span *ngIf="item.isNotification" class="number">{{unitStatusNotificationCount}}</span>
        </div>
    </div>

    <ng-template #mMenuLinkBadge>
        <!-- menu item with badge -->
        <span class="menu-link-title">
            <span class="menu-link-wrap">
                <span class="menu-text">
                    {{ item.name | localize }}
                </span>
                <span class="menu-link-badge">
                    <span class="badge badge-primary" [ngClass]="item.badge.type">{{ item.badge.value }}</span>
                </span>
            </span>
        </span>
    </ng-template>
    <!--    <span *ngIf="item.items.length && (!iconMenu || parentItem != null)" class="menu-arrow"></span>-->
</ng-template>
<!-- END: Left Aside -->
