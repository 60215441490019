export class SeriesWidgetConfigDto {
    visible: boolean;
    selectedComponent: SelectedBaseComponentDto[]
}

export class SelectedBaseComponentDto {
    id: number;
    name: string;
    unit?: string;
}
