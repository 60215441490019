import { v4 as uuidv4 } from 'uuid';
import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'app-checkbox-theme',
  templateUrl: './checkbox-theme.component.html',
  styleUrls: ['./checkbox-theme.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxThemeComponent),
            multi: true
        }
    ]
})
export class CheckboxThemeComponent implements ControlValueAccessor {
    @Input() label: string;
    @Input() instanceId: string = uuidv4();
    @Input() value: boolean = false;
    @Input() disabled: boolean;
    @Input() intermediate: boolean;

    onChange = (value: any) => {};
    onTouched = () => {};

    writeValue(value: any): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    onValueChange($event: Event) {
        const target = $event.target as HTMLInputElement;
        this.onChange(target.checked);
        this.onTouched();
    }
}
