import {Action, createReducer, on} from "@node_modules/@ngrx/store";
import {WidgetFilter} from "@app/state-management/models/add-widget";
import {GetWidgetFilterAction} from "@app/state-management/actions/add-widget.action";

// State Type
export interface IWidgetFiltersState {
    data: WidgetFilter;
}

// Initial State
export const initialState: IWidgetFiltersState = {
    data: new WidgetFilter
}

// Reducer
export const WidgetReducer = createReducer(initialState,
    on(GetWidgetFilterAction, (state, data) => {
        const newState: IWidgetFiltersState = {
            data: data
        }
        return newState;
    })
);

export function reducer(state: IWidgetFiltersState | undefined, action: Action) {
    return WidgetReducer(state, action);
}
