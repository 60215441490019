import { Component, Injector } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { Router } from "@angular/router";

@Component({
    selector: "app-page-not-found",
    templateUrl: "./page-not-found.component.html",
    styleUrls: ["./page-not-found.component.css"]
})
export class PageNotFoundComponent extends AppComponentBase {

    constructor(injector: Injector, private router: Router
    ) {
        super(injector);
    }

    goToHome() {
        this.router.navigate(["/app/main/dashboards"]);
    }
}
