import { Injectable } from "@angular/core";
import { AppLocalizationService } from "@app/shared/common/localization/app-localization.service";
import { DateTime } from "luxon";

@Injectable()
export class DateTimeService {
    constructor(private _appLocalizationService: AppLocalizationService) {
    }

    createDateRangePickerOptions(): any {
        let options = {
            locale: {
                format: "L",
                applyLabel: this._appLocalizationService.l("Apply"),
                cancelLabel: this._appLocalizationService.l("Cancel"),
                customRangeLabel: this._appLocalizationService.l("CustomRange")
            },
            min: this.fromISODateString("2015-05-01"),
            minDate: this.fromISODateString("2015-05-01"),
            max: this.getDate(),
            maxDate: this.getDate(),
            opens: "left",
            ranges: {}
        };

        options.ranges[this._appLocalizationService.l("Today")] = [this.getStartOfDay(), this.getEndOfDay()];
        options.ranges[this._appLocalizationService.l("Yesterday")] = [
            this.minusDays(this.getStartOfDay(), 1),
            this.minusDays(this.getEndOfDay(), 1)
        ];
        options.ranges[this._appLocalizationService.l("Last7Days")] = [
            this.minusDays(this.getStartOfDay(), 6),
            this.getEndOfDay()
        ];
        options.ranges[this._appLocalizationService.l("Last30Days")] = [
            this.minusDays(this.getStartOfDay(), 29),
            this.getEndOfDay()
        ];
        options.ranges[this._appLocalizationService.l("ThisMonth")] = [
            this.getDate().startOf("month"),
            this.getDate().endOf("month")
        ];
        options.ranges[this._appLocalizationService.l("LastMonth")] = [
            this.getDate().startOf("month").minus({ months: 1 }),
            this.getDate().endOf("month").minus({ months: 1 })
        ];

        return options;
    }

    getDate(): DateTime {
        if (abp.clock.provider.supportsMultipleTimezone) {
            return DateTime.local().setZone(abp.timing.timeZoneInfo.iana.timeZoneId);
        } else {
            return DateTime.local();
        }
    }

    getTimezoneOffset1(date: Date) {
        return date.getTimezoneOffset();
    }

    getUTCDate(): DateTime {
        return DateTime.utc();
    }

    getYear(): number {
        return this.getDate().year;
    }

    getMonth(): number {
        return this.getDate().month;
    }

    getDay(): number {
        return this.getDate().day;
    }

    getStartOfDay(): DateTime {
        if (abp.timing.timeZoneInfo)
            return this.getDate().setZone(abp.timing.timeZoneInfo.iana.timeZoneId).startOf("day");
    }

    getStartOfYear(): DateTime {
        return this.getDate().setZone(abp.timing.timeZoneInfo.iana.timeZoneId).startOf("year");
    }

    getEndOfTheYear(date: DateTime): DateTime {
        return date.setZone(abp.timing.timeZoneInfo.iana.timeZoneId).startOf("year");
    }

    getStartOfWeek(): DateTime {
        return this.getDate().startOf("week");
    }

    getEndOfWeek(): DateTime {
        return this.getDate().endOf("week");
    }

    getEndOfTheWeek(date: DateTime): DateTime {
        return date.endOf("week");
    }

    getStartOfMonth(): DateTime {
        return this.getDate().setZone(abp.timing.timeZoneInfo.iana.timeZoneId).startOf("month");
    }

    getEndOfMonth(): DateTime {
        return this.getDate().setZone(abp.timing.timeZoneInfo.iana.timeZoneId).endOf("month");
    }

    getEndOfTheMonth(date: DateTime): DateTime {
        return date.setZone(abp.timing.timeZoneInfo.iana.timeZoneId).endOf("month");
    }

    getStartOfDayForDate(date: DateTime | Date, withDefaultZoneBehaviour?: boolean): DateTime {
        if (!date) {
            return date as DateTime;
        }

        if (date instanceof Date) {
            return this.getStartOfDayForDate(this.fromJSDate(date));
        }

        if (withDefaultZoneBehaviour) {
            return date.startOf("day");
        } else {
            return date.setZone(abp.timing.timeZoneInfo.iana.timeZoneId).startOf("day");
        }
    }

    getStartOfDayMinusDays(daysFromNow: number): DateTime {
        let date = this.getDate();
        let newDate = this.minusDays(date, daysFromNow);
        return this.getStartOfDayForDate(newDate);
    }

    getEndOfDay(withDefaultZoneBehaviour?: boolean): DateTime {
        if (withDefaultZoneBehaviour) {
            return this.getDate().endOf("day");
        } else {
            return this.getDate().setZone(abp.timing.timeZoneInfo.iana.timeZoneId).endOf("day");
        }
    }

    getEndOfTheDay(date: DateTime, withDefaultZoneBehaviour?: boolean): DateTime {
        if (withDefaultZoneBehaviour) {
            return date.endOf("day");
        } else {
            return date.setZone(abp.timing.timeZoneInfo.iana.timeZoneId).endOf("day");
        }
    }

    getEndOfYear(): DateTime {
        return this.getDate().setZone(abp.timing.timeZoneInfo.iana.timeZoneId).endOf("year");
    }

    getEndOfDayForDate(date: DateTime | Date, withDefaultZoneBehaviour?: boolean): DateTime {
        if (!date) {
            return date as DateTime;
        }

        if (date instanceof Date) {
            return this.getEndOfDayForDate(this.fromJSDate(date));
        }

        if (withDefaultZoneBehaviour) {
            return date.endOf("day");
        } else {
            return date.setZone(abp.timing.timeZoneInfo.iana.timeZoneId).endOf("day");
        }
    }

    getEndOfDayPlusDays(daysFromNow: number): DateTime {
        let date = this.getDate();
        let newDate = this.plusDays(date, daysFromNow);
        return this.getEndOfDayForDate(newDate);
    }

    getEndOfDayMinusDays(daysFromNow: number): DateTime {
        let date = this.getDate();
        let newDate = this.minusDays(date, daysFromNow);
        return this.getEndOfDayForDate(newDate);
    }

    plusDays(date: DateTime | Date, dayCount: number): DateTime {
        if (date instanceof Date) {
            return this.plusDays(this.fromJSDate(date), dayCount);
        }

        if (date)
            return date.plus({ days: dayCount });
    }

    plusMonths(date: DateTime | Date, month: number): DateTime {
        if (date instanceof Date) {
            return this.plusMonths(this.fromJSDate(date), month);
        }

        return date.plus({ months: month });
    }

    plusYears(date: DateTime | Date, month: number): DateTime {
        if (date instanceof Date) {
            return this.plusYears(this.fromJSDate(date), month);
        }

        return date.plus({ years: month });
    }

    plusHours(date: DateTime | Date, hourCount: number): DateTime {
        if (date instanceof Date) {
            return this.plusHours(this.fromJSDate(date), hourCount);
        }

        return date.plus({ hours: hourCount });
    }

    plusSeconds(date: DateTime, seconds: number) {
        if (!date) {
            return date;
        }

        if (date instanceof Date) {
            return this.plusSeconds(this.fromJSDate(date), seconds);
        }

        return date.plus({ seconds: seconds });
    }

    minusDays(date: DateTime, dayCount: number): DateTime {
        return date.minus({ days: dayCount });
    }

    fromISODateString(date: string): DateTime {
        return DateTime.fromISO(date);
    }

    formatISODateString(dateText: string, format: string): string {
        let date = this.fromISODateString(dateText);
        return date.toFormat(format);
    }

    formatJSDate(jsDate: Date, format: string): string {
        let date = DateTime.fromJSDate(jsDate);
        return date.toFormat(format);
    }

    formatDate(date: DateTime | Date, format: string): string {
        if (date instanceof Date) {
            return this.formatDate(this.fromJSDate(date), format);
        }

        return date.toFormat(format);
    }

    getDiffInSeconds(maxDate: DateTime | Date, minDate: DateTime | Date) {
        if (maxDate instanceof Date && minDate instanceof Date) {
            return this.getDiffInSeconds(this.fromJSDate(maxDate), this.fromJSDate(minDate));
        }

        return (maxDate as DateTime).diff(minDate as DateTime, "seconds");
    }

    createJSDate(year: number, month: number, day: number): Date {
        return this.createDate(year, month, day).toJSDate();
    }

    createDate(year: number, month: number, day: number): DateTime {
        if (abp.clock.provider.supportsMultipleTimezone) {
            return DateTime.utc(year, month + 1, day);
        } else {
            return DateTime.local(year, month + 1, day);
        }
    }

    createUtcDate(year: number, month: number, day: number): DateTime {
        return DateTime.utc(year, month + 1, day);
    }

    toUtcDate(date: DateTime | Date): DateTime {
        if (date instanceof Date) {
            return this.createUtcDate(date.getFullYear(), date.getMonth(), date.getDate());
        }

        return (date as DateTime).toUTC();
    }

    fromJSDate(date: Date): DateTime {
        return DateTime.fromJSDate(date);
    }

    fromNow(date: DateTime | Date): string {
        if (date instanceof Date) {
            return this.fromNow(this.fromJSDate(date));
        }

        return date.toRelative();
    }

    getTimezoneOffset(ianaTimezoneId: string): number {
        var hourAndMinuteOffset = DateTime.fromJSDate(new Date(), { zone: ianaTimezoneId }).toFormat("ZZ");
        var multiplier = hourAndMinuteOffset[0] == "-" ? -1 : +1;
        var hourParts = hourAndMinuteOffset.replace("-", "").replace("+", "").split(":");
        var hourOffset = hourParts[0];
        var minuteOffset = hourParts[1];
        return multiplier * (parseInt(hourOffset) * 60 + parseInt(minuteOffset));
    }

    // only changes timezone of given date without changing the date itself
    changeTimeZone(date: Date, ianaTimezoneId: string): Date {
        var utcDateString = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
        var currentTimezoneString = DateTime.fromJSDate(new Date(), { zone: ianaTimezoneId }).toFormat("ZZ");
        var dateStringWithoutTimezome = utcDateString.substring(0, utcDateString.length - 1) + currentTimezoneString;
        return DateTime.fromISO(dateStringWithoutTimezome).toJSDate();
    }

    getStartOfYearForDate(date: DateTime | Date): DateTime {
        if (!date) {
            return date as DateTime;
        }

        if (date instanceof Date) {
            return this.getStartOfYearForDate(this.fromJSDate(date));
        }

        return date.setZone(abp.timing.timeZoneInfo.iana.timeZoneId).startOf("year");
    }

    getEndOfYearForDate(date: DateTime | Date): DateTime {
        if (!date) {
            return date as DateTime;
        }

        if (date instanceof Date) {
            return this.getEndOfYearForDate(this.fromJSDate(date));
        }

        return date.setZone(abp.timing.timeZoneInfo.iana.timeZoneId).endOf("year");
    }
}
