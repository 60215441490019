import {Component, EventEmitter, Injector, Input, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {ModalDirective} from "@node_modules/ngx-bootstrap/modal";
import {AppComponentBase} from "@shared/common/app-component-base";
import {
    DashboardStatus,
    ReportDashboardDto,
    ReportDashboardServiceProxy
} from "@shared/service-proxies/service-proxies";

@Component({
    selector: 'deleteDashboardModal',
    templateUrl: './delete-dashboard.component.html',
    styleUrls: ['./delete-dashboard.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class DeleteDashboardComponent extends AppComponentBase {
    @ViewChild('createOrEditModal', {static: true}) modal: ModalDirective;
    @Output() updateDashboard: EventEmitter<any> = new EventEmitter<any>();
    @Input() pageName = "";
    dashboard: ReportDashboardDto;
    saving = false;

    constructor(
        injector: Injector,
        private _reportDashboardServiceProxy: ReportDashboardServiceProxy,
    ) {
        super(injector);
    }

    show(item: ReportDashboardDto): void {
        this.dashboard = item;
        this.saving = false;
        this.modal.show();
    }

    close(): void {
        this.saving = true;
        this.modal.hide();
    }

    delete() {
        this.dashboard.status = DashboardStatus.Deleted;
        this._reportDashboardServiceProxy.updateReportDashboardStatus(this.dashboard).subscribe((data) => {
            this.saving = true;
            this.notify.info(this.l('SavedSuccessfully'));
            this.updateDashboard.emit(this.dashboard);
            this.modal.hide();
        });


    }

    archiveInstead() {
        this.dashboard.status = DashboardStatus.Archived;
        this._reportDashboardServiceProxy.updateReportDashboardStatus(this.dashboard).subscribe((data) => {
            this.notify.info(this.l('SavedSuccessfully'));
            this.saving = true;
            this.updateDashboard.emit(this.dashboard);
            this.modal.hide();
        });
    }
}
