import { Component, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { PowerPlanUnitFilters } from "@app/main/reports/report-overview/report-overview.model";

@Component({
    selector: 'app-power-plant-unit-selection',
    templateUrl: './power-plant-unit-selection.component.html',
    styleUrls: ['./power-plant-unit-selection.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: PowerPlantUnitSelectionComponent,
            multi: true
        },
    ]
})
export class PowerPlantUnitSelectionComponent implements ControlValueAccessor {
    @Input() activated = true;
    @Input() powerPlantUnits: PowerPlanUnitFilters[] = [];
    @Input() multiple = false;

    value: string;
    isDisabled: boolean;

    private onChange: any;
    private onTouched: any;

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    writeValue(value: number): void {
        this.value = value?.toString();
    }

    getAssetButtonClassName(defaultClass: string): string {
        return this.activated ? "button-active" : defaultClass;
    }

    getAssetLabelClassName(defaultClass: string) {
        return this.activated ? "label-active" : defaultClass;
    }

    getAssetSrc(defaultUrl: string) {
        return this.activated ? "assets/common/images/report/widget/end-icon-1.svg" : defaultUrl;
    }

    getAssetIcon(startIcon: string) {
        return this.activated ? startIcon + "-active" : startIcon;
    }

    onPowerPlantUnitSelect(id: number) {
        if (this.multiple) {
            this.powerPlantUnits.find(x => x.id === id).selected = true;
        } else {
            for (let powerPlantUnit of this.powerPlantUnits) {
                powerPlantUnit.selected = powerPlantUnit.id === id;
            }
        }
        this.updateSelectedValues();
    }

    isAllFiltersSelected() {
        return this.powerPlantUnits.every(x => x.selected);
    }

    toggleOptionAllSelect() {
        this.powerPlantUnits = this.powerPlantUnits.map(item => ({ ...item, selected: true }))
        this.updateSelectedValues();
    }

    private updateSelectedValues() {
        this.onTouched();
        this.onChange(this.powerPlantUnits.filter(x => x.selected).map(x => x.id));
    }

    getLabels() {
        if (this.isAllFiltersSelected()) return 'All';

        const selected = this.powerPlantUnits.filter(x => x.selected);

        if (!selected.length) return 'Unit';

        return selected.map(x => x.value).join(', ');
    }
}
