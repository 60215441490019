export class AppConsts {
    static readonly tenancyNamePlaceHolderInUrl = "{TENANCY_NAME}";

    static remoteServiceBaseUrl: string;

    static remoteOnePactIotDataConsumerServiceBaseUrl: string;
    static remoteServiceWsUrl: string;
    static remoteServiceBaseUrlFormat: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    static appBaseUrlFormat: string;
    static recaptchaSiteKey: string;
    static subscriptionExpireNootifyDayCount: number;
    static webSocketUrl: string;
    static iotDataRemoteServiceWsUrl: string;
    static downtimeServiceWsUrl: string;
    static alertServiceWsUrl: string;

    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: "admin",
    };

    static readonly localization = {
        defaultLocalizationSourceName: "IoTApp",
    };

    static readonly authorization = {
        encrptedAuthTokenName: "enc_auth_token",
    };

    static readonly grid = {
        defaultPageSize: 10,
    };

    static readonly MinimumUpgradePaymentAmount = 1;
    static readonly UnitStatusNotificationMenuName = "Unit Notification";

    /// <summary>
    /// Gets current version of the application.
    /// It's also shown in the web page.
    /// </summary>
    static readonly WebAppGuiVersion = "11.3.0";
}
