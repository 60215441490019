import { Component, HostListener, Injector, Input, OnInit, SimpleChanges } from "@angular/core";
import * as Highcharts from "@node_modules/highcharts";
import { SeriesOptionsType } from "@node_modules/highcharts/highstock";
import { DashboardItemWidgetType } from "@app/shared/common/widget/widget-consts";
import { ChartService } from "@app/shared/common/dashboard-charts/chart.service";
import { AppComponentBase } from "@shared/common/app-component-base";

export interface LineChartColumnData {
    categories?: string[];
    series?: SeriesOptionsType[];
    isTickActive?: boolean;
    xAxisTitle?: string;
    yAxisTitle?: string;
    isMarkerEnable?: boolean;
    isDataLabels?: boolean;
}

export interface MultiScaleOptions {
    yAxisTitle1?: string;
    yAxisTitle2?: string;
    yAxisLabel2?: string;
}

@Component({
    selector: "app-line-chart",
    templateUrl: "./line-chart.component.html",
    styleUrls: ["./line-chart.component.css"],
})
export class LineChartComponent extends AppComponentBase implements OnInit {
    @Input() divId: string;
    @Input() chartName: string;
    @Input() lineChartColumnData: LineChartColumnData;
    public innerWidth: number;
    chart: Highcharts.Chart;
    options: Highcharts.Options;
    @Input() completedEventId: string;
    @Input() isMultiScale: boolean = false;
    @Input() multiScaleOption: MultiScaleOptions;

    constructor(injector: Injector, private _chartService: ChartService) {
        super(injector);
    }

    @HostListener("window:resize", ["$event"])
    onResize(event) {
        this.innerWidth = window.innerWidth;
    }

    ngOnInit() {
        this.initChart();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.lineChartColumnData) {
            this.initChart();
        }
    }

    getChartOptions() {
        this.options = {
            chart: {
                type: "line",
            },
            credits: {
                enabled: false,
            },
            title: {
                text: "",
            },
            subtitle: {
                text: "",
            },
            yAxis: this.getYaxisConfig(),
            xAxis: {
                title: {
                    text: this.lineChartColumnData.xAxisTitle ?? "Time",
                },
                categories: this.lineChartColumnData.categories,
                showLastLabel: true,
            },
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: this.lineChartColumnData.isDataLabels ?? true,
                    },
                    enableMouseTracking: false,
                    marker: {
                        enabled: this.lineChartColumnData.isMarkerEnable ?? true,
                    },
                },
                series: {
                    pointPlacement: "on",
                },
            },
            series: this.lineChartColumnData.series,
            exporting: {
                enabled: true,
            },
        };
    }

    selectAllSeries() {
        this.chart.series.forEach((series) => {
            if (!series.visible) {
                series.show();
            }
        });
    }

    unSelectAllSeries() {
        this.chart.series.forEach((series) => {
            series.hide();
        });
    }

    private initChart() {
        this.getChartOptions();
        this.innerWidth = window.innerWidth;

        let chartElement = document.querySelector("#" + this.divId + " #" + this.chartName);

        // @ts-ignore
        this.chart = Highcharts.chart(chartElement, this.options);

        // Retry işlemini gerçekleştiren bir fonksiyon tanımlayın
        this._chartService.retryUntilSuccess(
            1000, // İnterval (ms)
            20, // Maksimum deneme sayısı
            () => Highcharts.charts.find((v) => v["renderTo"].id == this.chartName) !== undefined, // Koşul
            () => {
                const lineChart = Highcharts.charts.find((v) => v["renderTo"].id == this.chartName);
                if (lineChart) {
                    lineChart.reflow();
                }
            }
        );

        setTimeout(() => {
            abp.event.trigger(this.completedEventId, {
                isCompleted: true,
                highChart: this.chart,
                type: DashboardItemWidgetType.chart,
            });
        }, 1000);
    }

    private getYaxisConfig() {
        const primaryAxis = {
            title: {
                text: this.lineChartColumnData.yAxisTitle ?? "Time",
            },
        };

        if (!this.isMultiScale) {
            return [primaryAxis];
        }

        const secondaryAxis = {
            title: {
                text: this.multiScaleOption.yAxisTitle2 ?? "",
            },
            labels: {
                format: `{value} ${this.multiScaleOption.yAxisLabel2 ?? ""}`,
            },
            opposite: true,
        };

        return [primaryAxis, secondaryAxis];
    }
}
