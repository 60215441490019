import { Injectable } from "@angular/core";
import { FileDto, FileServiceProxy, TempFileDto } from "@shared/service-proxies/service-proxies";

@Injectable()
export class FileDownloadService {

    constructor(private _fileService: FileServiceProxy) { }

    downloadTempFile(file: FileDto) {
        const dto = new TempFileDto({fileToken: file.fileToken});
        this._fileService.downloadTempFile(dto).subscribe(result => {
            const byteCharacters = window.atob(result.fileContents);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const blob = new Blob([new Uint8Array(byteNumbers)], { type: file.fileType });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = file.fileName;
            link.click();
        });
    }
}
