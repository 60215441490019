import { Component, HostListener, Injector, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import * as Highcharts from "@node_modules/highcharts";
import HC_exporting from "@node_modules/highcharts/modules/exporting";
import { DashboardItemWidgetType } from "@app/shared/common/widget/widget-consts";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ChartService } from "@app/shared/common/dashboard-charts/chart.service";

let Boost = require("highcharts/modules/boost");
let noData = require("highcharts/modules/no-data-to-display");
let More = require("highcharts/highcharts-more");
let HighchartsSolidGauge = require("highcharts/modules/solid-gauge");

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
HighchartsSolidGauge(Highcharts);
HC_exporting(Highcharts);

@Component({
    selector: "app-solidgauge-chart",
    templateUrl: "./solidgauge-chart.component.html",
    styleUrls: ["./solidgauge-chart.component.css"],
})
export class SolidgaugeChartComponent extends AppComponentBase implements OnInit, OnChanges {
    @Input() divId: string;
    @Input() chartName: string;
    @Input() unitType: string;
    @Input() data: number;
    @Input() callbackFunction: (args: any) => void;
    public innerWidth: number;
    @Input() maxLimit: number;

    @Input() chartSize?: string;
    @Input() chartCenter?: any;
    @Input() completedEventId: string;
    @Input() comeFrom?: string;

    highchart: Highcharts.Chart;
    private valueTextFontSize: string;

    constructor(injector: Injector, private _chartService: ChartService) {
        super(injector);
    }

    @HostListener("window:resize", ["$event"])
    onResize(event) {
        this.innerWidth = window.innerWidth;
        this.initializeChart();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.data) {
            // @Input değeri değiştiğinde yapılacak işlemleri burada gerçekleştirin

            if (this.data) {
                let point;

                if (this.highchart) {
                    point = this.highchart.series[0].points[0];

                    point.update(this.data);
                    setTimeout(() => {
                        abp.event.trigger(this.completedEventId, {
                            isCompleted: true,
                            highChart: this.highchart,
                            type: DashboardItemWidgetType.chart,
                        });
                    }, 1000);
                }
            }
        }
    }

    getChartOptions() {
        console.log("this.innerWidth:: " + this.innerWidth);
        return {
            chart: {
                type: "solidgauge",
                zoomType: "x",
            },

            title: null,

            pane: {
                center: this.comeFrom == "Report" ? this.getCenterReport() : this.getCenterDashboard(),
                size: this.comeFrom == "Report" ? this.getSizeReport() : this.getSizeDashboard(),
                startAngle: -90,
                endAngle: 90,
                background: {
                    backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || "#EEE",
                    innerRadius: "60%",
                    outerRadius: "100%",
                    shape: "arc",
                },
            },

            exporting: {
                enabled: false,
            },

            tooltip: {
                enabled: false,
            },

            // the value axis
            yAxis: {
                minColor: "#74BC54",
                maxColor: "#74BC54",

                lineWidth: 0,
                tickWidth: 0,
                minorTickInterval: null,
                tickAmount: 0.0,
                labels: {
                    y: 16,
                },
                title: {
                    y: -100,
                },
            },

            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        y: -25,
                        borderWidth: 0,
                        useHTML: true,
                    },
                },
            },
        };
    }

    ngOnInit() {
        this.initializeChart();
    }

    initializeChart() {
        this.innerWidth = window.innerWidth;
        let chart = document.querySelector("#" + this.divId + " #" + this.chartName);
        this.valueTextFontSize = this.completedEventId ? "25" : "14";

        // @ts-ignore
        this.highchart = Highcharts.chart(
            // @ts-ignore
            chart,
            Highcharts.merge(this.getChartOptions(), {
                yAxis: {
                    min: 0,
                    max: this.maxLimit,
                    tickInterval: 0,
                    tickWidth: 0,
                    labels: {
                        formatter: function () {
                            return Number(this.value).toFixed(2);
                        },
                        style: {
                            "font-size": this.completedEventId ? 20 : 14,
                        },
                    },
                },

                credits: {
                    enabled: false,
                },
                series: [
                    {
                        data: [this.data > 0 ? this.data : 0],
                        dataLabels: {
                            format:
                                this.chartName == "production_chart"
                                    ? '<span style="font-size:' +
                                      this.valueTextFontSize +
                                      'px;opacity:0.4">{y} ' +
                                      this.unitType +
                                      "</span>"
                                    : '<span style="font-size:' + this.valueTextFontSize + 'px;opacity:0.4">{y} %</span>',
                        },
                        tooltip: {
                            valueSuffix: this.chartName == "production_chart" ? this.unitType : "%",
                        },
                    },
                ],
            })
        );

        // Retry işlemini gerçekleştiren bir fonksiyon tanımlayın
        this._chartService.retryUntilSuccess(
            1000, // İnterval (ms)
            10, // Maksimum deneme sayısı
            () => Highcharts.charts.find((v) => v["renderTo"].id == this.chartName) !== undefined, // Koşul
            () => {
                const solidGaugeChart = Highcharts.charts.find((v) => v["renderTo"].id == this.chartName);
                if (solidGaugeChart) {
                    solidGaugeChart.reflow();
                }
            }
        );

        setTimeout(() => {
            abp.event.trigger(this.completedEventId, {
                isCompleted: true,
                highChart: this.highchart,
                type: DashboardItemWidgetType.chart,
            });
        }, 1000);
    }

    private getCenterDashboard() {
        const breakpoints = [
            { width: 1000, percentage: 5 },
            { width: 1100, percentage: 10 },
            { width: 1200, percentage: 15 },
            { width: 1300, percentage: 20 },
            { width: 1500, percentage: 27 },
            { width: 1600, percentage: 30 },
            { width: 1700, percentage: 35 },
            { width: 1800, percentage: 35 },
            { width: 1900, percentage: 45 },
            { width: 2000, percentage: 40 },
            { width: Infinity, percentage: 50 },
        ];

        const breakpoint = breakpoints.find((bp) => this.innerWidth < bp.width);
        const percentage = breakpoint ? breakpoint.percentage : 50;

        return ["50%", percentage + "%"];
    }

    private getSizeDashboard(): string {
        const breakpoints = [
            { width: 1000, size: "25%" },
            { width: 1100, size: "30%" },
            { width: 1200, size: "35%" },
            { width: 1300, size: "40%" },
            { width: 1400, size: "45%" },
            { width: 1500, size: "50%" },
            { width: 1600, size: "55%" },
            { width: 1700, size: "60%" },
            { width: 1800, size: "65%" },
            { width: 2000, size: "70%" },
        ];

        const breakpoint = breakpoints.find((bp) => this.innerWidth < bp.width);
        return breakpoint ? breakpoint.size : "100%";
    }

    private getCenterReport() {
        return ["50%", "70%"];
    }

    private getSizeReport(): string {
        return "100%";
    }
}
