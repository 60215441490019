import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[appDoubleClick]'
})
export class DoubleClickDirective {
    @Output() onDoubleClick: EventEmitter<any> = new EventEmitter<any>();

    constructor() { }

    @HostListener('dblclick') onDoubleClicked() {
        this.onDoubleClick.emit();
    }
}
