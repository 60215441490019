import { PermissionCheckerService } from "abp-ng2-module";
import {
    Injector,
    ElementRef,
    Component,
    OnInit,
    ViewEncapsulation,
    Renderer2,
    AfterViewInit,
    Input
} from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { AppMenu } from "./app-menu";
import { AppNavigationService } from "./app-navigation.service";
import { NavigationEnd, NavigationCancel, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { FormattedStringValueExtracter } from "@shared/helpers/FormattedStringValueExtracter";
import * as objectPath from "object-path";
import { AppMenuItem } from "./app-menu-item";
import { MenuComponent, DrawerComponent, ToggleComponent, ScrollComponent } from "@metronic/app/kt/components";
import { LocalStorageService } from "@shared/utils/local-storage.service";
import { AlertDashboardServiceProxy } from "@shared/service-proxies/service-proxies";

@Component({
    templateUrl: "./side-bar-menu.component.html",
    selector: "side-bar-menu",
    encapsulation: ViewEncapsulation.None
})
export class SideBarMenuComponent extends AppComponentBase implements OnInit, AfterViewInit {
    @Input() iconMenu = false;

    menu: AppMenu = null;
    subMenu: AppMenu = null;
    currentRouteUrl = "";
    insideTm: any;
    outsideTm: any;
    unitStatusNotificationActive: boolean = false;
    unitStatusNotificationCount: number;
    alertDataCount: number;

    constructor(
        injector: Injector,
        private el: ElementRef,
        private router: Router,
        public permission: PermissionCheckerService,
        private _appNavigationService: AppNavigationService,
        private render: Renderer2,
        private _localStorageService: LocalStorageService,
        private readonly alertDashboardServiceProxy: AlertDashboardServiceProxy
    ) {
        super(injector);
    }

    ngOnInit() {
        this.menu = this._appNavigationService.getMenu();
        this.subMenu = this._appNavigationService.getSubMenu();
        this.currentRouteUrl = this.router.url.split(/[?#]/)[0];

        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => (this.currentRouteUrl = this.router.url.split(/[?#]/)[0]));

        this.router.events.pipe(filter((event) => event instanceof NavigationEnd || event instanceof NavigationCancel)).subscribe((event) => {
            this.reinitializeMenu();
        });

        this.getAlertsCount();

        setInterval(() => {
            let width = document.getElementById("kt_aside").offsetWidth;
            if (width < 80) {
                let items = document.querySelectorAll(".item-menu-1.active");
                items.forEach((item) => {
                    item.classList.add("active-1");
                    item.classList.remove("active");
                    let left = item.querySelector(".item-menu-1-left");
                    left.classList.add("item-menu-1-left-1");
                    left.classList.remove("item-menu-1-left");
                    let right = item.querySelector(".item-menu-1-right");
                    right.classList.add("item-menu-1-right-disable");
                    right.classList.remove("item-menu-1-right");
                });

                let showItems = document.querySelectorAll(".menu-item.menu-accordion.show");
                showItems.forEach((showItem) => {
                    showItem.classList.remove("show");
                });
                let menuNumbers = document.querySelectorAll(".menu-number-disable");
                menuNumbers.forEach((menuNumber) => {
                    menuNumber.classList.remove("menu-number-disable");
                    menuNumber.classList.add("menu-number");
                });

                let showMenus = document.querySelectorAll(".menu-sub.menu-sub-accordion.ng-star-inserted.show");
                showMenus.forEach((showMenu) => {
                    showMenu.classList.remove("show");
                });

            } else {
                let items = document.querySelectorAll(".item-menu-1.active-1");
                items.forEach((item) => {
                    item.classList.remove("active-1");
                    item.classList.add("active");
                    let left = item.querySelector(".item-menu-1-left-1");
                    left.classList.remove("item-menu-1-left-1");
                    left.classList.add("item-menu-1-left");
                    let right = item.querySelector(".item-menu-1-right-disable");
                    right.classList.remove("item-menu-1-right-disable");
                    right.classList.add("item-menu-1-right");
                });

                let menuNumbers = document.querySelectorAll(".menu-number");
                menuNumbers.forEach((menuNumber) => {
                    menuNumber.classList.add("menu-number-disable");
                    menuNumber.classList.remove("menu-number");
                });
            }
        }, 150);
    }

    private getAlertsCount() {
        this.alertDashboardServiceProxy
            .getNonAcknowledgedAlerts()
            .subscribe(data => {
                this.alertDataCount = data.length;
            });
    }

    ngAfterViewInit(): void {
        this.scrollToCurrentMenuElement();
    }

    reinitializeMenu(): void {
        this.getAlertsCount();
        this.menu = this._appNavigationService.getMenu();
        this.subMenu = this._appNavigationService.getSubMenu();
        this.currentRouteUrl = this.router.url.split(/[?#]/)[0];

        setTimeout(() => {
            MenuComponent.reinitialization();
            DrawerComponent.reinitialization();
            ToggleComponent.reinitialization();
            ScrollComponent.reinitialization();
        }, 50);


    }

    showMenuItem(menuItem): boolean {
        return this._appNavigationService.showMenuItem(menuItem);
    }

    isMenuItemIsActive(item): boolean {
        if (item.items.length) {
            return this.isMenuRootItemIsActive(item);
        }

        if (!item.route) {
            return false;
        }

        let urlTree = this.router.parseUrl(this.currentRouteUrl.replace(/\/$/, ""));
        let urlString = "/" + urlTree.root.children.primary.segments.map((segment) => segment.path).join("/");
        let exactMatch = urlString === item.route.replace(/\/$/, "");
        if (!exactMatch && item.routeTemplates) {
            for (let i = 0; i < item.routeTemplates.length; i++) {
                let result = new FormattedStringValueExtracter().Extract(urlString, item.routeTemplates[i]);
                if (result.IsMatch) {
                    return true;
                }
            }
        }
        return exactMatch;
    }

    isMenuRootItemIsActive(item): boolean {
        let result = false;

        for (const subItem of item.items) {
            result = this.isMenuItemIsActive(subItem);
            if (result) {
                return true;
            }
        }

        return false;
    }

    scrollToCurrentMenuElement(): void {
        const path = location.pathname;
        const menuItem = document.querySelector("a[href='" + path + "']");
        if (menuItem) {
            menuItem.scrollIntoView({ block: "center" });
        }
    }

    getItemCssClasses(item: AppMenuItem, parentItem: AppMenuItem) {
        let classes = "menu-item";

        if (item.items.length) {
            if (!this.iconMenu) {
                classes += " menu-accordion";
            } else {
                if (parentItem == null) {
                    classes += " menu-dropdown";
                } else {
                    classes += " menu-accordion";
                }
            }
        }

        // custom class for menu item
        const customClass = objectPath.get(item, "custom-class");
        if (customClass) {
            classes += " " + customClass;
        }

        if (this.iconMenu && parentItem == null) {
            classes += " pb-3";
        }


        if (!this.iconMenu && this.isMenuItemIsActive(item)) {
            let width = document.getElementById("kt_aside").offsetWidth;
            if (width > 75) {
                classes += " show";
            } else {
                classes += " ";
            }
        }
        return classes;
    }

    getSubMenuItemCssClass(item: AppMenuItem, parentItem: AppMenuItem): string {
        let classes = "menu-sub";

        if (!this.iconMenu) {
            classes += " menu-sub-accordion";
        } else {
            if (parentItem == null) {
                classes += " menu-sub-dropdown px-1 py-4";
            } else {
                classes += " menu-sub-accordion";
            }
        }

        return classes;
    }

    openUnitStatusDrawer(item: AppMenuItem) {
        const element = document.getElementById(item.name);

        this._localStorageService.getItem("unitStatusNotificationActive", (err, value) => {
            if (value) {
                if (item.isUnitNotification) {
                    this.unitStatusNotificationActive = !this.unitStatusNotificationActive;
                } else {
                    this.unitStatusNotificationActive = false;
                }

                if (this.unitStatusNotificationActive) {
                    element?.classList.add("active");
                } else {
                    element?.classList.remove("active");
                }

                abp.event.trigger("unit-status-notification-drawer", this.unitStatusNotificationActive);
            } else {
                this._localStorageService.setItem("unitStatusNotificationActive", !this.unitStatusNotificationActive);
            }
        });
    }

    getIsUnitActiveIcon(item: AppMenuItem) {
        if (this.unitStatusNotificationActive) {
            return item.activeIcon;
        } else {
            return item.icon;
        }
    }
}
