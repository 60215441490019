import { GridStackOptions } from "@node_modules/gridstack";
import { PowerPlantType, WindUnitStatus } from "@shared/service-proxies/service-proxies";
import { HydroUnitStatus, SolarUnitStatus } from "@app/main/map/map.model";
import { PortfolioFilterStateModel } from "@app/main/portfolio/portfolio.model";

export const PortfolioGridOptions: GridStackOptions = {
    disableDrag: false,
    disableResize: true,
    // maxRow: 36,
    // cellHeight:36
};

export const PortfolioFilterState: PortfolioFilterStateModel[] = [
    {
        key: WindUnitStatus.InProduction,
        selectedClassName: "toggle-button-production-checked toggle-button-standalone-3",
        unSelectedClassName: "toggle-button-standalone toggle-button-standalone-3",

        hoveredClassName: "toggle-button-standalone-prod toggle-button-standalone-3",

        unSelectedIconClassName: "ic_tabler_alert-prod",
        selectedIconClassName: "ic_tabler_alert-prod-checked",

        unSelectedBadgeIconClassName: "badge-production",
        selectedBadgeIconClassName: "badge-production-checked",

        unSelectedBadgeTextClassName: "number valign-text-middle number-2 publicsans-bold-white-11px",
        selectedBadgeTextClassName: "number valign-text-middle number-2 publicsans-bold-prod-11px",

        state: "selected",
        status:"InProduction"
    },
    {
        key: WindUnitStatus.Warning,
        selectedClassName: "toggle-button-warning-checked toggle-button-standalone-3",
        hoveredClassName: "toggle-button-standalone-warning toggle-button-standalone-3",

        unSelectedClassName: "toggle-button-standalone toggle-button-standalone-3",

        unSelectedIconClassName: "ic_tabler_alert-triangle",
        selectedIconClassName: "ic_tabler_alert-triangle-checked",

        unSelectedBadgeIconClassName: "badge-warning",
        selectedBadgeIconClassName: "badge-warning-checked",

        unSelectedBadgeTextClassName: "number valign-text-middle number-2 publicsans-bold-white-11px",
        selectedBadgeTextClassName: "number valign-text-middle number-2 publicsans-bold-warning-11px",

        state: "selected",
        status:"Warning"
    },
    {
        key: WindUnitStatus.LackOfWind,
        selectedClassName: "toggle-button-standstill-checked toggle-button-standalone-3",
        hoveredClassName: "toggle-button-standalone-lacoff toggle-button-standalone-3",

        unSelectedClassName: "toggle-button-standalone toggle-button-standalone-3",

        unSelectedIconClassName: "ic_tabler_zzz",
        selectedIconClassName: "ic_tabler_zzz-checked",

        unSelectedBadgeIconClassName: "badge-standstill",
        selectedBadgeIconClassName: "badge-standstill-checked",

        unSelectedBadgeTextClassName: "number valign-text-middle number-2 publicsans-bold-white-11px",
        selectedBadgeTextClassName: "number valign-text-middle number-2 publicsans-bold-lacoff-11px",

        state: "selected",
        status:"LackOfWind"
    },
    {
        key: WindUnitStatus.OutOfCommunication,
        selectedClassName: "toggle-button-outoffcom-checked toggle-button-standalone-3",
        hoveredClassName: "toggle-button-standalone-outoff toggle-button-standalone-3",

        unSelectedClassName: "toggle-button-standalone toggle-button-standalone-3",

        unSelectedIconClassName: "ic_tabler_antenna-bars-off",
        selectedIconClassName: "ic_tabler_antenna-bars-off-checked",

        unSelectedBadgeIconClassName: "badge-outoffcom",
        selectedBadgeIconClassName: "badge-outoffcom-checked",

        unSelectedBadgeTextClassName: "number valign-text-middle number-2 publicsans-bold-white-11px",
        selectedBadgeTextClassName: "number valign-text-middle number-2 publicsans-bold-outoff-11px",

        state: "selected",
        status:"OutOfCommunication"
    },
    {
        key: WindUnitStatus.Curtailment,
        selectedClassName: "toggle-button-curtailment-checked toggle-button-standalone-3",
        hoveredClassName: "toggle-button-standalone-curtailment toggle-button-standalone-3",

        unSelectedClassName: "toggle-button-standalone toggle-button-standalone-3",

        unSelectedIconClassName: "ic_tabler_plane-arrival",
        selectedIconClassName: "ic_tabler_plane-arrival-checked",

        unSelectedBadgeIconClassName: "badge-curtailment",
        selectedBadgeIconClassName: "badge-curtailment-checked",

        unSelectedBadgeTextClassName: "number valign-text-middle number-2 publicsans-bold-white-11px",
        selectedBadgeTextClassName: "number valign-text-middle number-2 publicsans-bold-curetailment-11px",

        state: "selected",
        status:"Curtailment"
    },
    {
        key: WindUnitStatus.Maintenance,
        selectedClassName: "toggle-button-maintenance-checked toggle-button-standalone-3",
        hoveredClassName: "toggle-button-standalone-maintenance toggle-button-standalone-3",

        unSelectedClassName: "toggle-button-standalone toggle-button-standalone-3",

        unSelectedIconClassName: "ic_tabler_settings-bolt",
        selectedIconClassName: "ic_tabler_settings-bolt-checked",

        unSelectedBadgeIconClassName: "badge-curtailment-1",
        selectedBadgeIconClassName: "badge-maintenance-checked",

        unSelectedBadgeTextClassName: "number valign-text-middle number-2 publicsans-bold-white-11px",
        selectedBadgeTextClassName: "number valign-text-middle number-2 publicsans-bold-maintenance-11px",

        state: "selected",
        status:"Maintenance"
    },
];

export const PowerPlantTechnology = [
    { name: "Hydro", value: 10 },
    { name: "Wind", value: 20 },
    { name: "Solar", value: 30 },
    { name: "NaturalGas", value: 40 },
    { name: "Coal", value: 50 },
];

export const UnitStatusClasses = {
    [PowerPlantType.Wind]: {
        [WindUnitStatus.InProduction]: "badge-status-production badge-status",
        [WindUnitStatus.LackOfWind]: "badge-status-lack-of-wind badge-status",
        [WindUnitStatus.Maintenance]: "badge-status-maintenance badge-status",
        [WindUnitStatus.Warning]: "badge-status-warning badge-status",
        [WindUnitStatus.Curtailment]: "badge-status-curtailment badge-status",
        [WindUnitStatus.OutOfCommunication]: "badge-status-out-of-com badge-status",
    },
    [PowerPlantType.Hydro]: {
        [HydroUnitStatus.InProduction]: "badge-status-production badge-status",
        [HydroUnitStatus.Standstill]: "badge-status-standstill badge-status",
        [HydroUnitStatus.Maintenance]: "badge-status-maintenance badge-status",
        [HydroUnitStatus.Warning]: "badge-status-warning badge-status",
        [HydroUnitStatus.OutOfCommunication]: "badge-status-out-of-com badge-status",
    },
    [PowerPlantType.Solar]: {
        [SolarUnitStatus.InProduction]: "badge-status-production badge-status",
        [SolarUnitStatus.Standstill]: "badge-status-standstill badge-status",
        [SolarUnitStatus.Warning]: "badge-status-warning badge-status",
    },
};

export const UnitStatusBackgroundClasses = {
    [PowerPlantType.Wind]: {
        [WindUnitStatus.InProduction]: "badge-status-production-background badge-status",
        [WindUnitStatus.LackOfWind]: "badge-status-lack-of-wind-background badge-status",
        [WindUnitStatus.Maintenance]: "badge-status-maintenance-background badge-status",
        [WindUnitStatus.Warning]: "badge-status-warning-background badge-status",
        [WindUnitStatus.Curtailment]: "badge-status-curtailment-background badge-status",
        [WindUnitStatus.OutOfCommunication]: "badge-status-out-of-com-background badge-status",
    },
    [PowerPlantType.Hydro]: {
        [HydroUnitStatus.InProduction]: "badge-status-production-background badge-status",
        [HydroUnitStatus.Standstill]: "badge-status-standstill-background badge-status",
        [HydroUnitStatus.Maintenance]: "badge-status-maintenance-background badge-status",
        [HydroUnitStatus.Warning]: "badge-status-warning-background badge-status",
        [HydroUnitStatus.OutOfCommunication]: "badge-status-out-of-com-background badge-status",
    },
    [PowerPlantType.Solar]: {
        [SolarUnitStatus.InProduction]: "badge-status-production-background badge-status",
        [SolarUnitStatus.Standstill]: "badge-status-standstill-background badge-status",
        [SolarUnitStatus.Warning]: "badge-status-warning-background badge-status",
    },
};

export const UnitStatusNames = {
    [PowerPlantType.Wind]: {
        [WindUnitStatus.InProduction]: false,
        [WindUnitStatus.LackOfWind]: false,
        [WindUnitStatus.Maintenance]: false,
        [WindUnitStatus.Warning]: true,
        [WindUnitStatus.Curtailment]: false,
        [WindUnitStatus.OutOfCommunication]:false,
    },
    [PowerPlantType.Hydro]: {
        [HydroUnitStatus.InProduction]:false,
        [HydroUnitStatus.Standstill]: true,
        [HydroUnitStatus.Maintenance]: false,
        [HydroUnitStatus.Warning]: true,
        [HydroUnitStatus.OutOfCommunication]: false,
    },
    [PowerPlantType.Solar]: {
        [SolarUnitStatus.InProduction]:false,
        [SolarUnitStatus.Standstill]: true,
        [SolarUnitStatus.Warning]: true,
    },
};



