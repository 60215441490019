<div class="set-size charts-container">
    <div [class]="circleClass">
        <span class="label">{{this.percentage}}<span>%</span></span>
        <div class="pie">
            <div class="left-side half-circle"></div>
            <div class="right-side half-circle"></div>
        </div>
        <div class="shadow"></div>
    </div>
</div>
