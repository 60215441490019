import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';

@Component({
    selector: 'app-slide-toggle-theme',
    templateUrl: './slide-toggle-theme.component.html',
    styleUrls: ['./slide-toggle-theme.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SlideToggleThemeComponent),
            multi: true
        }
    ]
})
export class SlideToggleThemeComponent implements ControlValueAccessor {
    @Input() label: string;
    @Input() value: boolean = false;
    @Input() instanceId: string = uuidv4();

    disabled: boolean;

    onChange = (value: any) => {};
    onTouched = () => {};

    writeValue(value: any): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    onValueChange($event: Event) {
        const target = $event.target as HTMLInputElement;
        this.onChange(target.checked);
    }
}
