import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import * as Highcharts from "@node_modules/highcharts";
import HC_exporting from "@node_modules/highcharts/modules/exporting";
import { DashboardItemWidgetType } from "@app/shared/common/widget/widget-consts";

let Boost = require("highcharts/modules/boost");
let noData = require("highcharts/modules/no-data-to-display");
let More = require("highcharts/highcharts-more");

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
HC_exporting(Highcharts);

@Component({
    selector: "app-gaugeseries-chart",
    templateUrl: "./gaugeseries-chart.component.html",
    styleUrls: ["./gaugeseries-chart.component.css"],
})
export class GaugeseriesChartComponent implements OnInit, OnChanges {
    @Input() divId: string;
    @Input() chartName: string;
    @Input() data: number;
    private innerWidth: number;
    @Input() maxLimit: number;
    @Input() unitType: string;

    @Input() chartSize?: string;
    @Input() chartCenter?: any;
    @Input() completedEventId: string;

    highchart: any;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.data) {
            // @Input değeri değiştiğinde yapılacak işlemleri burada gerçekleştirin

            if (this.data) {
                let point;

                if (this.highchart) {
                    point = this.highchart.series[0].points[0];

                    point.update(this.data);
                    setTimeout(() => {
                        abp.event.trigger(this.completedEventId, {
                            isCompleted: true,
                            highChart: this.highchart,
                            type: DashboardItemWidgetType.chart,
                        });
                    }, 1000);
                }
            }
        }
    }

    getChartOptions() {
        return {
            chart: {
                type: "gauge",
                plotBackgroundColor: null,
                plotBackgroundImage: null,
                plotBorderWidth: 0,
                plotShadow: false,
                height: "75%",
            },
            exporting: { enabled: false },
            title: {
                text: "",
            },

            pane: {
                startAngle: -90,
                endAngle: 89.9,
                background: null,
                center: this.chartCenter
                    ? this.chartCenter
                    : this.innerWidth < 1800
                    ? this.innerWidth < 1707
                        ? ["50%", "35%"]
                        : ["50%", "50%"]
                    : ["50%", "58%"],
                size: this.chartSize ? this.chartSize : "80%",
            },
            credits: {
                enabled: false,
            },
            // the value axis
            yAxis: {
                min: 0,
                max: this.maxLimit,
                tickPixelInterval: 0,
                //tickPosition: "inside",
                //tickColor: Highcharts.defaultOptions.chart.backgroundColor || "#FFFFFF",
                //tickLength: 20,
                //minorTickInterval: null,
                tickWidth: 0,
                tickPositions: [0, Math.round(this.maxLimit / 3), Math.round(this.maxLimit / 2), this.maxLimit],
                labels: {
                    distance: this.completedEventId ? 30 : 21,
                    style: {
                        fontSize: this.completedEventId ? "20px" : "11px",
                    },
                    formatter: function () {
                        return Number(this.value).toFixed(2);
                    },
                },
                plotBands: [
                    {
                        from: 0,
                        to: this.maxLimit / 3,
                        color: "#DF5353", // yeşil
                        thickness: 20,
                    },
                    {
                        from: this.maxLimit / 3,
                        to: this.maxLimit / 2,
                        color: "#DDDF0D", // sarı
                        thickness: 20,
                    },
                    {
                        from: this.maxLimit / 2,
                        to: this.maxLimit,
                        color: "#55BF3B", // kırmızı
                        thickness: 20,
                    },
                ],
            },

            series: [
                {
                    name: this.chartName == "production_chart" ? "production" : "capacity factor",
                    data: [this.data > 0 ? this.data : 0],
                    tooltip: {
                        valueSuffix: this.chartName == "production_chart" ? this.unitType : "%",
                    },
                    dataLabels: {
                        format: this.chartName == "production_chart" ? "{y} " + this.unitType : " {y} %",
                        borderWidth: 0,
                        color:
                            (Highcharts.defaultOptions.title &&
                                Highcharts.defaultOptions.title.style &&
                                Highcharts.defaultOptions.title.style.color) ||
                            "#333333",
                        style: {
                            fontSize: this.completedEventId ? "40px" : "14px",
                        },
                    },
                    dial: {
                        radius: "75%",
                        backgroundColor: "gray",
                        baseWidth: 12,
                        baseLength: "0%",
                        rearLength: "0%",
                    },
                    pivot: {
                        backgroundColor: "gray",
                        radius: 6,
                    },
                },
            ],
        };
    }

    ngOnInit() {
        this.initializeChart();
    }

    initializeChart() {
        this.innerWidth = window.innerWidth;

        let chart = document.querySelector("#" + this.divId + " #" + this.chartName);
        // @ts-ignore
        this.highchart = Highcharts.chart(chart, Highcharts.merge(this.getChartOptions()));

        if (Highcharts.charts.find((v) => v["renderTo"].id == this.chartName)) {
            Highcharts.charts.find((v) => v["renderTo"].id == this.chartName).reflow();
        }

        setTimeout(() => {
            abp.event.trigger(this.completedEventId, {
                isCompleted: true,
                highChart: this.highchart,
                type: DashboardItemWidgetType.chart,
            });
        }, 1000);
    }
}
