import {Component, Injector, Input, ViewEncapsulation} from '@angular/core';
import {AppComponentBase} from "@shared/common/app-component-base";

@Component({
    selector: 'edit-menu',
    templateUrl: './edit-menu.component.html',
    styleUrls: ['./edit-menu.component.css'],
    encapsulation: ViewEncapsulation.None,

})
export class EditMenuComponent extends AppComponentBase {
    @Input() IsAppear: boolean;

    constructor(
        injector: Injector
    ) {
        super(injector);
    }
}
