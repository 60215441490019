import { Component, Injector, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ReportSection, ReportSectionType } from "@app/main/reports/report-overview/report-overview.model";
import { ReportOverviewService } from "@app/main/reports/report-overview/report-overview.service";

@Component({
    selector: "app-toc-overview",
    templateUrl: "./toc-overview.component.html",
    styleUrls: ["./toc-overview.component.css"],
})
export class TocOverviewComponent extends AppComponentBase implements OnInit {
    @Input() reportSections: ReportSection[];
    @Input() pageId: string;
    @Input() containerStyle: string;
    sections: ReportSection[];
    pages: ReportSection[];
    tocTree: ReportSection[];

    constructor(injector: Injector, private _reportOverviewService: ReportOverviewService) {
        super(injector);
    }

    ngOnInit() {
        this.reportSections = this.reportSections.filter((x) => x.components[0].type == ReportSectionType.Cover);
        this.initializeTOCOverView();
    }

    private initializeTOCOverView() {
        const filteredReportSections = this.filterSectionsByType(this.reportSections, "Table Of Contents");

        if (!this.hasUnselectedSections(filteredReportSections)) {
            this.selectAllSections(filteredReportSections);
        }

        this.updateTOCTree(filteredReportSections);

        this.subscribeToEvent("click-section-chips", (sections: ReportSection[]) => {
            sections = sections.filter((x) => x.components[0].type == ReportSectionType.Cover);

            const filteredSections = this.filterSectionsByType(sections, "Table Of Contents");
            this.updateTOCTree(filteredSections);
        });

        this.subscribeToEvent("report-header-changes-event", (sections: ReportSection[]) => {
            sections = sections.filter((x) => x.components[0].type == ReportSectionType.Cover);

            const filteredSections = this.filterSectionsByType(sections, "Table Of Contents");
            this.updateTOCTree(filteredSections);
        });

        this.subscribeToEvent("report-hide-changes-event", (sections: ReportSection[]) => {
            sections = sections.filter((x) => x.components[0].type == ReportSectionType.Cover);

            const filteredSections = this.filterSectionsByType(sections, "Table Of Contents");
            this.updateTOCTree(filteredSections);
        });
    }

    private filterSectionsByType(sections: ReportSection[], type: string): ReportSection[] {
        return sections.filter((x) => x.components[0].type !== type);
    }

    private hasUnselectedSections(sections: ReportSection[]): boolean {
        return sections.some((x) => !x.isTOCSelected);
    }

    private selectAllSections(sections: ReportSection[]): void {
        sections.forEach((x) => (x.isTOCSelected = true));
    }

    private updateTOCTree(sections: ReportSection[]): void {
        let tocTree = this._reportOverviewService.buildTree(
            sections.filter((x) => x.isTOCSelected === true && x.isHidden === false)
        );

        this.tocTree = tocTree;
    }
}
