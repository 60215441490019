import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as _ from "lodash";

export enum SelectionType {
    Zoom,
    Select,
}

export interface ToolboxItem {
    icon: string;
    class: string;
    clickEvent;
    children: ToolboxItem[];
    disabled?: boolean;
    buttonType: ToolboxButtonType;
}

export enum ToolboxButtonType {
    FullScreen,
    Select,
    Zoom,
    Exclude,
    Delete,
    Download
}

@Component({
    selector: "app-toolbox",
    templateUrl: "./toolbox.component.html",
    styleUrls: ["./toolbox.component.scss"]
})
export class ToolboxComponent implements OnInit {
    @Output() deleteBtnClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() excludeBtnClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() fullScreenClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() downloadClick: EventEmitter<any> = new EventEmitter<any>();
    @Input() showEditTools: boolean = true;
    @Input() showDownloadButton: boolean = false;
    @Input() isAnyProjectDeployed: boolean;
    @Input() excludedToolboxButtonTypes: ToolboxButtonType[];
    btnClass = "btn btn-icon btn-active-color-dark btn-bg-light btn-hover-scale position-relative w-30px h-30px w-md-40px h-md-40px me-2 mb-2";
    tools: ToolboxItem[] = [];
    selectionType = SelectionType.Select;

    ngOnInit() {
        this.tools = [
            {
                icon: "la-expand",
                class: this.btnClass,
                clickEvent: this.fullScreen.bind(this),
                children: [],
                buttonType: ToolboxButtonType.FullScreen
            }
        ];
        if (this.showEditTools) {
            this.tools.push({
                icon: "la-mouse-pointer",
                class: this.btnClass,
                clickEvent: this.changeSelectionType.bind(this, SelectionType.Select),
                buttonType: ToolboxButtonType.Select,
                children: [{
                    icon: "la-search",
                    class: this.btnClass,
                    clickEvent: this.changeSelectionType.bind(this, SelectionType.Zoom),
                    children: [],
                    buttonType: ToolboxButtonType.Zoom
                }]
            },
            {
                icon: "la-ban",
                class: this.btnClass,
                clickEvent: this.excludeSelectedPoints.bind(this),
                children: [],
                disabled: this.isAnyProjectDeployed,
                buttonType: ToolboxButtonType.Exclude
            },
            {
                icon: "la-trash",
                class: this.btnClass,
                clickEvent: this.deleteSelectedPoints.bind(this),
                children: [],
                disabled: this.isAnyProjectDeployed,
                buttonType: ToolboxButtonType.Delete
            });
        }

        if (this.showDownloadButton) {
            this.tools.push({
                icon: "la-download",
                class: this.btnClass,
                clickEvent: this.download.bind(this),
                children: [],
                buttonType: ToolboxButtonType.Download
            });
        }

        if (this.excludedToolboxButtonTypes) {
            this.tools.forEach(parentItem => {
                parentItem.children = parentItem.children.filter(x => !this.excludedToolboxButtonTypes.includes(x.buttonType));
            });
            this.tools = this.tools.filter(x => !this.excludedToolboxButtonTypes.includes(x.buttonType));
        }
    }

    deleteSelectedPoints() {
        this.deleteBtnClick.emit();
    }

    excludeSelectedPoints() {
        this.excludeBtnClick.emit();
    }

    fullScreen() {
        this.fullScreenClick.emit();
    }

    download() {
        this.downloadClick.emit();
    }

    changeSelectionType(selectionType: SelectionType) {
        if (selectionType == this.selectionType) return;
        //TODO: Fix this implementation for tools that have multiple children
        let child = _.cloneDeep(this.tools[1].children[0]);
        this.tools[1].children = [];
        child.children.push(this.tools[1]);
        this.tools[1] = child;
        this.selectionType = selectionType;
    }
}
