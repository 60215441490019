import { Component, Injector, Input, OnInit } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";

export interface IStep {
    id: number;
    label: string;
    isCompleted: boolean;
    isPassedOptionally: boolean;
}

@Component({
    selector: "stepper",
    templateUrl: "./stepper.component.html",
    styleUrls: ["./stepper.component.scss"]
})
export class StepperComponent extends AppComponentBase implements OnInit {

    @Input() steps: IStep[];

    currentStepIndex: number = 0;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
        if (!this.steps) {
            this.steps = [];
        }
    }

    public nextStep(): void {
        if (this.currentStepIndex < this.steps.length - 1) {
            let currentStep = this.steps[this.currentStepIndex];
            currentStep.isCompleted = true;
            currentStep.isPassedOptionally = false;
            this.currentStepIndex++;
        }
    }

    public passStepOptionally(): void {
        if (this.currentStepIndex < this.steps.length - 1) {
            let currentStep = this.steps[this.currentStepIndex];
            currentStep.isCompleted = false;
            currentStep.isPassedOptionally = true;
            this.currentStepIndex++;
        }
    }

    public previousStep(): void {
        if (this.currentStepIndex > 0) {
            let currentStep = this.steps[this.currentStepIndex];
            currentStep.isCompleted = false;
            currentStep.isPassedOptionally = false;
            this.currentStepIndex--;
        }
    }

    getStepClass(step: IStep) {
        let className = "";
        if (step.id === this.steps[this.currentStepIndex].id) {
            className += " current";
            className.replace("completed", "");
            className.replace("passed-optionally", "");
        }

        if (step.isCompleted) {
            className.replace("current", "");
            className.replace("passed-optionally", "");
            className += " completed";
        }

        if (step.isPassedOptionally) {
            className.replace("current", "");
            className.replace("completed", "");
            className += " passed-optionally";
        }

        return className;
    }

    getStepLabelClass(step: IStep) {
        if (step.id === this.steps[this.currentStepIndex].id) {
            return "text-purple fw-boldest";
        } else if (step.isCompleted) {
            return "text-dark fw-bolder";
        } else if (step.isPassedOptionally) {
            return "text-dark fw-bold";
        } else {
            return "text-secondary";
        }
    }
}
