import { Component, Injector, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import * as Highcharts from "@node_modules/highcharts";
import { DashboardItemWidgetType } from "@app/shared/common/widget/widget-consts";
import { ChartService } from "@app/shared/common/dashboard-charts/chart.service";
import { AppComponentBase } from "@shared/common/app-component-base";
import { AxisLabelsFormatterCallbackFunction, SeriesHeatmapOptions, TooltipOptions } from "@node_modules/highcharts";
import HighchartsBoost from "highcharts/modules/boost";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import { DailyInvertersInformation } from "@shared/service-proxies/service-proxies";

// Boost ve Heatmap modüllerini etkinleştirme
HighchartsBoost(Highcharts);
HighchartsHeatmap(Highcharts);

export interface HeatMapSeriesData {
    subtitle?: string;
    categories?: string[];
    series?: SeriesHeatmapOptions[];
    formatter?: AxisLabelsFormatterCallbackFunction;
    tooltip?: TooltipOptions;
    xAxisTitle?: string;
    yAxisTitle?: string;
    xAxisTickPositions?: string[];
    yAxisTickPositions?: string[];
    inverterInfo?: DailyInvertersInformation[];
    yCategories: string[];
}

@Component({
    selector: "app-heatmap-chart",
    templateUrl: "./heatmap-chart.component.html",
    styleUrls: ["./heatmap-chart.component.css"],
})
export class HeatmapChartComponent extends AppComponentBase implements OnInit, OnChanges {
    @Input() divId: string;
    @Input() chartName: string;
    @Input() heatMapSeriesData: HeatMapSeriesData;
    @Input() completedEventId: string;
    @Input() legendEnabled: boolean = true;
    @Input() yCategories: string[];
    @Input() chartHeight: number = 300;
    @Input() tooltipFormatter: (arg: Highcharts.TooltipFormatterContextObject) => string;

    chart: Highcharts.Chart;
    options: Highcharts.Options;

    constructor(injector: Injector, private _chartService: ChartService) {
        super(injector);
    }

    ngOnInit(): void {
        this.setOptions();
        let chartElement = document.querySelector("#" + this.divId + " #" + this.chartName);

        // @ts-ignore
        this.chart = Highcharts.chart(chartElement, this.options);

        // Retry işlemini gerçekleştiren bir fonksiyon tanımlayın
        this._chartService.retryUntilSuccess(
            1000, // İnterval (ms)
            20, // Maksimum deneme sayısı
            () => Highcharts.charts.find((v) => v["renderTo"].id == this.chartName) !== undefined, // Koşul
            () => {
                const heatMapChart = Highcharts.charts.find((v) => v["renderTo"].id == this.chartName);
                if (heatMapChart) {
                    heatMapChart.reflow();
                }
            }
        );

        setTimeout(() => {
            abp.event.trigger(this.completedEventId, {
                isCompleted: true,
                highChart: this.chart,
                type: DashboardItemWidgetType.chart,
            });
        }, 2000);
    }

    ngOnChanges(changes: SimpleChanges) {}

    setOptions() {
        let that = this;
        // @ts-ignore
        this.options = {
            chart: {
                type: "heatmap",
                height: this.chartHeight,
            },
            credits: {
                enabled: false,
            },
            boost: {
                useGPUTranslations: true,
                enabled: true, // Boost'u etkinleştirme
            },

            title: {
                text: "",
            },

            subtitle: {
                text: "",
            },
            tooltip: {
                formatter: function (tooltip): string {
                    return that.tooltipFormatter(this);
                },
            },
            xAxis: {
                ordinal: false,
                type: "datetime",
                title: {
                    text: "Hours",
                },
            },

            yAxis: {
                startOnTick: false,
                endOnTick: false,
                title: {
                    text: "Inverters",
                },
                categories: this.yCategories,
                reversed: true,
                gridLineWidth: 0,
                tickInterval: 1, // Her kategori için bir aralık
                labels: {
                    style: {
                        fontSize: "10px", // Yazı boyutu
                    },
                },
                max: this.yCategories.length - 1, // Maksimum kategori sayısını belirleme
                min: 0, // Minimum kategori
            },

            colorAxis: {
                min: 0,
                max: 1,
                stops: [
                    [0, "#c4463a"], // Kırmızı
                    [0.25, "#e18956"], // Turuncuya çalan sarı
                    [0.5, "#ffcc33"], // Daha koyu sarı
                    [0.75, "#ffe066"], // Orta sarı
                    [1, "#f8f2ac"], // Açık sarı
                ],
                startOnTick: false,
                endOnTick: false,
                labels: {
                    format: "{value}kW/kWp",
                },
            },

            legend: {
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom",
                // y: 25,
                // symbolHeight: 280,
                symbolWidth: 450,
            },

            series: this.heatMapSeriesData.series,
        };
    }
}
