import { BaseComponent } from "@app/state-management/models/base-component";
import {GetBaseComponentAction} from "@app/state-management/actions/base-component.action";
import {Action, createReducer, on} from "@node_modules/@ngrx/store";


export interface IBaseComponentState {
    data: BaseComponent;
}

// Initial State
export const initialState: IBaseComponentState = {
    data: new BaseComponent
}

// Reducer
export const BaseComponentReducer = createReducer(initialState,
    on(GetBaseComponentAction, (state, data) => {
        const newState: IBaseComponentState = {
            data: data
        }
        return newState;
    })
);


export function reducer(state: IBaseComponentState | undefined, action: Action) {
    return BaseComponentReducer(state, action);
}


