import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { DateTimeService } from "@app/shared/common/timing/date-time.service";

export interface IRangeSliderInfo {
    leftValue?: number;
    leftHandleRatio?: number;
    rightValue?: number;
    rightHandleRatio?: number;
    rangeRatio?: number;
}

@Component({
    selector: "range-slider",
    templateUrl: "./range-slider.component.html",
    styleUrls: ["./range-slider.component.scss"]
})
export class RangeSliderComponent implements OnInit, OnChanges {

    @Input() isTooltipEnabled: boolean;
    @Input() valueType: "numeric" | "datems";
    @Input() min?: number;
    @Input() minRangeRatio?: number;
    @Input() max?: number;
    @Input() maxRangeRatio?: number;
    @Input() step: number;
    @Input() leftValue?: number;
    @Input() leftHandleRatio?: number;
    @Input() rightValue?: number;
    @Input() rightHandleRatio?: number;
    @Input() values: number[];
    @Input() rangeRatio: number;

    @Output() leftValueChange = new EventEmitter<number>();
    @Output() leftHandleRatioChange = new EventEmitter<number>();
    @Output() onLeftHandleChange = new EventEmitter<IRangeSliderInfo>();
    @Output() rightValueChange = new EventEmitter<number>();
    @Output() rightHandleRatioChange = new EventEmitter<number>();
    @Output() onRightHandleChange = new EventEmitter<IRangeSliderInfo>();
    @Output() rangeRatioChange = new EventEmitter<number>();

    private recursiveMultiplier = 1;

    constructor(
        private _cdr: ChangeDetectorRef,
        private _dateTimeService: DateTimeService
    ) {
    }

    ngOnInit(): void {
        this.min ??= this.values[0];
        this.max ??= this.values[this.values.length - 1];
        if (this.leftHandleRatio) {
            this.leftValue = this.getValueByRatio(this.leftHandleRatio);
            this.onLeftHandleSlide();
        } else {
            this.leftValue ??= this.min;
        }

        if (this.rightHandleRatio) {
            this.rightValue = this.getValueByRatio(this.rightHandleRatio);
            this.onRightHandleSlide();
        } else {
            this.rightValue ??= this.max;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.leftValue && !changes.leftValue.firstChange && changes.leftValue.currentValue) {
            this.leftHandleRatio = this.getRatioByValue(this.leftValue);
            this.onLeftHandleSlide();
        }

        if (changes.rightValue && !changes.rightValue.firstChange && changes.rightValue.currentValue) {
            this.rightHandleRatio = this.getRatioByValue(this.rightValue);
            this.onRightHandleSlide();
        }
    }

    onLeftHandleSlide(event?: MouseEvent) {
        let leftHandleRatio = this.getRatioByValue(this.leftValue);
        console.log("LeftHandleRatio: ", leftHandleRatio);
        if (this.rightHandleRatio - leftHandleRatio < 0) {
            this.leftValue = this.getValueByRatio(this.rightHandleRatio - this.minRangeRatio);
            this.onLeftHandleSlide();
            return;
        } else if (this.minRangeRatio && this.rightHandleRatio - leftHandleRatio < this.minRangeRatio) {
            this.leftValue = this.getValueByRatio(this.rightHandleRatio - this.minRangeRatio + (-0.01 * this.recursiveMultiplier));
            this.recursiveMultiplier++;
            this.onLeftHandleSlide();
            return;
        } else if (this.maxRangeRatio && this.rightHandleRatio - leftHandleRatio > this.maxRangeRatio) {
            this.leftValue = this.getValueByRatio(this.rightHandleRatio - this.maxRangeRatio + (0.01 * this.recursiveMultiplier));
            this.recursiveMultiplier++;
            this.onLeftHandleSlide();
            return;
        }

        this.setRangeTooltip();
        this.leftHandleRatio = this.getRatioByValue(this.leftValue);
        this.rangeRatio = (this.rightValue - this.leftValue) / (this.max - this.min);
        this.recursiveMultiplier = 1;
        this._cdr.detectChanges();
        this.onLeftHandleChange.emit({
            leftValue: this.leftValue,
            leftHandleRatio: this.leftHandleRatio,
            rightValue: this.rightValue,
            rightHandleRatio: this.rightHandleRatio,
            rangeRatio: this.rangeRatio
        });
    }

    onRightHandleSlide(event?: MouseEvent) {
        let rightHandleRatio = this.getRatioByValue(this.rightValue);
        console.log("RightHandleRatio: ", rightHandleRatio);
        if (rightHandleRatio - this.leftHandleRatio < 0) {
            this.rightValue = this.getValueByRatio(this.leftHandleRatio + this.minRangeRatio);
            this.onRightHandleSlide();
            return;
        } else if (this.minRangeRatio && rightHandleRatio - this.leftHandleRatio < this.minRangeRatio) {
            this.rightValue = this.getValueByRatio(this.leftHandleRatio + this.minRangeRatio + (0.01 * this.recursiveMultiplier));
            this.recursiveMultiplier++;
            this.onRightHandleSlide();
            return;
        } else if (this.maxRangeRatio && rightHandleRatio - this.leftHandleRatio > this.maxRangeRatio) {
            this.rightValue = this.getValueByRatio(this.leftHandleRatio + this.maxRangeRatio + (-0.01 * this.recursiveMultiplier));
            this.recursiveMultiplier++;
            this.onRightHandleSlide();
            return;
        }

        this.setRangeTooltip();
        this.rightHandleRatio = this.getRatioByValue(this.rightValue);
        this.rangeRatio = (this.rightValue - this.leftValue) / (this.max - this.min);
        this.recursiveMultiplier = 1;
        this._cdr.detectChanges();
        this.onRightHandleChange.emit({
            leftValue: this.leftValue,
            leftHandleRatio: this.leftHandleRatio,
            rightValue: this.rightValue,
            rightHandleRatio: this.rightHandleRatio,
            rangeRatio: this.rangeRatio
        });
    }

    private getRatioByValue(value: number): number {
        let index = this.values.indexOf(value);
        return Number(((index + 1) / this.values.length).toFixed(2));
    }

    private getNearestPointValue(value: number): number {
        return this.values.reduce((prev, curr) => Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev, this.values[0]);
    }

    private getValueByRatio(ratio: number): number {
        let index = Math.round(ratio * this.values.length);
        let value = this.values[index - 1];
        return this.getNearestPointValue(value);
    }

    private setRangeTooltip() {
        if (this.isTooltipEnabled) {
            let tooltip = document.querySelector(".range-slider-tooltip") as HTMLDivElement;
            if (tooltip) {
                if (this.valueType === "numeric") {
                    tooltip.innerHTML = `<span>${this.leftValue} - ${this.rightValue}</span>`;
                } else if (this.valueType === "datems") {
                    tooltip.innerHTML =
                        `<span>
                        ${this._dateTimeService.fromJSDate(new Date(this.leftValue)).toFormat("yyyy LLL dd")}
                        -
                        ${this._dateTimeService.fromJSDate(new Date(this.rightValue)).toFormat("yyyy LLL dd")}
                    </span>`;
                }
            }
        }
    }
}
