import {GetReportTrialAction} from '../actions/report-trials.action'
import {ReportTrials} from "../models/report-trials";
import {Action, createReducer, on} from "@node_modules/@ngrx/store";

// State Type
export interface IReportTrialsState {
    data: ReportTrials;
}

// Initial State
export const initialState: IReportTrialsState = {
    data: new ReportTrials
}

// Reducer
export const ReportTrialsReducer = createReducer(initialState,
    on(GetReportTrialAction, (state, data) => {
        const newState: IReportTrialsState = {
            data: data
        }
        return newState;
    })
);

export function reducer(state: IReportTrialsState | undefined, action: Action) {
    return ReportTrialsReducer(state, action);
}



