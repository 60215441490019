<div class="maintenance-header">
    <div class="maintenance-logo"><img class="unknown-3" src="assets/maintenance/unknown-3@2x.png" alt="One Pact"/></div>
</div>
<div class="maintenance screen">
    <div class="maintenance-container">
        <div class="head">
            <h1 class="maintenance-header-title">Website currently <br/>under maintenance</h1>
            <p class="we-are-currently-wor">We are currently working hard on this page!</p>
        </div>
        <img class="maintenance-bro-1" src="assets/maintenance/maintenance-bro-1.svg" alt="Maintenance 1"/>
    </div>
</div>
