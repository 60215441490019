import { Component, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { AppComponentBase } from "@shared/common/app-component-base";

@Component({
  selector: 'app-internal-server-error',
  templateUrl: './internal-server-error.component.html',
  styleUrls: ['./internal-server-error.component.css']
})
export class InternalServerErrorComponent extends AppComponentBase  {

    constructor(injector: Injector, private router: Router
    ) {
        super(injector);
    }

    goToHome() {
        this.router.navigate(["/app/main/dashboards"]);

    }
}
