import { ChangeDetectorRef, Component, Injector, Input, OnInit } from "@angular/core";
import * as Highcharts from "@node_modules/highcharts";
import HighchartsData from "highcharts/modules/data";
import HighchartsAccessibility from "highcharts/modules/accessibility";
import Histogram from "highcharts/modules/histogram-bellcurve";
import { SeriesOptionsType } from "@node_modules/highcharts/highstock";
import HC_exporting from "@node_modules/highcharts/modules/exporting";

HighchartsData(Highcharts);
HighchartsAccessibility(Highcharts);
Histogram(Highcharts);
HC_exporting(Highcharts);

export interface HistogramColumnData {
    series?: SeriesOptionsType[];
}

@Component({
    selector: 'app-histogram-chart',
    templateUrl: './histogram-chart.component.html',
    styleUrls: ['./histogram-chart.component.css']
})
export class HistogramChartComponent implements OnInit {

    @Input() divId: string;
    @Input() chartName: string;
    @Input() histogramColumnData: HistogramColumnData;
    @Input() completedEventId: string;

    chart: Highcharts.Chart;
    options: Highcharts.Options;

    constructor(injector: Injector, private _changeDetectorReference: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.setOptions();
        let chartElement = document.querySelector('#' + this.divId + ' #' + this.chartName);
        // @ts-ignore
        this.chart = Highcharts.chart(chartElement, this.options);

        if (Highcharts.charts.find(v => v['renderTo'].id == this.chartName)) {
            Highcharts.charts.find(v => v['renderTo'].id == this.chartName).reflow();
        }
    }

    setOptions() {
        this.options = {
            title: {
                text: ''
            },
            xAxis: [
                {
                    title: { text: '' },
                    alignTicks: false,
                    opposite: true
                },{
                title: { text: '' },
                alignTicks: false
            }],

            yAxis: [
                {
                    title: { text: '' },
                    opposite: true
                },{
                title: { text: '' }
            }],
            plotOptions: {
                histogram: {
                    accessibility: {
                        point: {
                            valueDescriptionFormat: '{index}. {point.x:.3f} to {point.x2:.3f}, {point.y}.'
                        }
                    }
                }
            },
            series: this.histogramColumnData.series,
            credits: {
                enabled: false
            }
        }
    }
}
