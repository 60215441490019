import { SelectedAlgorithm } from "@app/state-management/models/selected-algorithm";
import { Action, createReducer, on } from "@node_modules/@ngrx/store";
import { GetSelectedAlgorithmAction } from "@app/state-management/actions/selected-algorithm.action";

export interface ISelectedAlgorithmState {
    data: SelectedAlgorithm;
}

// Initial State
export const initialState: ISelectedAlgorithmState = {
    data: new SelectedAlgorithm
}

// Reducer
export const SelectedAlgorithmReducer = createReducer(initialState,
    on(GetSelectedAlgorithmAction, (state, data) => {
        const newState: ISelectedAlgorithmState = {
            data: data
        };
        return newState;
    })
);

export function reducer(state: ISelectedAlgorithmState | undefined, action: Action) {
    return SelectedAlgorithmReducer(state, action);
}
