import {Injectable} from "@angular/core";
import {
    HttpEvent, HttpRequest, HttpHandler,
    HttpInterceptor, HttpErrorResponse
} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {retry, catchError} from "rxjs/operators";
import {Router} from "@angular/router";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private _router: Router) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            retry(1),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 500) {
                    this._router.navigate(["internal-server-error"]);
                } else if (error.status === 403 || error.status === 401 ) {
                    this._router.navigate(["app/main/no-permission"]);
                } else {
                    return throwError(error);
                }
            })
        );
    }
}
