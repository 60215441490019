import {Action, createReducer, on} from "@node_modules/@ngrx/store";
import {EditWidgetConfigDto} from "@app/state-management/models/edit-widget-config";
import {EditWidgetAction} from "@app/state-management/actions/edit-widget.action";

// State Type
export interface IEditWidgetState {
    data: EditWidgetConfigDto;
}

// Initial State
export const initialState: IEditWidgetState = {
    data: new EditWidgetConfigDto
}

// Reducer
export const EditWidgetReducer = createReducer(initialState,
    on(EditWidgetAction, (state, data) => {
        const newState: IEditWidgetState = {
            data: data
        }
        return newState;
    })
);

export function reducer(state: IEditWidgetState | undefined, action: Action) {
    return EditWidgetReducer(state, action);
}
