import { Component, Injector, Input, OnInit } from "@angular/core";
import { PowerPlant, ReportDashboardServiceProxy } from "@shared/service-proxies/service-proxies";
import { AppComponentBase } from "@shared/common/app-component-base";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import * as ReportTrialsUtilities from "@app/main/report-trials/utilities";
import * as _ from "lodash";
import { Store } from "@node_modules/@ngrx/store";
import { SelectedBaseComponentDto, SeriesWidgetConfigDto } from "@app/shared/models/SeriesWidgetConfigDto";
import { EditWidgetConfigDto } from "@app/state-management/models/edit-widget-config";
import { EditWidgetAction } from "@app/state-management/actions/edit-widget.action";
import { LocalStorageService } from "@shared/utils/local-storage.service";

@Component({
    selector: "edit-series",
    templateUrl: "./edit-series.component.html",
    styleUrls: ["./edit-series.component.css"]
})
export class EditSeriesComponent extends AppComponentBase implements OnInit {

    @Input() powerPlant: PowerPlant;
    searchvalue = "";
    activeId: number = 1;
    baseComponents: SelectedBaseComponentDto[] = [];
    selectedBaseComponent: number;
    columns = [];
    config: EditWidgetConfigDto;
    seriesWidgetConfigDto: SeriesWidgetConfigDto;
    tenancyName: string;

    constructor(
        injector: Injector,
        private _reportDashboardServiceProxy: ReportDashboardServiceProxy,
        private _localStorageService: LocalStorageService,
        private store: Store
    ) {
        super(injector);
    }

    ngOnInit() {
        this.store.select((state) => state).subscribe((state: any) => {
            if (!_.isNil(state.editWidget.data.general)) {
                this.config = state.editWidget.data;
                if (this.config.series.visible) {
                    if (this.config.series.selectedComponent)
                        this.columns = [...this.config.series.selectedComponent];
                }
            }
        });
        this.selectedBaseComponent = 0;

        this._localStorageService.getItem("tenancyName", (err, tName) => {
            this.tenancyName = tName;
        });
        this.getBaseComponent();


    }

    public ShowOverlay(overlayName, animationName) {
        ReportTrialsUtilities.ShowOverlay(overlayName, animationName);
    };

    getBaseComponent() {

        this._reportDashboardServiceProxy.getActiveUnitBaseComponents("", [this.powerPlant.id], [])
            .subscribe(data => {
                this.baseComponents = data.map(x => ({
                    id: x.id,
                    name: x.name,
                    unit: x.unit
                }) as SelectedBaseComponentDto);
            });
    }


    dragDrop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
        this.pre = JSON.stringify(this.columns, null, " ");
    }

    markdown = `
# Material Design: Angular 7, drag-and-drop list
requires:
- \`CdkDragDrop\` and \`moveItemInArray\` imports
- \`cdkDropList\` directive and \`cdkDropListDropped\` event
- CSS \`flex\` layout

😒 setting \`cdkDropListOrientation\` to \`horizontal\` requires a CSS change
`;

    pre = JSON.stringify(this.columns, null, " ");

    seriesClick(id: number) {
        this.activeId = id;
    }

    alert(number: number) {
        alert(number);
    }

    addBaseComponent() {
        if (this.selectedBaseComponent > 0) {
            if (this.config.component == "app_custom_gadget")
                this.columns = [];
            const selectedModel = this.baseComponents.find(x => x.id == this.selectedBaseComponent);
            this.columns.push(selectedModel);

            this.seriesWidgetConfigDto = new SeriesWidgetConfigDto();
            this.seriesWidgetConfigDto.visible = true;
            this.seriesWidgetConfigDto.selectedComponent = this.columns;

            let item = Object.assign(new EditWidgetConfigDto(), {
                component: this.config.component,
                divId: this.config.divId,
                widgetId: this.config.widgetId,
                general: this.config.general,
                datasource: this.config.datasource,
                series: this.seriesWidgetConfigDto,
                format: this.config.format,
                axex: this.config.axex,
                unitModel: this.config.unitModel,
                baseComponent: this.config.baseComponent

            });
            this.store.dispatch(EditWidgetAction(item));
            abp.event.trigger(this.config.divId + "-series-add", selectedModel);
        }
    }

    removeColumn(number: number) {
        const item = this.columns.find(x => x.id == number);
        const index = this.columns.indexOf(item);
        if (index !== -1) {
            abp.event.trigger(this.config.divId + "-series-remove", item);
            this.columns.splice(index, 1);

            this.seriesWidgetConfigDto = new SeriesWidgetConfigDto();
            this.seriesWidgetConfigDto.visible = true;
            this.seriesWidgetConfigDto.selectedComponent = this.columns;

            let _item = Object.assign(new EditWidgetConfigDto(), {
                component: this.config.component,
                divId: this.config.divId,
                widgetId: this.config.widgetId,
                general: this.config.general,
                datasource: this.config.datasource,
                series: this.seriesWidgetConfigDto,
                format: this.config.format,
                axex: this.config.axex,
                unitModel: this.config.unitModel,
                baseComponent: this.config.baseComponent

            });
            this.store.dispatch(EditWidgetAction(_item));
        }
    }
}
