<div class="table-tableOption">
    <div class="dialogueu58-select-columns screen">
        <div class="dialog">
            <div class="ic_tabler_settings"></div>
            <div class="title-1">Table Options</div>
            <div class="close">
                <div class="icon-button-1" (click)="onCloseClick()">
                    <img class="iconsic_close" src="/assets/dashboard/setting/icons-ic-close.svg"
                         alt="icons/ic_close" />
                </div>
            </div>
        </div>
        <div class="menu">
            <div class="title-icon">
                <div class="icon-button">
                    <div class="ic-tabler-eye-cog"></div>
                </div>
                <div class="title publicsans-bold-charade-14px">VIEW &amp; SPACING</div>
            </div>
            <div class="desc-info">
                <div class="frame-558 frame">
                    <div class="toggle-button-group toggle-button">
                        <div (click)="changeSpacing('Dense')"
                             [ngClass]="this.optionTable.spacing=='Dense' ? 'toggle-button-standalone-1 toggle-button':'toggle-button-standalone toggle-button'">
                            <div class="ic_tabler_arrows-diagonal-minimize ic_tabler_arrows-diagonal">
                                <img class="arrows-diagonal" *ngIf="this.optionTable.spacing=='Dense'"
                                     src="/assets/dashboard/setting/arrows-diagonal-minimize.svg"
                                     alt="arrows-diagonal-minimize" />
                                <img class="arrows-diagonal" *ngIf="this.optionTable.spacing!='Dense'"
                                     src="/assets/dashboard/setting/arrows-diagonal-minimize-1.svg"
                                     alt="arrows-diagonal-minimize" />
                            </div>
                        </div>
                        <div (click)="changeSpacing('Neutral')"
                             [ngClass]="this.optionTable.spacing=='Neutral' ? 'toggle-button-standalone-1 toggle-button':'toggle-button-standalone toggle-button'">
                            <div class="ic_tabler-space" *ngIf="this.optionTable.spacing!='Neutral'"></div>
                            <div class="ic_tabler-space-1" *ngIf="this.optionTable.spacing=='Neutral'"></div>
                        </div>
                        <div (click)="changeSpacing('Wide')"
                             [ngClass]="this.optionTable.spacing=='Wide' ? 'toggle-button-standalone-1 toggle-button':'toggle-button-standalone toggle-button'">
                            <div class="ic_tabler_arrows-diagonal">
                                <img *ngIf="this.optionTable.spacing!='Wide'"
                                     class="arrows-diagonal-2 arrows-diagonal"
                                     src="/assets/dashboard/setting/arrows-diagonal-2.svg"
                                     alt="arrows-diagonal-2"
                                />
                                <img *ngIf="this.optionTable.spacing=='Wide'"
                                     class="arrows-diagonal-2 arrows-diagonal"
                                     src="/assets/dashboard/setting/arrows-diagonal-3.svg"
                                     alt="arrows-diagonal-2"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="table-cell">
                        <div class="stack">
                            <div class="with-subline">
                                <div
                                    class="td-text publicsans-bold-cardinal-pink-14px">{{this.optionTable.spacing}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="menu-1">
            <div class="title-icon">
                <div class="icon-button">
                    <div class="ic_tabler_columns-3"></div>
                </div>
                <div class="title publicsans-bold-charade-14px">COLUMNS</div>
            </div>
            <div cdkDropList (cdkDropListDropped)="drop($event)" style="width: 100%">
                <div class="menu-item" *ngFor="let column of optionTable.columns;  let i = index;" cdkDrag>
                    <div class="switch switch-5">
                        <div class="switch">
                            <label class="toggle-switch">
                                <input type="checkbox"
                                       [ngModel]="column.isVisible"
                                       (change)="changeColumn($event,column)"
                                >
                                <div class="f-slider"></div>
                            </label>
                        </div>
                    </div>
                    <div class="table-cell">
                        <div class="stack">
                            <div class="with-subline">
                                <div class="td-text publicsans-bold-cardinal-pink-14px">{{column.name}}</div>
                                <p class="subline publicsans-normal-pale-sky-14px">{{column.desc}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="frame-596 frame">
                        <div class="ic_tabler-menu-2"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="dialog-1">
            <div class="button" (click)="onDefaultClick()" style="cursor: pointer;">
                <div class="start-icon"></div>
                <div class="label valign-text-middle publicsans-bold-charade-14px">Default</div>
            </div>
            <div class="frame-68 frame">
                <article class="button-1 button-3" (click)="onCloseClick()">
                    <div class="label valign-text-middle publicsans-bold-charade-14px">Cancel</div>
                </article>
                <article class="button-2 button-3" (click)="onSaveClick()">
                    <div class="label-1 valign-text-middle">Save</div>
                </article>
            </div>
        </div>
    </div>

</div>
