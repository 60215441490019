<div [className]="getAssetButtonClassName('button')">
    <div [className]="getAssetIcon('start-icon')"></div>

    <select [value]="value?.toString()" (change)="onPowerPlantSelect($event)" class="asset-conf-select" [disabled]="isDisabled">
        <option value="-1">
           <span [className]="getAssetLabelClassName('label')">
               {{ 'PowerPlant' | localize }}
           </span>
        </option>
        <option *ngFor="let powerPlant of powerPlants" [value]="powerPlant.id">
            {{ powerPlant.name }}
        </option>
    </select>

    <img class="icon" [src]="getAssetSrc('assets/common/images/report/widget/end-icon-1-disabled.svg')" alt="end icon">
</div>
