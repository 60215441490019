import { createAction, props } from "@ngrx/store";
import { MapLocationModel, MapModel } from "@app/state-management/models/map.model";

//Action Type
export const SET_MAP_STATE = '[SET MAP_STATE] MapModel';
export const SET_MAP_LOCATION = '[SET MAP_LOCATION] MapModel';

//Action
export const setMapState = createAction(SET_MAP_STATE, props<MapModel>());

export const setMapLocation = createAction(SET_MAP_LOCATION, props<MapModel>());

