<div class="axes-content screen">
    <div class="stack-2 stack-5">
        <div class="col-md-3">
            <div class="user-account-update-user-form-1">
                <div class="widget-data-grid-dense">
                    <div class="data-grid-head data-grid">
                        <div class="table-cell-1 table-cell-3">
                            <div class="stack">
                                <div class="th-text publicsans-bold-charade-12px">X AXIS</div>
                            </div>
                        </div>
                    </div>
                    <div class="data-grid-row data-grid">
                        <div class="table-cell">
                            <div class="stack-1 stack-5">
                                <div class="avatar-1">
                                    <div class="container">
                                        <img class="iconsic_replace"
                                             src="assets/common/images/edit-widget/axes/icons-ic-replace-1.svg"
                                             alt="icons/ic_replace">
                                    </div>
                                </div>
                                <div class="name2-1">Time</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="widget-data-grid-dense">
                    <div class="table-cell-2 table-cell-3">
                        <div class="stack">
                            <div class="th-text publicsans-bold-charade-12px">Y AXIS</div>
                        </div>
                    </div>
                    <div class="data-grid-row-1 data-grid-row-3">
                        <div class="table-cell">
                            <div class="stack-1 stack-5">
                                <div class="avatar">
                                    <div class="container">
                                        <img class="iconsic_replace"
                                             src="assets/common/images/edit-widget/axes/icons-ic-replace-1.svg"
                                             alt="icons/ic_replace">
                                    </div>
                                </div>
                                <div class="name2">Energy loss</div>
                            </div>
                        </div>
                    </div>
                    <div class="data-grid-row-3">
                        <div class="table-cell">
                            <div class="stack-1 stack-5">
                                <div class="avatar">
                                    <div class="container">
                                        <img class="iconsic_replace"
                                             src="assets/common/images/edit-widget/axes/icons-ic-replace-1.svg"
                                             alt="icons/ic_replace">
                                    </div>
                                </div>
                                <div class="name2">Energy loss</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="user-account-update-user-form">
                <div class="value-range">
                    <div class="specification publicsans-bold-charade-12px">VALUE RANGE</div>
                    <div class="stack-3 stack-5">
                        <div class="slider">
                            <div class="custom-slider">
<!--                                <ngx-slider [(value)]="value" [(highValue)]="highValue" [options]="options"></ngx-slider>-->
                            </div>
                        </div>
                        <div class="text-field">
                            <div class="input">
                                <div class="container-1">
                                    <div class="label-focus">Range</div>
                                    <div class="content">
                                        <div class="name1">12-80</div>
                                    </div>
                                </div>
                                <div class="space-m"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tooltip">
                    <div class="text-field-1">
                        <div class="input">
                            <div class="container-1">
                                <div class="label-focus">Tooltip Decimals</div>
                                <div class="content">
                                    <div class="name1">Default: 2</div>
                                </div>
                            </div>
                            <div class="space-m"></div>
                        </div>
                        <img class="select-arrow" src="assets/common/images/edit-widget/axes/select-arrow.svg"
                             alt="select arrow">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="user-account-update-user-form">
                <div class="specification publicsans-bold-charade-12px">CONFIGURATION</div>
                <div class="conf">
                    <article class="switch">
                        <label class="toggle-switch">
                            <input type="checkbox" [(ngModel)]="selected">
                            <div class="f-slider"></div>
                        </label>
                        <div class="label publicsans-normal-charade-14px">Show Ticks</div>
                    </article>
                    <article class="switch">
                        <label class="toggle-switch">
                            <input type="checkbox" [(ngModel)]="selected">
                            <div class="f-slider"></div>
                        </label>
                        <div class="label publicsans-normal-charade-14px">Show Grids</div>
                    </article>
                </div>
                <div class="position">
                    <div class="specification-1 publicsans-bold-charade-12px">POSITION</div>
                    <div class="stack-4 stack-5">
                        <article class="radio-button">
                            <div class="icon-container">
                                <label>
                                    <input name="position" [checked]="true" value="1" type="radio" />
                                    <svg class="svg" fill="currentColor" preserveAspectRatio="xMidYMid meet" height="20px" width="20px" viewBox="0 0 34 34">
                                        <circle class="radioOutline" cx="17" cy="17" r="15" fill="none" stroke="#890C58" stroke-width="3" />
                                        <circle class="radioDot" cx="17" cy="17" r="8" fill="#890C58" />
                                    </svg>
                                </label>
                            </div>
                            <div class="label publicsans-normal-charade-14px">Left</div>
                        </article>
                        <article class="radio-button">
                            <div class="icon-container">
                                <label>
                                    <input name="position" value="1" type="radio" />
                                    <svg class="svg" fill="currentColor" preserveAspectRatio="xMidYMid meet" height="20px" width="20px" viewBox="0 0 34 34">
                                        <circle class="radioOutline" cx="17" cy="17" r="15" fill="none" stroke="#890C58" stroke-width="3" />
                                        <circle class="radioDot" cx="17" cy="17" r="8" fill="#890C58" />
                                    </svg>
                                </label>
                            </div>
                            <div class="label publicsans-normal-charade-14px">Right</div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
