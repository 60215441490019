<div class="toolbox">
    <div class="d-flex parent-tool" *ngFor="let tool of tools">
        <button (click)="tool.clickEvent()" [class]="tool.class" [disabled]="tool.disabled">
            <i class="la icon-2x" [class]="tool.icon"></i>
        </button>
        <div class="child-tool" *ngIf="tool.children?.length">
            <button *ngFor="let childTool of tool.children" (click)="childTool.clickEvent()" [class]="childTool.class" [disabled]="childTool.disabled">
                <i class="la icon-2x" [class]="childTool.icon"></i>
            </button>
        </div>
    </div>

</div>
