<div class="bottom-draweru58-settings">
    <div class="frame-43 frame">
        <div class="stack">
            <div class="col-md-3">
                <div class="user-account-update-user-form">
                    <div class="specification publicsans-bold-charade-12px">INFO</div>
                    <div class="form-floating w-100">
                        <select [(ngModel)]="selectedUnitModelId" (ngModelChange)="onUnitModelChange($event)"
                                class="form-select" id="modelSelect" aria-label="Please select a model">
                            <option *ngFor="let model of unitModels" [value]="model.id"
                                    [selected]="model.id==selectedUnitModelId">{{model.name}}</option>
                        </select>
                        <label for="modelSelect">Please select a model</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
