import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[appHighlightText][name]',
})
export class HighlightTextDirective implements OnChanges {
    @Input() name: string;
    @Input() appHighlightText: string;

    constructor(private readonly host: ElementRef<HTMLElement>) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('appHighlightText' in changes) {
            const { currentValue, previousValue } = changes.appHighlightText;

            if (currentValue !== previousValue) {
                const pattern = new RegExp(currentValue, 'ig');
                const callback = (match: string) => `<span class="highlighted-query">${match}</span>`;
                this.host.nativeElement.innerHTML = this.name.replace(pattern, callback);
            }
        }
    }
}
