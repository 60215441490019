import { Component, Injector, Input, OnInit, Optional } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { BsModalRef } from "@node_modules/ngx-bootstrap/modal";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import * as _ from "lodash";


export class OptionTable {
    spacing: string;
    columns: TableColumnOption[] = [];
}

export class TableColumnOption {

    id: string;
    name: string;
    desc: string;
    index: number;
    isVisible: boolean;

    constructor(id: string, name: string, desc: string, index: number, isVisible: boolean) {
        this.id = id;
        this.name = name;
        this.desc = desc;
        this.index = index;
        this.isVisible = isVisible;
    }
}


@Component({
    selector: "tableOption",
    templateUrl: "./table-option.component.html",
    styleUrls: ["./table-option.component.scss"]
})
export class TableOptionComponent extends AppComponentBase implements OnInit {

    @Input() optionTable: OptionTable;
    @Input() defaultOptionTable: OptionTable;
    saveChangesClick: () => void;

    constructor(
        injector: Injector,
        @Optional() private readonly modalRef: BsModalRef
    ) {
        super(injector);
    }

    ngOnInit() {
    }

    onCloseClick() {
        this.modalRef.hide();
    }

    changeColumn(event, column) {
        let item = this.optionTable.columns.find(x => x.id == column.id);
        item.isVisible = !item.isVisible;
    }

    onSaveClick() {
        let optionTable = _.cloneDeep(this.optionTable);
        abp.event.trigger("dashboard-option", optionTable);
        this.saveChangesClick();
        this.modalRef.hide();
    }

    changeSpacing(spacing) {
        this.optionTable.spacing = spacing;
    }

    drop(event: CdkDragDrop<[]>) {
        moveItemInArray(this.optionTable.columns, event.previousIndex, event.currentIndex);
    }

    onDefaultClick() {
        this.optionTable = _.cloneDeep(this.defaultOptionTable);
    }

}

