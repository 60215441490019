import {PowerPlant, PowerPlantUnitDto} from "@shared/service-proxies/service-proxies";

export class SubHeader {
    id: number;
    isFullScreen: boolean = false;
    dashboardName?: string;
    powerPlantUnitList: PowerPlantUnitDto[] = [];
    turbineName: string | null;
    startDate: Date | null;
    endDate: Date | null;
    powerPlants: PowerPlant[] = [];
    selectedPowerPlant: PowerPlant | null;
    selectedPlantList?: PowerPlant[] = [];
    selectedPowerPlantUnitList?: PowerPlantUnitDto[] = [];

}
