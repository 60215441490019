<div bsModal #createOrEditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="createOrEditModal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-xs">
        <div class="modal-content">
            <div class="dialogue-delete-dashboard">
                <div class="dialog dialog-2 modal-header">
                    <img class="icon" src="/assets/dashboard/icons-ic-trash-1.svg" alt="icon-trash"/>
                    <div class="title">Delete {{pageName}}?</div>
                    <a (click)="close()" class="close">
                        <div class="icon-button"><img class="icon"
                                                      src="/assets/dashboard/icons-ic-close.svg"
                                                      alt="icons/ic_close"/></div>
                    </a>
                </div>
                <div *ngIf="dashboard" class="content">
                    <p class="text">
                        <span class="span0">You’re about to delete: </span><span
                        class="span1">“{{this.dashboard.name}}”<br/></span
                    ><span class="span2"></span>
                    </p>
                </div>
                <div class="help-text">
                    <img class="iconsic_info" src="/assets/dashboard/icons-ic-info.svg" alt="icons/ic_info"/>
                    <p class="helper-text">Deleted {{pageName.toLowerCase()}} will be accessible thru “Deleted” section for 10
                        days.</p>
                </div>
                <div class="modal-footer dialog-1 dialog-2">
                    <div class="button button-5" (click)="archiveInstead()" style="cursor: pointer;">
                        <div class="label label-3">Archive Instead</div>
                    </div>
                    <div class="frame-67">
                        <button type="button" (click)="close()" class="button-1111 button-3333">
                            <div class="label-1 valign-text-middle label-5555 ">{{l("Cancel")}}</div>
                        </button>
                        <button [disabled]="saving" (click)="delete()" class="button-222 button-333">
                            <img class="start-icon-1" src="/assets/dashboard/start-icon-64.svg" alt="start icon"/>
                            <div class="label-222 valign-text-middle label-555">{{l("Delete")}}</div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
