import { AfterViewInit, Component, Injector, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import * as Highcharts from "@node_modules/highcharts/highstock";
import { Options, SeriesOptionsType } from "@node_modules/highcharts/highstock";
import { AlertSeverity } from "@shared/service-proxies/service-proxies";
import { DateTime } from "luxon";
import { DateFilterOption } from "@app/shared/models/DateFilterOption";
import { DateTimeService } from "@app/shared/common/timing/date-time.service";

export interface IAlertSeverityWithDateDto {
    date: DateTime;
    formattedDate?: string;
    severity: AlertSeverity;
}

@Component({
    selector: "counts-of-seven-days-chart",
    templateUrl: "./counts-of-seven-days-chart.component.html",
    styleUrls: ["./counts-of-seven-days-chart.component.scss"]
})
export class CountsOfSevenDaysChartComponent extends AppComponentBase implements OnInit, AfterViewInit, OnChanges {

    @Input() class: any;
    @Input() height: number = 75;
    @Input() width: number = 200;
    @Input() chartId: string = "counts-of-last-seven-days-chart";
    @Input() mergeWithDefaultOptions: boolean = true;
    @Input() dateFilterOption: DateFilterOption = DateFilterOption.LastOneWeek;
    @Input() data: IAlertSeverityWithDateDto[] = [];
    @Input() isOnlyAipactAlerts: boolean = false;
    @Input() isOnlyForOnepactAlerts: boolean = false;
    @Input() onePactAlertSeverity: AlertSeverity;

    private chart: Highcharts.StockChart;
    private dateCategories: string[] = [];

    constructor(
        injector: Injector,
        private readonly _dateTimeService: DateTimeService
    ) {
        super(injector);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && !changes.data.firstChange && changes.data.currentValue) {
            this.setDateCategories();
            this.setFormattedData();
            this.updateChart();
        }
    }

    ngOnInit(): void {
        this.setDateCategories();
        this.setFormattedData();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.chart = Highcharts.chart(this.chartId, this.getDefaultOptions());
        }, 100);
    }

    private setDateCategories() {
        let today: DateTime;
        let startDate: DateTime;
        switch (this.dateFilterOption) {
            case DateFilterOption.LastOneWeek:
                let daysOfLastWeek: string[] = [];
                today = this._dateTimeService.getStartOfDay();
                startDate = today.minus({ weeks: 1 });
                let countOfDaysInLastWeek = today.diff(startDate, "days").days;
                for (let i = 1; i <= countOfDaysInLastWeek; i++) {
                    daysOfLastWeek.push(startDate.plus({ days: i }).toFormat("dd LLL yyyy"));
                }

                this.dateCategories = daysOfLastWeek;
                break;
            case DateFilterOption.LastOneMonth:
                let daysOfLastMonth: string[] = [];
                today = this._dateTimeService.getStartOfDay();
                startDate = today.minus({ months: 1 });
                let countOfDaysInLastMonth = today.diff(startDate, "days").days;
                for (let i = 1; i <= countOfDaysInLastMonth; i++) {
                    daysOfLastMonth.push(startDate.plus({ days: i }).toFormat("dd LLL yyyy"));
                }

                this.dateCategories = daysOfLastMonth;
                break;
            case DateFilterOption.LastSixMonths:
                let monthsOfLastSixMonths: string[] = [];
                today = this._dateTimeService.getStartOfDay();
                startDate = today.minus({ months: 5 });
                let countOfMonthsInLastSixMonths = today.diff(startDate, "months").months;
                for (let i = 0; i <= countOfMonthsInLastSixMonths; i++) {
                    monthsOfLastSixMonths.push(startDate.plus({ months: i }).toFormat("LLL yyyy"));
                }

                this.dateCategories = monthsOfLastSixMonths;
                break;
            case DateFilterOption.LastTwelveMonths:
                let monthsOfLastTwelveMonths: string[] = [];
                today = this._dateTimeService.getStartOfDay();
                startDate = today.minus({ months: 11 });
                let countOfMonthsInLastTwelveMonths = today.diff(startDate, "months").months;
                for (let i = 0; i <= countOfMonthsInLastTwelveMonths; i++) {
                    monthsOfLastTwelveMonths.push(startDate.plus({ months: i }).toFormat("LLL yyyy"));
                }

                this.dateCategories = monthsOfLastTwelveMonths;
                break;
        }
    }

    setFormattedData() {
        switch (this.dateFilterOption) {
            case DateFilterOption.LastOneWeek:
            case DateFilterOption.LastOneMonth:
                this.data.forEach(item => {
                    item.formattedDate = item.date.toFormat("dd LLL yyyy");
                });
                break;
            case DateFilterOption.LastSixMonths:
            case DateFilterOption.LastTwelveMonths:
                this.data.forEach(item => {
                    item.formattedDate = item.date.toFormat("LLL yyyy");
                });
                break;
        }
    }

    private getDefaultOptions(): Options {
        return {
            chart: {
                type: "column",
                width: this.width,
                height: this.height,
                alignTicks: true,
                marginTop: 0,
                marginBottom: 0,
                marginLeft: 0,
                marginRight: 0,
            },
            title: {
                text: null
            },
            xAxis: {
                title: {
                    text: null
                },
                gridLineWidth: 0,
                lineColor: "transparent",
                tickColor: "transparent",
                categories: this.dateCategories
            },
            yAxis: {
                min: 0,
                title: {
                    text: null
                },
                gridLineWidth: 0,
                lineColor: "transparent",
                tickColor: "transparent"
            },
            scrollbar: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            plotOptions: {
                column: {
                    pointPadding: 0.1,
                    borderWidth: 0,
                    borderRadius: 2,
                    minPointLength: 3,
                    pointWidth: 9,
                    color: "#36B37E" // Default color for all bars unless overridden
                }
            },
            credits: { enabled: false },
            //@ts-ignore
            series: [...this.getSeries()]
        };
    }

    showLoading() {
        if (this.chart) {
            this.chart.showLoading();
        }
    }

    hideLoading() {
        if (this.chart) {
            this.chart.hideLoading();
        }
    }

    public updateChart() {
        setTimeout(() => {
            if (this.chart) {
                this.chart.update(this.getDefaultOptions(), true, false, false);
            }

            this.hideLoading();
        });
    }

    private getSeverityColorCode(severity: AlertSeverity): string {
        switch (severity) {
            case AlertSeverity.Low:
                return "#637381";
            case AlertSeverity.Medium:
                return "#FFAB00";
            case AlertSeverity.High:
                return "#FF5630";
            default:
                return "#36B37E";
        }
    }

    private getSeries() {
        if (this.isOnlyForOnepactAlerts) {
            return [
                <SeriesOptionsType>{
                    name: this.l("Count"),
                    data: this.dateCategories.map(date => {
                        return this.data.filter(x => x.formattedDate === date).length
                    }),
                    color: this.getSeverityColorCode(this.onePactAlertSeverity)
                }
            ];
        } else {
            let highSeveritySeries = this.data.filter(x => x.severity === AlertSeverity.High);
            let mediumSeveritySeries = this.data.filter(x => x.severity === AlertSeverity.Medium);
            let series = [
                <SeriesOptionsType>{
                    name: (this.isOnlyAipactAlerts ? this.l("Critical") : this.l(AlertSeverity[AlertSeverity.High])) + " " + this.l("Alerts"),
                    data: this.dateCategories.map(date => {
                        return highSeveritySeries.filter(x => x.formattedDate === date).length
                    }),
                    color: this.getSeverityColorCode(AlertSeverity.High)
                },
                <SeriesOptionsType>{
                    name: (this.isOnlyAipactAlerts ? this.l("Warning") : this.l(AlertSeverity[AlertSeverity.Medium])) + " " + this.l("Alerts"),
                    data: this.dateCategories.map(date => {
                        return mediumSeveritySeries.filter(x => x.formattedDate === date).length
                    }),
                    color: this.getSeverityColorCode(AlertSeverity.Medium)
                }
            ];
            if (!this.isOnlyAipactAlerts) {
                let lowSeveritySeries = this.data.filter(x => x.severity === AlertSeverity.Low);
                series.push(<SeriesOptionsType>{
                    name: this.l(AlertSeverity[AlertSeverity.Low]) + " " + this.l("Alerts"),
                    data: this.dateCategories.map(date => {
                        return lowSeveritySeries.filter(x => x.formattedDate === date).length
                    }),
                    color: this.getSeverityColorCode(AlertSeverity.Low)
                });
            }

            return series;
        }
    }
}
