<div bsModal #createModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="createOrEditModal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="new-dashboard-modal-step-1 screen">
                <div class="dialog modal-header">
                    <img class="iconsic" src="/assets/dashboard/create-dashboard/new-dashboard.svg"
                         alt="icons/ic_replace" />
                    <div class="title-1 title-3">{{ l("CreateNewDashboard") }}</div>
                    <div class="close">
                        <a (click)="close()" style="cursor: pointer;" class="icon-button"><img class="iconsic"
                                                                                               src="/assets/dashboard/create-dashboard/icons-ic-close-1.svg"
                                                                                               alt="icons/ic_close" /></a>
                    </div>
                </div>
                <div class="select-type">
                    <div class="steps">

                        <article *ngFor="let tab of tabs; let i = index"
                                 class="stepper-elements-vertical">
                            <div class="stack">
                                <div [class]="tabIndex==i || i<tabIndex? 'iconsic_number-1':'iconsic_number'">
                                    <div class="shape valign-text-middle publicsans-normal-white-16px">
                                        <span *ngIf="i<tabIndex">✓</span>
                                        <span *ngIf="i>tabIndex || i==tabIndex">{{ tab.number }}</span>
                                    </div>
                                </div>
                                <div class="text">
                                    <div class="title-2 title-3">{{ tab.title }}</div>
                                </div>
                            </div>
                            <div class="stack-1">
                                <div class="line-1">
                                    <div class="shape-2 shape-3"></div>
                                </div>
                                <div class="content" *ngIf="tabIndex==i">
                                    <div class="text-2">
                                        <p class="text-3">{{ tab.description }}</p>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                    <img class="divider" src="/assets/dashboard/create-dashboard/divider.svg" alt="Divider" />
                    <div [ngSwitch]="tabIndex">
                        <div *ngSwitchCase="0" class="frame-75">
                            <div (click)="changeMode(DashboardMode.RealTime)"
                                 [class]="'real-time-card card card-hover border rounded-3' + (createDashboardDto.mode == DashboardMode.RealTime ? ' active-card' : '')"
                                 data-testid="real-time-new-dashboard">
                                <img class="real-time-icon rounded-top" alt="real-time-icon" />
                                <div class="card-body" style="margin-top: 10px;">
                                    <div class="text-1" style="margin-bottom: 10px;">{{ l("RealTime") }}</div>
                                </div>
                            </div>
                            <div (click)="changeMode(DashboardMode.TimeBasedDaily)"
                                 [class]="'time-based-daily-card card card-hover border rounded-3' + (createDashboardDto.mode == DashboardMode.TimeBasedDaily ? ' active-card' : '')"
                                 data-testid="time-based-Daily-new-dashboard">
                                <img class="time-based-icon rounded-top" alt="time-based-icon" />
                                <div class="card-body">
                                    <div class="text-1" style="font-weight: 10 !important;">{{ l("TimeBased") }}</div>
                                    <div class="text-1">{{ l("Daily") }}</div>
                                </div>
                            </div>
                            <div (click)="changeMode(DashboardMode.TimeBasedMonthly)"
                                 [class]="'time-based-monthly-card card card-hover border rounded-3' + (createDashboardDto.mode == DashboardMode.TimeBasedMonthly ? ' active-card' : '')"
                                 data-testid="time-based-new-dashboard">
                                <img class="time-based-icon rounded-top" alt="time-based-icon" />
                                <div class="card-body">
                                    <div class="text-1" style="font-weight: 10 !important;">{{ l("TimeBased") }}</div>
                                    <div class="text-1">{{ l("Monthly") }}</div>
                                </div>
                            </div>
                            <div (click)="changeMode(DashboardMode.TimeBasedYearly)"
                                 [class]="'time-based-yearly-card card card-hover border rounded-3' + (createDashboardDto.mode == DashboardMode.TimeBasedYearly ? ' active-card' : '')"
                                 data-testid="time-based-new-dashboard">
                                <img class="time-based-icon rounded-top" alt="time-based-icon" />
                                <div class="card-body">
                                    <div class="text-1" style="font-weight: 10 !important;">{{ l("TimeBased") }}</div>
                                    <div class="text-1">{{ l("Yearly") }}</div>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase="1" class="frame-75">
                            <div (click)="changeType(DashboardType.PowerPlant)"
                                 [class]="'power-plant-card card card-hover border rounded-3' + (createDashboardDto.type == DashboardType.PowerPlant ? ' active-card' : '')"
                                 data-testid="power-plant-new-dashboard">
                                <img class="power-plant-icon rounded-top" alt="power-plant-icon" />
                                <div class="card-body">
                                    <div class="text-1">{{ l("PowerPlant") }}</div>
                                </div>
                            </div>
                            <div [ngStyle]="{'display': tenancyName === TENANT_SabanciRenewables ? 'none' : 'flex'}" *ngIf="createDashboardDto.mode == DashboardMode.TimeBasedDaily ||
                             createDashboardDto.mode == DashboardMode.TimeBasedMonthly ||
                            createDashboardDto.mode == DashboardMode.TimeBasedYearly"
                                 (click)="changeType(DashboardType.Generator)"
                                 [class]="'generator-card card card-hover border rounded-3' + (createDashboardDto.type == DashboardType.Generator ? ' active-card' : '')"
                                 data-testid="generator-new-dashboard">
                                <img class="generator-icon rounded-top" alt="power-plant-icon" />
                                <div class="card-body">
                                    <div class="text-1">{{ l("Unit") }}</div>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase="2" class="frame-67">
                            <div class="filter">
                                <div class="file_-manager-filters">
                                    <div class="stack-1 stack-4">
                                        <div class="text-field" onclick="" onmouseover="">
                                            <input type="text" class="input" placeholder="Search"
                                                   (input)="getContents()" [(ngModel)]="this.filterText" />
                                        </div>
                                        <button class="button" (click)="resetFilter()"
                                                style="border: none; background-color: rgba(255, 86, 48, 0.161); padding: 6px 12px; height: 35px;  color: #B71D18;">
                                            <img class="start-icon" src="/assets/new-widget/start-icon-14.svg"
                                                 alt="start icon" />
                                            <div class="label-55 label-9">{{ l("Clear") }}</div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="table">
                                <div class="content-1">
                                    <div class="table-row-file"
                                         *ngFor="let content of dashboardContents;let index = index">
                                        <div class="table-cell">

                                            <div class="stack-2 stack-4">
                                                <div class="checkbox">
                                                    <div class="icon-container icon">
                                                        <input id="select-{{content.Name}}" type="checkbox"
                                                               name="ppu_content" [checked]="content.IsSelected"
                                                               (change)="changeSelection(content)"
                                                               class="form-check-input">
                                                    </div>
                                                </div>
                                                <div class="file-name1 publicsans-normal-charade-16px">
                                                    {{ content.Title }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dialog-1 modal-footer ">
                    <button type="button" data-testid="new-dashboard-back-button" class="button-56" *ngIf="tabIndex!=0"
                            style="cursor: pointer"
                            (click)="setActiveTab(tabIndex-1)">
                        <img class="back-icon" src="/assets/dashboard/create-dashboard/back.svg" alt="end icon" />
                        <div class="label valign-text-middle" style="color: #000000">{{ l('Back') }}</div>
                    </button>
                    <div class="button-556" data-testid="next-button-dashboard-template-disable"
                         *ngIf="(tabIndex == 0 && createDashboardDto.mode==null) || (tabIndex==1 && createDashboardDto.type==null)">
                        <div class="label valign-text-middle" style="color: white">{{ l('Next') }}</div>
                        <img class="back-icon" src="/assets/dashboard/create-dashboard/end-icon-black.svg"
                             alt="end icon" />
                    </div>
                    <div class="button-55" data-testid="next-button-dashboard-template-enable"
                         *ngIf="tabIndex!=2 && ((tabIndex == 0 && createDashboardDto.mode!=null) || (tabIndex==1 && createDashboardDto.type!=null))"
                         style="cursor: pointer"
                         (click)="setActiveTab(tabIndex+1)">
                        <div class="label valign-text-middle" style="color: white">{{ l('Next') }}</div>
                        <img class="end-icon" src="/assets/dashboard/create-dashboard/end-icon.svg" alt="end icon" />
                    </div>
                    <button type="button" data-testid="create-button" class="button-55" *ngIf="tabIndex===2"
                            [disabled]="saving" style="border: none;cursor: pointer" (click)="createDashboard()">
                        <div class="label valign-text-middle" style="color: white">{{ l('Create') }}</div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
