<div class="error500 screen">
    <div class="container">
        <div class="head">
            <h1 class="sorry-page-not-foun">500 Internal Server Error</h1>
            <p class="sorry-we-couldnt-f">
                There was an error, please try again later.
            </p>
        </div>
        <div class="x404-error-bro-1"></div>
        <div class="button"><div class="label" (click)="goToHome()">Go to Home</div></div>
    </div>
    <div class="logo"><img class="unknown-3" src="/assets/common/images/onepact_logo_light-1.png" alt="OnePact" /></div>
</div>
