export class GeneralWidgetConfigDto {
    visible: boolean;
    name: string;
    description: string;
    isFilterTopRecords?: boolean = false;
    isFilterErrorCode?: boolean = false;
    filterTopRecordFilters?: number[] = [];
    selectedTopRecordFilter?: number;
    selectedMainStatus?: string;
}
