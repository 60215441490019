import { Component, ElementRef, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DateRangeValue } from "@app/shared/common/forms/date-range-picker/date-range-picker.model";
import { DatePipe } from "@angular/common";
import { isNil } from "lodash-es";

@Component({
    selector: 'app-date-range-picker',
    templateUrl: './date-range-picker.component.html',
    styleUrls: ['./date-range-picker.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: DateRangePickerComponent,
            multi: true
        },
        DatePipe
    ]
})
export class DateRangePickerComponent implements ControlValueAccessor {
    dateRange: [Date, Date];
    isDisabled: boolean;

    startDateLabel: string;
    endDateLabel: string;

    @ViewChild('startDate', { static: true }) startDateElement: ElementRef<HTMLDivElement>;
    @ViewChild('endDate', { static: true }) endDateElement: ElementRef<HTMLDivElement>;

    constructor(
        private readonly datePipe: DatePipe,
        private readonly renderer: Renderer2
    ) {
    }

    onChange: (value: DateRangeValue) => void;
    onTouched: () => void;

    onDateRangeChange(dates: (Date | undefined)[]) {
        const [startDate, endDate] = dates;
        const dateRangeValue = { startDate, endDate };
        this.updateLabels(dateRangeValue);
        this.onTouched();
        this.onChange({ startDate, endDate })
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.renderer.setProperty(this.startDateElement.nativeElement, 'disabled', isDisabled)
        this.renderer.setProperty(this.endDateElement.nativeElement, 'disabled', isDisabled)

        this.isDisabled = isDisabled;
    }

    writeValue(value: DateRangeValue): void {
        const { startDate, endDate } = value ?? {};
        this.updateLabels(value);
        this.dateRange = [this.getNormalizeDateValue(startDate), this.getNormalizeDateValue(endDate)];
    }

    private getNormalizeDateValue(date: string | Date | number): Date {
        switch (typeof date) {
            case 'undefined':
                return null;
            case "string":
                const normalizedDate = new Date(date) as Date | string;
                if (normalizedDate === 'Invalid Date') return null;
                return normalizedDate as Date;
            case "number":
                return new Date(date * 1000);
            case "object":
                return date instanceof Date ? date : null
            default:
                throw new Error("Unknown date format provided: " + typeof date)
        }
    }

    private updateLabels(dateRangeValue: DateRangeValue) {
        const { startDate, endDate } = dateRangeValue ?? {};
        this.startDateLabel = this.normalizeDateLabel(startDate);
        this.endDateLabel = this.normalizeDateLabel(endDate);
    }

    private normalizeDateLabel(startDate: Date) {
        return isNil(startDate) ? null : this.datePipe.transform(startDate, 'dd/MM/yyyy');
    }
}
