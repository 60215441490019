import { Component, Injector, Input, OnInit, ViewChild } from "@angular/core";
import {
    BaseComponentsServiceProxy, MeasurementUnitBaseComponents,
    PowerPlant, PowerPlantAndUnitDto,
    PowerPlantUnitsServiceProxy,
    ReportDashboardServiceProxy
} from "@shared/service-proxies/service-proxies";
import { Options } from "@node_modules/@angular-slider/ngx-slider";
import { EditWidgetConfigDto } from "@app/state-management/models/edit-widget-config";
import * as ReportTrialsUtilities from "@app/main/report-trials/utilities";
import { NewDataComponent } from "@app/shared/common/edit-widget/data/new-data/new-data.component";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { AppComponentBase } from "@shared/common/app-component-base";
import * as _ from "lodash";
import { LocalStorageService } from "@shared/utils/local-storage.service";
import { Store } from "@node_modules/@ngrx/store";
import { DataWidgetConfigContent, LegedDto, SelectedLegend } from "@app/main/report-trials/new-widget/app-widget-item";
import { SelectItemGroup } from "@node_modules/primeng/api";
import { DateTimeService } from "@app/shared/common/timing/date-time.service";


@Component({
    selector: "edit-data",
    templateUrl: "./data.component.html",
    styleUrls: ["./data.component.scss"]
})
export class EditDataComponent extends AppComponentBase implements OnInit {
    @Input() powerPlant: PowerPlant;
    searchvalue = "";
    selected: boolean = false;
    value: number = 3;
    activeId: number = -1;
    xAxisActiveId: number = -1;
    options: Options = {
        ceil: 8,
        floor: 0,
        showSelectionBar: true,
        showTicks: true
    };
    config: EditWidgetConfigDto;
    yAxiscolumns: DataWidgetConfigContent[] = [];
    xAxiscolumns: DataWidgetConfigContent[] = [{
        id: 0,
        uuid: "0",
        name: "Time",
        type: "xAxis",
        isSelected: true,
        dataSpecs: [],
        timeFrequency: "10",
        aggregate: "",
        unit: "",
        index: -1
    }];
    yAxis = "yAxis";
    xAxis = "xAxis";
    activeColumn: DataWidgetConfigContent = new DataWidgetConfigContent();
    chartType: string = "line";
    powerPlantUnitList: PowerPlantAndUnitDto[];
    measurementUnits: MeasurementUnitBaseComponents[] = [];
    multiScale: boolean = false;
    legendEnable: boolean = false;
    legend: string = "None";

    groupedCities!: SelectItemGroup[];

    selectedPowerPlantUnit: string[] = [];
    years: LegedDto[] = [];
    months: LegedDto[] = [];

    selectedYears: LegedDto[] = [];
    selectedMonths: LegedDto[] = [];
    isSelectedYears: boolean = false;

    selectedLegend: SelectedLegend;


    constructor(
        injector: Injector,
        private _reportDashboardServiceProxy: ReportDashboardServiceProxy,
        private _localStorageService: LocalStorageService,
        private _powerPlantUnitAppService: PowerPlantUnitsServiceProxy,
        private _baseComponentsServiceProxy: BaseComponentsServiceProxy,
        private store: Store,
        private _dateTimeService: DateTimeService
    ) {
        super(injector);
    }

    ngOnInit() {
        this.months = [];
        this.getDataSpecs();
        this.activeColumn = new DataWidgetConfigContent();
        this.yAxiscolumns = [];
        this.xAxiscolumns = [{
            id: 0,
            uuid: "0",
            name: "Time",
            type: "xAxis",
            isSelected: true,
            dataSpecs: [],
            timeFrequency: "10",
            aggregate: "",
            unit: "",
            index: -1
        }];
        this.xAxisActiveId = -1;
        this.activeId = -1;
        this.store.select((state) => state).subscribe((state: any) => {
            if (!_.isNil(state.editWidget.data.general)) {
                this.config = state.editWidget.data;
                this.getDataSpecs();
                if (this.config.data.visible) {
                    this.xAxisActiveId = -1;
                    this.activeId = -1;
                    this.activeColumn = new DataWidgetConfigContent();
                    if (this.config.data.yAxis) {
                        let jstring = JSON.stringify(this.config.data.yAxis);
                        let jsonObj = JSON.parse(jstring);
                        this.yAxiscolumns = jsonObj as DataWidgetConfigContent[];
                    } else
                        this.yAxiscolumns = [];

                    if (this.config.data.xAxis) {
                        let jstring = JSON.stringify(this.config.data.xAxis);
                        let jsonObj = JSON.parse(jstring);
                        this.xAxiscolumns = jsonObj as DataWidgetConfigContent[];
                    } else {
                        this.xAxiscolumns = [{
                            id: 0,
                            uuid: "0",
                            name: "Time",
                            type: "xAxis",
                            isSelected: true,
                            dataSpecs: [],
                            timeFrequency: "10",
                            aggregate: "",
                            unit: "",
                            index: -1
                        }];
                    }

                    if (this.config.data.chartType)
                        this.chartType = this.config.data.chartType;

                    if (this.config.data.multiScale)
                        this.multiScale = this.config.data.multiScale;
                    else
                        this.multiScale = false;

                    if (this.config.data.legendEnable)
                        this.legendEnable = this.config.data.legendEnable;
                    else
                        this.legendEnable = false;

                    if (this.config.data.legend)
                        this.legend = this.config.data.legend;
                    else
                        this.legend = "None";

                    if (this.config.data.selectedYears)
                        this.selectedYears = this.config.data.selectedYears;
                    else
                        this.selectedYears = [];

                    if (this.config.data.selectedMonths) {
                        this.selectedYears.forEach(year => {
                            this.months.push(
                                { name: year.name + " January", value: year.value + ".1" },
                                { name: year.name + " February", value: year.value + ".2" },
                                { name: year.name + " March", value: year.value + ".3" },
                                { name: year.name + " April", value: year.value + ".4" },
                                { name: year.name + " May", value: year.value + ".5" },
                                { name: year.name + " June", value: year.value + ".6" },
                                { name: year.name + " July", value: year.value + ".7" },
                                { name: year.name + " August", value: year.value + ".8" },
                                { name: year.name + " September", value: year.value + ".9" },
                                { name: year.name + " October", value: year.value + ".10" },
                                { name: year.name + " November", value: year.value + ".11" },
                                { name: year.name + " December", value: year.value + ".12" }
                            );
                        });

                        this.selectedMonths = this.config.data.selectedMonths;
                    } else
                        this.selectedMonths = [];

                    this.isSelectedYears = this.selectedYears.length > 0;
                    if (!this.isSelectedYears)
                        this.selectedMonths = [];
                }
            }
        });

        let now = this._dateTimeService.getUTCDate();

        let year = new LegedDto();
        for (let i = 0; i < 3; i++) {
            let newNowDate = this._dateTimeService.plusYears(now, i * -1);
            year = new LegedDto();
            year.name = newNowDate.toFormat("yyyy");
            year.value = newNowDate.toFormat("yyyy");
            this.years.push(year);
        }

    }

    @ViewChild("newdataModal", { static: true }) newdataModal: NewDataComponent;


    public ShowOverlay(overlayName, animationName) {
        ReportTrialsUtilities.ShowOverlay(overlayName, animationName);
    };

    addData(item: DataWidgetConfigContent[]) {
        if (item.length > 0 && item[0].type == this.xAxis) {
            this.activeColumn = new DataWidgetConfigContent();
            if (this.xAxiscolumns.length == 1)
                this.xAxiscolumns = [];
            this.xAxiscolumns.push(item[0]);
            if (item[0].id != 0) {
                this.typeChange("scatter");
            }
            else{
                this.typeChange('line')
            }
            abp.event.trigger(this.config.divId + "-data-xAxis-add", item[0]);
        } else {
            this.yAxiscolumns = [];
            abp.event.trigger(this.config.divId + "-data-yAxis-add", item);
            this.yAxiscolumns = this.yAxiscolumns.concat(item);
        }
    }


    dragDrop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.yAxiscolumns, event.previousIndex, event.currentIndex);
        this.pre = JSON.stringify(this.yAxiscolumns, null, " ");
    }

    markdown = `
# Material Design: Angular 7, drag-and-drop list
requires:
- \`CdkDragDrop\` and \`moveItemInArray\` imports
- \`cdkDropList\` directive and \`cdkDropListDropped\` event
- CSS \`flex\` layout

😒 setting \`cdkDropListOrientation\` to \`horizontal\` requires a CSS change
`;

    pre = JSON.stringify(this.yAxiscolumns, null, " ");

    seriesClick(id: number) {
        if (this.yAxiscolumns.find(x => x.id == id).dataSpecs)
            this.selectedPowerPlantUnit = this.yAxiscolumns.find(x => x.id == id).dataSpecs;
        else
            this.selectedPowerPlantUnit = [];

        this.xAxisActiveId = -1;
        this.activeId = id;
        this.activeColumn = this.yAxiscolumns.find(x => x.id == id);
        let _this = this;
        let unit = this.activeColumn.unit;
        this.measurementUnits = [];
        setTimeout(function() {
            _this.getMeasurementUnitBaseComponents(_this.activeColumn.id, unit);
        }, 500);
    }

    seriesClickXaxis(id: number) {
        this.activeId = -1;
        this.xAxisActiveId = id;
        this.activeColumn = this.xAxiscolumns.find(x => x.id == id);
        let unit = this.activeColumn.unit;
        let _this = this;
        setTimeout(function() {
            _this.getMeasurementUnitBaseComponents(_this.activeColumn.id, unit);
        }, 500);
    }

    alert(number: number) {
        alert(number);
    }

    removeColumn(number: number) {
        const item = this.yAxiscolumns.find(x => x.id == number);
        if (item) {
            const index = this.yAxiscolumns.indexOf(item);
            if (index !== -1) {
                abp.event.trigger(this.config.divId + "-data-yAxis-remove", item);
                this.yAxiscolumns.splice(index, 1);

                this.xAxisActiveId = -1;
                this.activeId = -1;
                this.activeColumn = new DataWidgetConfigContent();
            }
        }

    }

    namehange() {
        let jstring = JSON.stringify(this.activeColumn);
        let jsonObj = JSON.parse(jstring);
        let newActiveColumn = jsonObj as DataWidgetConfigContent;
        abp.event.trigger(this.config.divId + "-data-change-name", newActiveColumn);
    }

    frequencyChange() {
        abp.event.trigger(this.config.divId + "-data-change-frequency", this.activeColumn);
    }

    aggregateChange() {
        abp.event.trigger(this.config.divId + "-data-change-aggregate", this.activeColumn);
    }

    unitChange() {
        abp.event.trigger(this.config.divId + "-data-change-unit", this.activeColumn);
    }

    typeChange(chartType) {
        this.chartType = chartType;
        abp.event.trigger(this.config.divId + "-data-change-type", this.chartType);
    }

    dataSpecsChange(e) {
        this.selectedPowerPlantUnit = _.uniq(this.selectedPowerPlantUnit);
        this.activeColumn.dataSpecs = this.selectedPowerPlantUnit;

        this.yAxiscolumns.find(x => x.id == this.activeColumn.id).dataSpecs = this.selectedPowerPlantUnit;
        abp.event.trigger(this.config.divId + "-data-change-data-spec", this.activeColumn);
    }

    getDataSpecs() {
        if (this.powerPlant) {
            this._powerPlantUnitAppService.getAllPowerPlantAndUnit().subscribe((response: PowerPlantAndUnitDto[]) => {
                this.powerPlantUnitList = response;
            });
        }
    }


    getMeasurementUnitBaseComponents(id, unit) {
        if (id != 0 && id) {
            this._baseComponentsServiceProxy.getMeasurementUnitBaseComponents(id)
                .subscribe((result: MeasurementUnitBaseComponents[]) => {
                    this.measurementUnits = result;
                    this.activeColumn.unit = unit;
                });
        }
    }

    changeMultiScale(event) {
        this.multiScale = !this.multiScale;
        abp.event.trigger(this.config.divId + "-data-change-scale", this.multiScale);
    }

    changeLegend(event) {
        this.legendEnable = !this.legendEnable;
        if (!this.legendEnable) {
            this.legend = "None";
            this.selectedMonths = [];
            this.selectedYears = [];
            let selectedLegend = new SelectedLegend();
            selectedLegend.legend = this.legend;
            this.selectedLegend = selectedLegend;
            this.selectedLegend.selectedYears = [];
            this.selectedLegend.selectedMonths = [];
            abp.event.trigger(this.config.divId + "-data-legend", this.selectedLegend);
        }

        this.changeLegendByYaxis();
    }

    changeLegendByYaxis() {
        if (!this.legendEnable) {
            this.activeColumn.aggregate = "None";
            this.yAxiscolumns.forEach(activeColumn => {
                activeColumn.aggregate = "None";
                abp.event.trigger(this.config.divId + "-data-change-aggregate", activeColumn);
            });
        } else {
            this.activeColumn.aggregate = "Avg";
            this.yAxiscolumns.forEach(activeColumn => {
                activeColumn.aggregate = "Avg";
                abp.event.trigger(this.config.divId + "-data-change-aggregate", activeColumn);
            });
        }
    }

    legendChange() {
        let selectedLegend = new SelectedLegend();
        selectedLegend.legend = this.legend;

        this.selectedLegend = selectedLegend;
        if (this.legendEnable && this.legend == "Year") {
            this.selectedLegend.selectedYears = this.selectedYears;
            this.selectedLegend.selectedMonths = [];
            this.selectedMonths = [];
            abp.event.trigger(this.config.divId + "-data-legend", this.selectedLegend);
        }

        if (this.legendEnable && this.legend == "Month" && this.selectedMonths.length > 0) {
            let _selectedMonths = this.selectedMonths;
            this.selectedMonths = [];
            this.selectedYears.forEach(y => {
                let control = _selectedMonths.filter(x => !x.value.indexOf(y.value));
                if (control.length > 0) {
                    this.selectedMonths = this.selectedMonths.concat(control);
                }
            });
            this.selectedLegend.selectedYears = this.selectedYears;
            this.selectedLegend.selectedMonths = this.selectedMonths;
            abp.event.trigger(this.config.divId + "-data-legend", this.selectedLegend);
        }

        if (this.legendEnable && this.legend == "Model") {
            this.selectedLegend.selectedYears = [];
            this.selectedLegend.selectedMonths = [];
            this.selectedYears = [];
            this.selectedMonths = [];
            abp.event.trigger(this.config.divId + "-data-legend", this.selectedLegend);
        }
    }

    changedYear() {
        this.isSelectedYears = this.selectedYears.length > 0;
        if (!this.isSelectedYears)
            this.selectedMonths = [];

        this.months = [];

        if (this.legendEnable && this.legend == "Year") {
            this.legendChange();
        }

        if (this.legendEnable && this.legend == "Month" && this.selectedMonths.length > 0) {
            this.legendChange();
        }

        if (this.legendEnable && this.legend == "Model") {
            this.legendChange();
        }

        this.selectedYears.forEach(year => {
            this.months.push(
                { name: year.name + " January", value: year.value + ".1" },
                { name: year.name + " February", value: year.value + ".2" },
                { name: year.name + " March", value: year.value + ".3" },
                { name: year.name + " April", value: year.value + ".4" },
                { name: year.name + " May", value: year.value + ".5" },
                { name: year.name + " June", value: year.value + ".6" },
                { name: year.name + " July", value: year.value + ".7" },
                { name: year.name + " August", value: year.value + ".8" },
                { name: year.name + " September", value: year.value + ".9" },
                { name: year.name + " October", value: year.value + ".10" },
                { name: year.name + " November", value: year.value + ".11" },
                { name: year.name + " December", value: year.value + ".12" }
            );
        });


    }

    changedMonth() {
        if (this.legendEnable && this.legend == "Month" && this.isSelectedYears) {
            this.legendChange();
        }
    }
}
