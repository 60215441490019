import {Component, Input} from '@angular/core';
import * as ReportTrialsUtilities from "@app/main/report-trials/utilities";
import {EditWidgetConfigDto} from "@app/state-management/models/edit-widget-config";
import {PowerPlant} from "@shared/service-proxies/service-proxies";

@Component({
    selector: 'edit-data-source',
    templateUrl: './edit-datasource.component.html',
    styleUrls: ['./edit-datasource.component.css']
})
export class EditDatasourceComponent {
    @Input() powerPlant: PowerPlant;
    searchvalue = '';
    config: EditWidgetConfigDto;

    public ShowOverlay(overlayName, animationName) {
        ReportTrialsUtilities.ShowOverlay(overlayName, animationName);
    };
}
