import { PowerPlantDto } from "@shared/service-proxies/service-proxies";

export enum HydroUnitStatus {
    InProduction = 0,
    Standstill = 1,
    Warning = 2,
    Maintenance = 3,
    OutOfCommunication = 4,
}

export enum SolarUnitStatus {
    InProduction = 0,
    Warning = 1,
    Standstill = 2,
}

export interface AssetDetail {
    name: string;
    region: string;
    country: string;
    company: string;
    mainStatus: string;
    powerPlantType: number;
    isPowerPlant: boolean;
    powerPlantId: number;
    unitId: number;
    location: [number, number];
    statusText: string;
    unitModelId?: number;
    type?: string;
    powerPlantDto?: PowerPlantDto;
    statusIconClassName?: string;
    solarInverterId?: number;
}
