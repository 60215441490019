import { Component, Input } from "@angular/core";

@Component({
    selector: "app-circle",
    templateUrl: "./circle-progress.component.html",
    styleUrls: ["./circle-progress.component.scss"]
})
export class CircleProgressComponent {
    @Input() percentage: number;
    @Input() color: string;

    get circleClass(): string {
        return `pie-wrapper progress-${this.color}-${this.percentage} style-2` ;
    }
}
