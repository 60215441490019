import { SubHeader } from "../models/sub-header";
import {GetFullScreenAction} from '@app/state-management/actions/sub-header.action'
import {Action, createReducer, on} from "@node_modules/@ngrx/store";

export interface ISubHeaderState {
    data: SubHeader;
}

// Initial State
export const initialState: ISubHeaderState = {
    data: new SubHeader
}

// Reducer
export const SubHeaderReducer = createReducer(initialState,
    on(GetFullScreenAction, (state, data) => {
        const newState: ISubHeaderState = {
            data: data
        }
        return newState;
    })
);

export function reducer(state: ISubHeaderState | undefined, action: Action) {
    return SubHeaderReducer(state, action);
}

