<div [@routerTransition] class="d-flex flex-column flex-root">
    <div class="page d-flex flex-row flex-column-fluid">
        <div
            id="kt_aside"
            class="aside pb-5 pt-5 pt-lg-0"
            data-kt-drawer="true"
            data-kt-drawer-name="aside"
            data-kt-drawer-activate="{default: true, lg: false}"
            data-kt-drawer-overlay="true"
            data-kt-drawer-width="{default:'80px', '300px': '100px'}"
            data-kt-drawer-direction="start"
            data-kt-drawer-toggle="#kt_aside_mobile_toggle"
        >
            <div class="aside-logo py-8" id="kt_aside_logo">
                <theme6-brand></theme6-brand>
            </div>
            <div class="aside-menu flex-column-fluid" id="kt_aside_menu"></div>
        </div>
        <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
            <div id="kt_header" class="header align-items-stretch">
                <div class="container-fluid d-flex align-items-stretch justify-content-between">
                    <div class="d-flex align-items-center d-lg-none ms-n3 me-1" title="Show aside menu">
                        <div
                            class="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
                            id="kt_header_menu_mobile_toggle"
                        >
                            <span class="svg-icon svg-icon-2x mt-1">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                        d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                        fill="black"
                                    ></path>
                                    <path
                                        opacity="0.3"
                                        d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                        fill="black"
                                    ></path>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                        <theme6-brand
                            [anchorClass]="'d-lg-none'"
                            [skin]="currentTheme.baseSettings.layout.darkMode ? 'dark' : 'light'"
                        ></theme6-brand>
                    </div>
                    <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
                        <div class="d-flex align-items-stretch" id="kt_header_nav">
                            <div
                                class="header-menu align-items-stretch"
                                data-kt-drawer="true"
                                data-kt-drawer-name="header-menu"
                                data-kt-drawer-activate="{default: true, lg: false}"
                                data-kt-drawer-overlay="true"
                                data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                                data-kt-drawer-direction="start"
                                data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
                                data-kt-swapper="true"
                                data-kt-swapper-mode="prepend"
                                data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
                            >
                                <top-bar-menu></top-bar-menu>
                            </div>
                        </div>

                        <div class="d-flex align-items-stretch flex-shrink-0">
                            <active-delegated-users-combo
                                [customStyle]="'btn btn-hover-text-primary btn-lg mb-1'"
                            ></active-delegated-users-combo>
                            <subscription-notification-bar
                                [customStyle]="
                                    'btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px position-relative me-2'
                                "
                            ></subscription-notification-bar>
                            <quick-theme-selection
                                [customStyle]="
                                    'btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px me-2'
                                "
                            ></quick-theme-selection>
                            <language-switch-dropdown
                                [customStyle]="
                                    'btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px me-2'
                                "
                                [isDropup]="true"
                            ></language-switch-dropdown>
                            <header-notifications
                                [customStyle]="
                                    'btn position-relative btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px me-2'
                                "
                            ></header-notifications>
                            <chat-toggle-button
                                [customStyle]="
                                    'btn position-relative btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px me-2'
                                "
                            ></chat-toggle-button>
                            
                            <toggle-dark-mode
                                [customStyle]="
                                    'btn position-relative btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px me-2'
                                "
                                [isDarkModeActive]="isDarkModeActive()"
                            ></toggle-dark-mode>

                            <user-menu
                                class="topbar-item"
                                [iconOnly]="false"
                                [togglerCssClass]="'btn btn-active-light-primary w-auto me-2 py-1 h-40px'"
                            ></user-menu>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column flex-column-fluid" id="kt_content">
                <div id="kt_content_container">
                    <router-outlet></router-outlet>
                </div>
                <!--end::Container-->
            </div>
            <!--end::Content-->
            <!--begin::Footer-->
            <footer-bar></footer-bar>
            <!--end::Footer-->
        </div>
        <!--end::Wrapper-->
    </div>
    <!--end::Page-->
</div>
