<div class="date-range-picker">
    <div class="text-field">
        <div class="input" bsDaterangepicker dateRangePickerLuxonModifier [(bsValue)]="dateRange"
             placement="bottom" #startDate [isDisabled]="isDisabled"
             (bsValueChange)="onDateRangeChange($event)">
            <div class="date-range-picker-container">
                <div class="label-focus label-3 publicsans-normal-manatee-12px">
                    Start Date
                </div>
                <div class="content">
                    <div class="value publicsans-normal-charade-16px">
                        {{ startDateLabel }}
                    </div>
                </div>
            </div>
            <div class="end-adornment">
                <div class="icon-button-1 icon-button-3">
                    <div class="ic_tabler_calendar-due ic_tabler_calendar"></div>
                </div>
            </div>
            <div class="space-m"></div>
        </div>
    </div>
    <div class="text-field">
        <div class="input" bsDaterangepicker dateRangePickerLuxonModifier
             placement="bottom" [(bsValue)]="dateRange" #endDate [isDisabled]="isDisabled"
             (bsValueChange)="onDateRangeChange($event)">
            <div class="date-range-picker-container">
                <div class="label-focus label-3 publicsans-normal-manatee-12px">
                    End Date
                </div>
                <div class="content">
                    <div class="value publicsans-normal-charade-16px">
                        {{ endDateLabel }}
                    </div>
                </div>
            </div>
            <div class="end-adornment">
                <div class="icon-button-1 icon-button-3">
                    <div class="ic_tabler_calendar-due ic_tabler_calendar"></div>
                </div>
            </div>
            <div class="space-m"></div>
        </div>
    </div>
</div>
