import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

    transform(value: string, limit: number): string {
        if (!value || !limit) {
            return value;
        }

        const words = value.split(' ');

        if (value.length > limit) {
            if (words[0].length > limit) {
                return words[0].substring(0, limit) + '...';
            }

            let truncatedText = '';
            for (let word of words) {
                if ((truncatedText + word).length > limit) {
                    break;
                }
                truncatedText += (truncatedText ? ' ' : '') + word;
            }

            return truncatedText + '...';
        }

        return value;
    }
}
