import { PowerPlantType, WindUnitStatus } from "@shared/service-proxies/service-proxies";
import { HydroUnitStatus, SolarUnitStatus } from "@app/main/map/map.model";

export const MapConstants = {
    TenantNames: {
        Lodos: "lodos",
        Ere: "ere",
    },

    HTMLPowerPlantUnit: {
        UnitId: "power-plant-unit-id-",
        UnitMarkerColor: "plant-unit-marker-color-",
        UnitMarkerIcon: "plant-unit-marker-icon-",
        UnitMarkerStatusContext: "plant-unit-marker-status-context-",
        UnitMarkerStatusDataGroup: "plant-unit-marker-group-",
        UnitMarkerStatusText: "plant-unit-marker-status-text-",
        UnitMarkerPowerTag: "plant-unit-marker-power-tag-",
        UnitMarkerWindSpeedTag: "plant-unit-marker-wind-speed-tag-",
        TurbineId: "turbine-id",
        PowerPlantId: "power-plant-id",
    },

    HTMLPowerPlantMarker: {
        ppMarkerInProductionCount: "-ppMarkerInProductionCount",
        ppMarkerWarningCount: "-ppMarkerWarningCount",
        ppMarkerMaintenanceCount: "-ppMarkerMaintenanceCount",
        ppMarkerOutOffComCount: "-ppMarkerOutOffComCount",
        ppMarkerLackOffWindCount: "-ppMarkerLackOffWindCount",
        ppMarkerStandStillCount: "-ppMarkerStandStillCount",
        ppMarkerCurtailmentCount: "-ppMarkerCurtailmentCount",
    },
    HTMLPowerPlantMarkerStatusStack: {
        ppMarkerInProductionStack: "-ppMarkerInProductionStack",
        ppMarkerWarningStack: "-ppMarkerWarningStack",
        ppMarkerMaintenanceStack: "-ppMarkerMaintenanceStack",
        ppMarkerOutOffComStack: "-ppMarkerOutOffComStack",
        ppMarkerLackOffWindStack: "-ppMarkerLackOffWindStack",
        ppMarkerStandStillStack: "-ppMarkerStandStillStack",
        ppMarkerCurtailmentStack: "-ppMarkerCurtailmentStack",
    },

    LocalStorageKeys: {
        TenancyName: "tenancyName",
        PowerPlantList: "powerPlantList",
        StatusCodesResponse: "statusCodesResponse",
        PowerPlantUnitList: "powerPlantUnitList",
        ComponentList: "componentList",
        AllWindUnitStatus: "allWindUnitStatus",
        PowerPlantsForPage: "powerPlantsForPage",
        RealtimeIotData: "realtimeIotData",
        SolarInverterList: "solarInverterList",
    },
};

export const UnitStatusText = {
    [PowerPlantType.Wind]: {
        [WindUnitStatus.InProduction]: "In Production",
        [WindUnitStatus.LackOfWind]: "Lack Of Wind",
        [WindUnitStatus.Maintenance]: "Maintenance",
        [WindUnitStatus.Warning]: "Warning",
        [WindUnitStatus.Curtailment]: "Curtailment",
        [WindUnitStatus.OutOfCommunication]: "Out Of Communication",
    },
    [PowerPlantType.Hydro]: {
        [HydroUnitStatus.InProduction]: "In Production",
        [HydroUnitStatus.Standstill]: "Standstill",
        [HydroUnitStatus.Maintenance]: "Maintenance",
        [HydroUnitStatus.Warning]: "Warning",
        [HydroUnitStatus.OutOfCommunication]: "Out Of Communication",
    },
    [PowerPlantType.Solar]: {
        [SolarUnitStatus.InProduction]: "In Production",
        [SolarUnitStatus.Standstill]: "Standstill",
        [SolarUnitStatus.Warning]: "Warning",
    },
};

export const UnitStatusData = {
    [PowerPlantType.Wind]: {
        [WindUnitStatus.InProduction]: "#36B37E",
        [WindUnitStatus.LackOfWind]: "#00B8D9",
        [WindUnitStatus.Maintenance]: "#FFAB00",
        [WindUnitStatus.Warning]: "#FF5630",
        [WindUnitStatus.Curtailment]: "#36F",
        [WindUnitStatus.OutOfCommunication]: "#637381",
    },
    [PowerPlantType.Hydro]: {
        [HydroUnitStatus.InProduction]: "#36B37E",
        [HydroUnitStatus.Standstill]: "#00B8D9",
        [HydroUnitStatus.Maintenance]: "#FFAB00",
        [HydroUnitStatus.Warning]: "#FF5630",
        [HydroUnitStatus.OutOfCommunication]: "#637381",
    },
    [PowerPlantType.Solar]: {
        [SolarUnitStatus.InProduction]: "#36B37E",
        [SolarUnitStatus.Standstill]: "#00B8D9",
        [SolarUnitStatus.Warning]: "#FF5630",
    },
};

export const UnitStatusClasses = {
    [PowerPlantType.Wind]: {
        [WindUnitStatus.InProduction]: "fab-contained",
        [WindUnitStatus.LackOfWind]: "fab-contained-2 fab-contained-6",
        [WindUnitStatus.Maintenance]: "fab-contained-3 fab-contained-6",
        [WindUnitStatus.Warning]: "fab-contained-1 fab-contained-6",
        [WindUnitStatus.Curtailment]: "fab-contained-4 fab-contained-6",
        [WindUnitStatus.OutOfCommunication]: "#637381",
    },
    [PowerPlantType.Hydro]: {
        [HydroUnitStatus.InProduction]: "fab-contained",
        [HydroUnitStatus.Standstill]: "fab-contained-2 fab-contained-6",
        [HydroUnitStatus.Maintenance]: "fab-contained-3 fab-contained-6",
        [HydroUnitStatus.Warning]: "fab-contained-1 fab-contained-6",
        [HydroUnitStatus.OutOfCommunication]: "fab-contained-4 fab-contained-6",
    },
    [PowerPlantType.Solar]: {
        [SolarUnitStatus.InProduction]: "fab-contained",
        [SolarUnitStatus.Standstill]: "fab-contained-2 fab-contained-6",
        [SolarUnitStatus.Warning]: "fab-contained-1 fab-contained-6",
    },
};

export const UnitStatusTextClasses = {
    [PowerPlantType.Wind]: {
        [WindUnitStatus.InProduction]: "in-production-text",
        [WindUnitStatus.LackOfWind]: "lack-of-wind-text",
        [WindUnitStatus.Maintenance]: "maintenance-text",
        [WindUnitStatus.Warning]: "warnig-text",
        [WindUnitStatus.Curtailment]: "curtailment-text",
        [WindUnitStatus.OutOfCommunication]: "curtailment-text",
    },
    [PowerPlantType.Hydro]: {
        [HydroUnitStatus.InProduction]: "in-production-text",
        [HydroUnitStatus.Standstill]: "lack-of-wind-text",
        [HydroUnitStatus.Maintenance]: "maintenance-text",
        [HydroUnitStatus.Warning]: "warnig-text",
        [HydroUnitStatus.OutOfCommunication]: "curtailment-text",
    },
    [PowerPlantType.Solar]: {
        [SolarUnitStatus.InProduction]: "in-production-text",
        [SolarUnitStatus.Standstill]: "lack-of-wind-text",
        [SolarUnitStatus.Warning]: "warnig-text",
    },
};

export const UnitStatusTextContentClasses = {
    [PowerPlantType.Wind]: {
        [WindUnitStatus.InProduction]: "in-production-content",
        [WindUnitStatus.LackOfWind]: "lack-of-wind-content",
        [WindUnitStatus.Maintenance]: "maintenance-content",
        [WindUnitStatus.Warning]: "warning-content",
        [WindUnitStatus.Curtailment]: "curtailment-content",
        [WindUnitStatus.OutOfCommunication]: "curtailment-content",
    },
    [PowerPlantType.Hydro]: {
        [HydroUnitStatus.InProduction]: "in-production-content",
        [HydroUnitStatus.Standstill]: "lack-of-wind-content",
        [HydroUnitStatus.Maintenance]: "maintenance-content",
        [HydroUnitStatus.Warning]: "warning-content",
        [HydroUnitStatus.OutOfCommunication]: "curtailment-content",
    },
    [PowerPlantType.Solar]: {
        [SolarUnitStatus.InProduction]: "in-production-content",
        [SolarUnitStatus.Standstill]: "lack-of-wind-content",
        [SolarUnitStatus.Warning]: "warning-content",
    },
};

export const UnitNotificationClasses = {
    [PowerPlantType.Wind]: {
        [WindUnitStatus.InProduction]: "x-11",
        [WindUnitStatus.LackOfWind]: "x-6",
        [WindUnitStatus.Maintenance]: "x-10",
        [WindUnitStatus.Warning]: "x-4",
        [WindUnitStatus.Curtailment]: "x-12",
        [WindUnitStatus.OutOfCommunication]: "x-8",
    },
    [PowerPlantType.Hydro]: {
        [HydroUnitStatus.InProduction]: "x-11",
        [HydroUnitStatus.Standstill]: "x-6",
        [HydroUnitStatus.Maintenance]: "x-10",
        [HydroUnitStatus.Warning]: "x-4",
        [HydroUnitStatus.OutOfCommunication]: "x-8",
    },
    [PowerPlantType.Solar]: {
        [SolarUnitStatus.InProduction]: "x-11",
        [SolarUnitStatus.Standstill]: "x-6",
        [SolarUnitStatus.Warning]: "x-4",
    },
};


export const UnitStatusCount = {
    [PowerPlantType.Wind]: {
        [WindUnitStatus.InProduction]: "-ppMarkerInProductionCount",
        [WindUnitStatus.LackOfWind]: "-ppMarkerLackOffWindCount",
        [WindUnitStatus.Maintenance]: "-ppMarkerMaintenanceCount",
        [WindUnitStatus.Warning]: "-ppMarkerWarningCount",
        [WindUnitStatus.Curtailment]: "-ppMarkerCurtailmentCount",
        [WindUnitStatus.OutOfCommunication]: "-ppMarkerOutOffComCount",
    },
    [PowerPlantType.Hydro]: {
        [HydroUnitStatus.InProduction]: "-ppMarkerInProductionCount",
        [HydroUnitStatus.Standstill]: "-ppMarkerStandStillCount",
        [HydroUnitStatus.Maintenance]: "-ppMarkerMaintenanceCount",
        [HydroUnitStatus.Warning]: "-ppMarkerWarningCount",
        [HydroUnitStatus.OutOfCommunication]: "-ppMarkerOutOffComCount",
    },
    [PowerPlantType.Solar]: {
        [SolarUnitStatus.InProduction]: "-ppMarkerInProductionCount",
        [SolarUnitStatus.Standstill]: "-ppMarkerStandStillCount",
        [SolarUnitStatus.Warning]: "-ppMarkerWarningCount",
    },
};

export const UnitStatusCountStack = {
    [PowerPlantType.Wind]: {
        [WindUnitStatus.InProduction]: "-ppMarkerInProductionStack",
        [WindUnitStatus.LackOfWind]: "-ppMarkerLackOffWindStack",
        [WindUnitStatus.Maintenance]: "-ppMarkerMaintenanceStack",
        [WindUnitStatus.Warning]: "-ppMarkerWarningStack",
        [WindUnitStatus.Curtailment]: "-ppMarkerCurtailmentStack",
        [WindUnitStatus.OutOfCommunication]: "-ppMarkerOutOffComStack",
    },
    [PowerPlantType.Hydro]: {
        [HydroUnitStatus.InProduction]: "-ppMarkerInProductionStack",
        [HydroUnitStatus.Standstill]: "-ppMarkerStandStillStack",
        [HydroUnitStatus.Maintenance]: "-ppMarkerMaintenanceStack",
        [HydroUnitStatus.Warning]: "-ppMarkerWarningStack",
        [HydroUnitStatus.OutOfCommunication]: "-ppMarkerOutOffComStack",
    },
    [PowerPlantType.Solar]: {
        [SolarUnitStatus.InProduction]: "-ppMarkerInProductionStack",
        [SolarUnitStatus.Standstill]: "-ppMarkerStandStillStack",
        [SolarUnitStatus.Warning]: "-ppMarkerWarningStack",
    },
};
