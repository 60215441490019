<div bsModal #infoModal="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" aria-labelledby="infoModal" aria-hidden="true" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xs">
        <div class="modal-content">
            <div class="modal-header">
                <div class="d-flex align-items-center justify-content-between w-100">
                    <div class="d-flex align-items-center gap-2">
                        <img *ngIf="modalDto.headerIconSrc" [src]="modalDto.headerIconSrc" class="icon header-icon mr-2" alt="icon"/>
                        <span [class]="modalDto.headerTitleClass">{{modalDto.headerTitle}}</span>
                    </div>
                    <img (click)="close()" src="/assets/dashboard/icons-ic-close.svg" class="icon close-icon right" alt="icon"/>
                </div>
            </div>
            <div class="modal-body">
                <div [innerHTML]="modalDto.contentHtml"></div>
            </div>
            <div class="modal-footer py-3">
                <div class="d-flex align-items-center w-100" [class]="modalDto.isLinkTextVisible ? ' justify-content-between' : ' justify-content-end'">
                    <a *ngIf="modalDto.isLinkTextVisible" (click)="clickLinkText()" href="javascript:">
                        <span class="fw-boldest text-decoration-underline text-dark">
                            {{ modalDto.linkText }}
                        </span>
                    </a>
                    <div class="buttons-container d-flex align-items-center justify-content-end gap-2">
                        <button *ngIf="modalDto.isCancelButtonVisible" (click)="close()" class="btn btn-sm btn-outline-dark border border-dark">{{l("Cancel")}}</button>
                        <button (click)="clickConfirm()" [class]="modalDto.buttonClass">
                            <div class="d-flex align-items-center justify-content-center gap-2">
                                <img *ngIf="modalDto.buttonIconSrc" [src]="modalDto.buttonIconSrc" alt="icon" class="icon">
                                <span [class]="modalDto.buttonTextClass">{{modalDto.buttonText}}</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
