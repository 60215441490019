import { Component, HostListener, Injector, OnInit, ViewChild } from "@angular/core";
import { AppConsts } from "@shared/AppConsts";
import { UrlHelper } from "@shared/helpers/UrlHelper";
import { DashboardMode, ReportDashboardServiceProxy, SubscriptionStartType } from "@shared/service-proxies/service-proxies";
import { AppComponentBase } from "shared/common/app-component-base";
import { SignalRHelper } from "shared/helpers/SignalRHelper";
import { LinkedAccountsModalComponent } from "@app/shared/layout/linked-accounts-modal.component";
import { UserDelegationsModalComponent } from "@app/shared/layout/user-delegations-modal.component";
import { ChangePasswordModalComponent } from "@app/shared/layout/profile/change-password-modal.component";
import { ChangeProfilePictureModalComponent } from "@app/shared/layout/profile/change-profile-picture-modal.component";
import { MySettingsModalComponent } from "@app/shared/layout/profile/my-settings-modal.component";
import { NotificationSettingsModalComponent } from "@app/shared/layout/notifications/notification-settings-modal.component";
import { UserNotificationHelper } from "@app/shared/layout/notifications/UserNotificationHelper";
import { DateTimeService } from "./shared/common/timing/date-time.service";
import { NotificationDetailModalComponent } from "./shared/layout/notifications/detail-modal/notification-detail-modal.component";

import { ToggleComponent, ScrollTopComponent, DrawerComponent, StickyComponent, MenuComponent, ScrollComponent } from "@metronic/app/kt/components";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";

@Component({
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.less"],
})
export class AppComponent extends AppComponentBase implements OnInit {
    @HostListener("drop", ["$event"]) onDrop(event: any) {
        console.log("onFileDropped", event);
        event.preventDefault();
        event.stopPropagation();
    }

    @HostListener("dragover", ["$event"]) onDragOver(event: any) {
        event.preventDefault();
    }

    @ViewChild("linkedAccountsModal") linkedAccountsModal: LinkedAccountsModalComponent;
    @ViewChild("userDelegationsModal", { static: true }) userDelegationsModal: UserDelegationsModalComponent;
    @ViewChild("changePasswordModal", { static: true }) changePasswordModal: ChangePasswordModalComponent;
    @ViewChild("changeProfilePictureModal", { static: true })
    changeProfilePictureModal: ChangeProfilePictureModalComponent;
    @ViewChild("mySettingsModal", { static: true }) mySettingsModal: MySettingsModalComponent;
    @ViewChild("notificationSettingsModal", { static: true })
    notificationSettingsModal: NotificationSettingsModalComponent;
    @ViewChild("chatBarComponent") chatBarComponent;
    @ViewChild("notificationDetailModal", { static: true })
    notificationDetailModal: NotificationDetailModalComponent;

    subscriptionStartType = SubscriptionStartType;
    theme: string;
    installationMode = true;
    isQuickThemeSelectEnabled: boolean = this.setting.getBoolean("App.UserManagement.IsQuickThemeSelectEnabled");
    IsSessionTimeOutEnabled: boolean = this.setting.getBoolean("App.UserManagement.SessionTimeOut.IsEnabled") && this.appSession.userId != null;
    private previousUrl: string;

    public constructor(
        injector: Injector,
        private router: Router,
        private _reportDashboardServiceProxy: ReportDashboardServiceProxy,
        private _userNotificationHelper: UserNotificationHelper,
        private _dateTimeService: DateTimeService
    ) {
        super(injector);
        router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            console.log("prev:", event.url);
            if (event.url == "/") {
                this.firstRoute();
            }
        });
    }

    ngOnInit(): void {
        this._userNotificationHelper.settingsModal = this.notificationSettingsModal;
        this._userNotificationHelper.detailModal = this.notificationDetailModal;

        this.theme = abp.setting.get("App.UiManagement.Theme").toLocaleLowerCase();
        this.installationMode = UrlHelper.isInstallUrl(location.href);

        this.registerModalOpenEvents();

        if (this.appSession.application) {
            SignalRHelper.initSignalR(() => {
            });
        }

        this.pluginsInitialization();
    }

    pluginsInitialized(): boolean {
        let menuItems = document.querySelectorAll('[data-kt-menu="true"]');
        for (let i = 0; i < menuItems.length; i++) {
            let el = menuItems[i];
            const menuItem = el as HTMLElement;
            let menuInstance = MenuComponent.getInstance(menuItem);
            if (menuInstance) {
                return true;
            }
        }

        return false;
    }

    pluginsInitialization() {
        setTimeout(() => {
            if (this.pluginsInitialized()) {
                return;
            }

            ToggleComponent.bootstrap();
            ScrollTopComponent.bootstrap();
            DrawerComponent.bootstrap();
            StickyComponent.bootstrap();
            MenuComponent.bootstrap();
            ScrollComponent.bootstrap();
        }, 200);
    }

    subscriptionStatusBarVisible(): boolean {
        return this.appSession.tenantId > 0 && (this.appSession.tenant.isInTrialPeriod || this.subscriptionIsExpiringSoon());
    }

    subscriptionIsExpiringSoon(): boolean {
        if (this.appSession.tenant?.subscriptionEndDateUtc) {
            let today = this._dateTimeService.getUTCDate();
            let daysFromNow = this._dateTimeService.plusDays(today, AppConsts.subscriptionExpireNootifyDayCount);
            return daysFromNow >= this.appSession.tenant.subscriptionEndDateUtc;
        }

        return false;
    }

    registerModalOpenEvents(): void {
        this.subscribeToEvent("app.show.linkedAccountsModal", () => {
            this.linkedAccountsModal.show();
        });

        this.subscribeToEvent("app.show.userDelegationsModal", () => {
            this.userDelegationsModal.show();
        });

        this.subscribeToEvent("app.show.changePasswordModal", () => {
            this.changePasswordModal.show();
        });

        this.subscribeToEvent("app.show.changeProfilePictureModal", () => {
            this.changeProfilePictureModal.show();
        });

        this.subscribeToEvent("app.show.mySettingsModal", () => {
            this.mySettingsModal.show();
        });
    }

    getRecentlyLinkedUsers(): void {
        abp.event.trigger("app.getRecentlyLinkedUsers");
    }

    onMySettingsModalSaved(): void {
        abp.event.trigger("app.onMySettingsModalSaved");
    }

    private firstRoute() {
        this._reportDashboardServiceProxy.getReportDashboardHomePage().subscribe((result) => {
            let route = result.id === 0 ? "/app/main/map" : result.mode === DashboardMode.Aipact ? `app/main/deployment-results` : `/app/main/dashboard/${result.reportDashboardId}`;

            this.router.navigate([route]);
        });
    }
}
