<div class="frame-43 frame">
    <div class="stack">
        <div class="col-md-3">
            <div class="user-account-update-user-form-1">
                <div class="specification publicsans-bold-charade-12px">INFO</div>
                <div class="stack-1 stack-3">
                    <div class="text-field">
                        <div class="input">
                            <div class="container-1">
                                <div class="label-focus label-4 publicsans-normal-manatee-12px">Widget Name</div>
                                <div class="content">
                                    <input class="name1 publicsans-normal-charade-16px input-form" #Name="ngModel"
                                           id="Name" [(ngModel)]="name" (ngModelChange)="modelChange()"
                                    >
                                </div>
                            </div>
                            <div class="space-m"></div>
                        </div>
                    </div>
                </div>
                <div class="textarea">
                    <div class="input">
                        <div class="container-1">
                            <div class="label-focus label-4 publicsans-normal-manatee-12px">Description</div>
                            <div class="content">
                            <textarea rows="3" class="value publicsans-normal-charade-16px input-form"
                                      [(ngModel)]="description" (ngModelChange)="modelChange()">
                            </textarea>

                            </div>
                        </div>
                        <div class="space-m"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3" *ngIf="this.isActiveForSelectingBaseComponents">
            <div class="user-account-update-user-form-1">
                <div class="specification publicsans-bold-charade-12px">Temperature Base Component</div>
                <div class="stack-1 stack-3">
                    <div class="form-floating w-100">
                        <select [(ngModel)]="selectedBaseComponentIdId" (ngModelChange)="onBaseComponentChange($event)"
                                class="form-select" id="modelSelect" aria-label="Please select a model">
                            <option *ngFor="let model of temperatureBaseComponents"
                                    [value]="model.id">{{model.name}}</option>
                        </select>
                        <label for="modelSelect">Please select a base component</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-3" *ngIf="this.isFilterErrorCode && this.isFilterTopRecords">
            <div class="user-account-update-user-form-1">
                <div class="specification publicsans-bold-charade-12px">Filter Downtimes</div>
                <div class="stack-1 stack-3">
                    <div class="form-floating w-100">
                        <select [(ngModel)]="selectedTopRecordFilter" (ngModelChange)="onselectedTopRecordFilter($event)"
                                class="form-select" aria-label="Please select a top records">
                            <option *ngFor="let model of filterTopRecordFilters"
                                    [value]="model">{{model}}</option>
                        </select>
                        <label for="modelSelect">Please select a top records</label>
                    </div>
                </div>

                <div class="stack-1 stack-3">
                    <div class="form-floating w-100">
                        <select [(ngModel)]="selectedMainStatus" (ngModelChange)="onSelectedMainStatus($event)"
                                class="form-select" aria-label="Please select a error code">
                            <option *ngFor="let item of downtimeStatusCodeResponse"
                                    [value]="item.mainStatus">{{item.mainStatus}} {{item.mainStatusText}}</option>
                        </select>
                        <label for="modelSelect">Please select a error code</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
