import { Component, Input } from '@angular/core';
import { PowerPlantDto } from "@shared/service-proxies/service-proxies";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
    selector: 'app-power-plant-selection',
    templateUrl: './power-plant-selection.component.html',
    styleUrls: ['./power-plant-selection.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: PowerPlantSelectionComponent,
            multi: true
        },
    ]
})
export class PowerPlantSelectionComponent implements ControlValueAccessor {
    @Input() activated = true;
    @Input() powerPlants: PowerPlantDto[] = [];

    value: number;
    isDisabled: boolean;

    private onChange: any;
    private onTouched: any;


    getAssetButtonClassName(defaultClass: string): string {
        return this.activated ? "button-active" : defaultClass;
    }

    getAssetLabelClassName(defaultClass: string) {
        return this.activated ? "label-active" : defaultClass;
    }

    getAssetSrc(defaultUrl: string) {
        return this.activated ? "assets/common/images/report/widget/end-icon-1.svg" : defaultUrl;
    }

    getAssetIcon(startIcon: string) {
        return this.activated ? startIcon + "-active" : startIcon;
    }

    onPowerPlantSelect(event: Event) {
        this.onTouched();
        this.onChange(+(event.target as HTMLSelectElement).value);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    writeValue(value: any): void {
        this.value = value;
    }
}
