export const StringValuesConst = {
    STATUS: "status",
    PRODUCTION: "production",
    REAL_TIME: "realTime",
    DAILY: "daily",
    MINIMUM_RADIATION_LEVEL: 100,
    STAND_STILL_COLOR: "#00B8D9",
    REALTIME_CELL_COLOR: [
        "#FF0000", // Kırmızı
        "#FF5733", // Turuncu
        "#FFC300", // Sarı
        "#9ACD32", // Limon Yeşili
        "#32CD32", // Parlak Yeşil
    ],
};
