<div id="overlay-bottom-drawer-general" class="overlay-base">
    <div class="bottom-drawer-general screen">
        <div class="bottom-drawer-general-1">
            <div class="card-header">
                <div class="user-account-tabs">
                    <article *ngFor="let tab of tabs; " (click)="setActiveTab(tab.id)"
                             [class]="tabIndex==tab.id ? 'tab':'user-account-tabs-item'">
                        <div class="container">
                            <img class="icon" [src]="tab.icon" [alt]="tab.title">
                            <div class="label-1 label-4">{{ tab.title }}</div>
                        </div>
                        <div class="space"></div>
                    </article>
                </div>
                <div class="frame-45 frame">
                    <a (click)="this.Cancel('bottom-drawer-general', 'animate-appear');">
                        <div class="button">
                            <img class="icon" src="/assets/common/images/edit-widget/start-icon-2.svg" alt="start icon">
                            <div class="label-2 valign-text-middle label-4">Close</div>
                        </div>
                    </a>
                    <a class="button-1" (click)="this.HideOverlay('bottom-drawer-general', 'animate-appear');"
                       style="background-color: #0c8954 !important;">
                        <div class="label-3 valign-text-middle label-4">Ok</div>
                    </a>
                    <a class="button-1" *ngIf="!(buttonSavesActiveIds.indexOf(config?.widgetId)==-1)"
                       (click)="SaveWidget('bottom-drawer-general', 'animate-appear')">
                        <img class="icon" src="/assets/common/images/top-bar/img/save-dashboard-icon.svg"
                             alt="start icon">
                        <div class="label-3 valign-text-middle label-4">Save as Widget</div>
                    </a>
                </div>
            </div>

            <div>
                <div [ngSwitch]="tabIndex">
                    <edit-general *ngSwitchCase="0" class="tab-flex" #general [powerPlant]="powerPlant"></edit-general>
                    <edit-data-source *ngSwitchCase="1" class="tab-flex" #datasource
                                      [powerPlant]="powerPlant"></edit-data-source>
                    <edit-series *ngSwitchCase="2" class="tab-flex" #series [powerPlant]="powerPlant"></edit-series>
                    <edit-format *ngSwitchCase="3" class="tab-flex" #format [powerPlant]="powerPlant"></edit-format>
                    <edit-axes *ngSwitchCase="4" class="tab-flex" #axes [powerPlant]="powerPlant"></edit-axes>
                    <edit-data *ngSwitchCase="5" class="tab-flex" #axes [powerPlant]="powerPlant"></edit-data>
                    <edit-settings *ngSwitchCase="6" class="tab-flex" #axes [powerPlant]="powerPlant"></edit-settings>
                    <edit-unit-model *ngSwitchCase="7" class="tab-flex" #unitModel
                                     [powerPlant]="powerPlant"></edit-unit-model>

                </div>
            </div>


        </div>
    </div>
</div>
