import { AppComponentBase } from "@shared/common/app-component-base";
import { HubConnection } from "@node_modules/@microsoft/signalr";
import { Injectable, Injector } from "@angular/core";
import { LocalStorageService } from "@shared/utils/local-storage.service";
import { AppConsts } from "@shared/AppConsts";
import * as signalR from "@node_modules/@microsoft/signalr";
import { UnitStatusNotificationModelDto } from "@shared/service-proxies/service-proxies";

@Injectable()
export class UnitStatusNotificationSignalrService extends AppComponentBase {
    unitStatusNotificationHub: HubConnection;

    constructor(injector: Injector) {
        super(injector);
    }

    public connect() {
        new LocalStorageService().getItem(AppConsts.authorization.encrptedAuthTokenName, (err, value) => {
            let encryptedAuthToken = value?.token;
            const url = `${AppConsts.iotDataRemoteServiceWsUrl}?${AppConsts.authorization.encrptedAuthTokenName}=${encodeURIComponent(encryptedAuthToken)}`;
            this.unitStatusNotificationHub = new signalR.HubConnectionBuilder()
                .configureLogging(signalR.LogLevel.Information)
                .withAutomaticReconnect()
                .withUrl(url, {
                    skipNegotiation: true,
                    transport: signalR.HttpTransportType.WebSockets,
                })
                .build();
            this.unitStatusNotificationHub
                .start()
                .then(() => {
                    console.log("SignalR Status Connected!");
                })
                .catch((err) => {
                    console.log(err);
                });
            this.unitStatusNotificationHub.on(this.appSession.tenant.name + "-unit-status-notification", (unitStatusCount) => {
                let unitStatusNotifications = JSON.parse(unitStatusCount) as UnitStatusNotificationModelDto[];

                abp.event.trigger("changed-unit-status-notification", unitStatusNotifications);
            });
        });
    }

    public close() {
        this.unitStatusNotificationHub
            ?.stop()
            .then(() => {})
            .catch((err) => {
                console.log(err);
            });
    }
}
