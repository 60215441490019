import { Component, Input, OnInit } from "@angular/core";
import { ReportSection, ReportSectionType } from "@app/main/reports/report-overview/report-overview.model";
import { AppComponentBase } from "@shared/common/app-component-base";

@Component({
    selector: "app-report-table-of-content",
    templateUrl: "./table-of-content.component.html",
    styleUrls: ["./table-of-content.component.css"],
})
export class TableOfContentComponent extends AppComponentBase implements OnInit {
    @Input() reportSections: ReportSection[];
    sections: ReportSection[];
    pages: ReportSection[];

    ngOnInit(): void {
        this.reportSections = this.reportSections.filter((x) => x.components[0].type == ReportSectionType.Cover);
        this.pages = this.reportSections.filter((x) => x.isRoot && x.components[0].type !== "Table Of Contents");
        this.sections = this.reportSections.filter((x) => !x.isRoot);

        const isAnyUnSectionSelected = this.reportSections.some((x) => !x.isTOCSelected);

        if (!isAnyUnSectionSelected) {
            this.reportSections.forEach((x) => {
                x.isTOCSelected = true;
            });
        }
    }

    getChipsClassName(item: ReportSection) {
        if (item.isTOCSelected) {
            return "toc-selected";
        } else {
            return "toc-un-selected";
        }
    }

    getChipsIcon(item: ReportSection) {
        if (item.isTOCSelected) {
            return "assets/common/images/report/table-of-content/icons-ic-remove.svg";
        } else {
            return "assets/common/images/report/table-of-content/icons-ic-plus.svg";
        }
    }

    clickSectionChips(item: ReportSection) {
        const section = this.reportSections.find((x) => x.id === item.id);

        if (section) {
            section.isTOCSelected = !section.isTOCSelected;
        }
        abp.event.trigger("click-section-chips", this.reportSections);
    }

    clickPagesChips(item: ReportSection) {
        const section = this.reportSections.find((x) => x.id === item.id);

        if (section) {
            section.isTOCSelected = !section.isTOCSelected;
        }

        abp.event.trigger("click-section-chips", this.reportSections);
    }
}
