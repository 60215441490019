<div id="widget-action-popup" class="edit-menu edit-screen" style="display: none;">
    <div class="edit-menu-1 edit-menu-2">
        <article class="edit-menu-item edit-menu-2">
            <div class="edit-start-icon">
                <div class="edit-icon"><img class="edit-shape"
                                            src="../../../../assets/common/images/edit-widget/edit-menu/shape.svg"
                                            alt="shape"></div>
            </div>
            <div class="edit-content">
                <div class="edit-label publicsans-normal-charade-14px">Edit</div>
            </div>
        </article>
        <!--        <article class="edit-menu-item edit-menu-2">-->
        <!--            <div class="edit-start-icon"><img class="edit-icon" src="../../../../assets/common/images/edit-widget/edit-menu/icons-ic-archive-off.svg" alt="icons/ic_archive"></div>-->
        <!--            <div class="edit-content"><div class="edit-label publicsans-normal-charade-14px">Save as</div></div>-->
        <!--        </article>-->
        <article class="edit-menu-item edit-menu-2">
            <div class="edit-start-icon"><img class="edit-icon"
                                              src="../../../../assets/common/images/edit-widget/edit-menu/icons-ic-trash.svg"
                                              alt="icon-trash"></div>
            <div class="edit-content">
                <div id="remove-widget" class="edit-label publicsans-normal-charade-14px">Delete</div>
            </div>
        </article>
    </div>
</div>
