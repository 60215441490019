import {PowerPlantType, WindUnitStatus} from "@shared/service-proxies/service-proxies";

export interface DataGridData {
    headers: DataGridHeader[];
    rows: DataGridRowData[];
}

export interface DataGridHeader {
    name: string;
    sorting: boolean;
    sortable: boolean;
    isNumericSort?: boolean;
    visible: boolean;
}

export interface DataGridRowData {
    columns: DataGridColumnData[];
}

export interface DataGridColumnData {
    type: DataGridColumnType;
    value?: any;
    precisionSize?: number;
    imageUrl?: string;
    badgeStatus?: "green" | "red";
    additionalText?: string;
    progressPercentage?: number;
    progressColor?: string;
    progressBackgroundColor?: string;
    visible: boolean,
    nominalPower?: number;
    mainStatus?: string;
    onePactResponse?: WindUnitStatus;
    StatusText?: string;
    powerPlantType?: PowerPlantType;

    modelId?: number;
    name?: string;
}

export enum DataGridColumnType {
    Text,
    Number,
    Progress,
    AvatarAndName
}
