<div class="error404 screen">
    <div class="container">
        <div class="head">
            <h1 class="sorry-page-not-foun">Sorry, page not found!</h1>
            <p class="sorry-we-couldnt-f">
                Sorry, we couldn’t find the page you’re looking for. <br />Perhaps a mistyped the URL? Be sure to check the
                spelling.
            </p>
        </div>
        <div class="x404-error-bro-1"></div>
        <div class="button"><div class="label" (click)="goToHome()">Go to Home</div></div>
    </div>
    <div class="logo"><img class="unknown-3" src="/assets/common/images/onepact_logo_light-1.png" alt="OnePact" /></div>
</div>
