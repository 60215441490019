import { Injector, Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { ThemesLayoutBaseComponent } from '@app/shared/layout/themes/themes-layout-base.component';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { AppConsts } from '@shared/AppConsts';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SubHeader } from '@app/state-management/models/sub-header';
@Component({
    templateUrl: './default-layout.component.html',
    selector: 'default-layout',
    animations: [appModuleAnimation()],
})
export class DefaultLayoutComponent extends ThemesLayoutBaseComponent implements OnInit {

    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;
    subHeader: Observable<string>;
    isFullScreen: boolean = false;

    constructor(
        private store: Store<SubHeader>,
        injector: Injector,
        _dateTimeService: DateTimeService,
        @Inject(DOCUMENT) private document: Document) {
        super(injector, _dateTimeService);
    }

    ngOnInit() {
        // @ts-ignore
        this.subHeader= this.store.select('subHeader');
        this.subHeader.subscribe((subHeader: any) => this.isFullScreen = subHeader.data.isFullScreen);

        this.installationMode = UrlHelper.isInstallUrl(location.href);
        if (this.currentTheme.baseSettings.menu.defaultMinimizedAside){
            this.document.body.setAttribute('data-kt-aside-minimize', 'on');
        }
    }
}
