import {GeneralWidgetConfigDto} from "@app/shared/models/GeneralWidgetConfigDto";
import {DatasourceWidgetConfigDto} from "@app/shared/models/DatasourceWidgetConfigDto";
import {SeriesWidgetConfigDto} from "@app/shared/models/SeriesWidgetConfigDto";
import {FormatWidgetConfigDto} from "@app/shared/models/FormatWidgetConfigDto";
import {AxexWidgetConfigDto} from "@app/shared/models/AxexWidgetConfigDto";
import {DataWidgetConfigDto} from "@app/shared/models/DataWidgetConfigDto";
import {SettingsWidgetConfigDto} from "@app/shared/models/SettingsWidgetConfigDto";
import {UnitModelWidgetConfigDto} from "@app/shared/models/UnitModelWidgetConfigDto";
import { BaseComponentDto } from "@shared/service-proxies/service-proxies";

export class EditWidgetConfigDto {
    component?: string;
    divId: string;
    widgetId: number;
    defaultWidgetName: string;
    general: GeneralWidgetConfigDto;
    datasource: DatasourceWidgetConfigDto;
    series: SeriesWidgetConfigDto;
    format: FormatWidgetConfigDto;
    axex: AxexWidgetConfigDto;
    data: DataWidgetConfigDto;
    settings: SettingsWidgetConfigDto;
    unitModel: UnitModelWidgetConfigDto;
    baseComponent?: BaseComponentDto;
}



