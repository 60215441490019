<div class="container" *ngIf="loading">
    <div class="row">
        <div class="col text-center">
            <div class="spinner-container">
                <div class="spinner-border" style="width: 3rem; height: 3rem;color: #890c58" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </div>
</div>
