export class BaseComponentConsts {
    static readonly WindSpeed = 1;
    static readonly Power = 2;
    static readonly WindDirection = 3;
    static readonly RotorSpeed = 4;
    static readonly Availability = 5;
    static readonly PavaVWind = 6;
    static readonly NominalPower = 7;
    static readonly WaterLevel = 8;
    static readonly PavaTech = 9;
    static readonly NacelleTemperature = 10;
    static readonly Status = 11;
    static readonly ReactivePower = 12;
    static readonly PfkStatus = 13;
    static readonly Pfk = 14;
    static readonly Sfk = 15;
    static readonly CalcCosPhi = 16;
    static readonly Radiation = 17;
    static readonly PanelTemperature = 18;
    static readonly Efficiency = 19;
    static readonly NRotor = 20;
    static readonly GoPos = 21;
    static readonly Hour = 22;
    static readonly Wexp = 23;
    static readonly SerieNo = 24;
    static readonly TypeNo = 25;
    static readonly Alias = 26;
    static readonly CalcVwind = 27;
    static readonly CalcWDir = 28;
    static readonly CalcP = 29;
    static readonly CalcQ = 30;
    static readonly AvaPlant = 31;
    static readonly ComState = 32;
    static readonly AbstMaxP = 33;
    static readonly PavaForceM = 34;
    static readonly PavaExtern = 35;
    static readonly CalcQAbsRes = 36;
    static readonly CalcQGenRes = 37;
    static readonly PavaVWind2 = 38;
    static readonly AirPres = 39;
    static readonly PtAngValBl1 = 40;
    static readonly PtAngValBl2 = 41;
    static readonly PtAngValBl3 = 42;
    static readonly PwrActMax = 43;
    static readonly YawHydAccPres = 44;
    static readonly PwrReactCWE = 45;
    static readonly CosPhiCWE = 46;
    static readonly GbxTmpCW = 47;
    static readonly BotBoxTmp = 48;
    static readonly GriActPwrMaxMet = 49;
    static readonly GriActPwrMaxMan = 50;
    static readonly GriActPwrMaxWtgProt = 51;
    static readonly GriActPwrMaxWtgOp = 52;
    static readonly GriActPwrMaxGcmErr = 53;
    static readonly GriActPwrMaxGcm = 54;
    static readonly GriActPwrMaxCWE = 55;
    static readonly GriActPwrMaxID = 56;
    static readonly PwrReactStatCom = 57;
    static readonly GriVolt1 = 58;
    static readonly GriVolt2 = 59;
    static readonly GriVolt3 = 60;
    static readonly GriCurL1 = 61;
    static readonly GriCurL2 = 62;
    static readonly GriCurL3 = 63;
    static readonly HydPres = 64;
    static readonly RotBrkPres = 65;
    static readonly GbxPresOil = 66;
    static readonly GnTmpOutlet = 67;
    static readonly ExtTmp = 68;
    static readonly GnTmpL1 = 69;
    static readonly GnTmpL2 = 70;
    static readonly GbxBrgTmp1 = 71;
    static readonly GbxTmpOil = 72;
    static readonly TrmTmpShfBrg1 = 73;
    static readonly HydTmpOil = 74;
    static readonly GnTmpInlet = 75;
    static readonly GnBrgBS = 76;
    static readonly GbxTmpOilSmp = 77;
    static readonly TopBoxTmp = 78;
    static readonly GnTmpL3 = 79;
    static readonly NacDir = 80;
    static readonly BotBoxCtrFldTmp = 81;
    static readonly CabWup = 82;
    static readonly PtAngBl1 = 83;
    static readonly PtAngBl2 = 84;
    static readonly PtAngBl3 = 85;
    static readonly TurSt = 86;
    static readonly CommStOk = 87;
    static readonly GbxTempOil = 88;
    static readonly GnBrgAS = 89;
    static readonly GnSpd = 90;
    static readonly GriFrqWTG = 91;
    static readonly LOCALRFCTIME = 92;
    static readonly PwrActCWE = 93;
    static readonly PwrApp = 94;
    static readonly RFCTIME = 95;
    static readonly TurError = 96;
    static readonly YwSt = 97;
    static readonly Temp_Ambient = 98;
    static readonly Historical_Capacity_Factor = 99;
    static readonly Std_Availability = 100;
    static readonly Nacelle_position = 101;
    static readonly EnergyGenTot = 102;
    static readonly PowerLimit = 103;
    static readonly Unknown = 104; // Bu, Enum'da olmayan bir değer
}
