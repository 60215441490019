import { BaseComponentDto } from "@shared/service-proxies/service-proxies";
import { DateTime } from "luxon";

export class EditWidgetConfig {
    general: GeneralWidgetConfig;
    datasource: DatasourceWidgetConfig;
    series: SeriesWidgetConfig;
    format: FormatWidgetConfig;
    axex: AxexWidgetConfig;
    data: DataWidgetConfig;
    settings: SettingsWidgetConfig;
    unitModel: UnitModelWidgetConfig;
    baseComponent?: BaseComponentDto;
    deletedPoint: DeletedPointDto[];
}

export class GeneralWidgetConfig {
    visible: boolean;
    name: string;
    description: string;
    isFilterTopRecords?: boolean = false;
    isFilterErrorCode?: boolean = false;
    filterTopRecordFilters?: number[] = [];
    selectedTopRecordFilter?: number;
}

export class DatasourceWidgetConfig {
    visible: boolean;
}

export class SeriesWidgetConfig {
    visible: boolean;
    selectedComponent: SelectedBaseComponent[];
}

export class SelectedBaseComponent {
    id: number;
    name: string;
}

export class FormatWidgetConfig {
    visible: boolean;
}

export class AxexWidgetConfig {
    visible: boolean;
}

export class DataWidgetConfig {
    visible: boolean;
    xAxis: DataWidgetConfigContent[];
    yAxis: DataWidgetConfigContent[];
    chartType: string;
    multiScale: boolean;
    legendEnable: boolean;
    legend: string;
    selectedYears: LegedDto[];
    selectedMonths: LegedDto[];
}

export class SettingsWidgetConfig {
    visible: boolean;
}

export class UnitModelWidgetConfig {
    visible: boolean;
    unitModelId: number;
}

export class DataWidgetConfigContent {
    id: number;
    uuid: string;
    isSelected: boolean;
    name: string;
    type: string;
    dataSpecs: string[];
    timeFrequency: string;
    aggregate: string;
    unit: string;
    index: number;
}


export class LegedDto {
    name: string;
    value: string;
}


export class SelectedLegend {
    selectedYears: LegedDto[];
    selectedMonths: LegedDto[];
    legend: string;
}

export class DeletedPointDto {
    startDate: DateTime;
    endDate: DateTime;
    points: [];
}
