import { Component, Injector, Input, OnChanges, SimpleChanges } from "@angular/core";
import * as Highcharts from "@node_modules/highcharts";
import xrange from "highcharts/modules/xrange";
import { Options } from "@node_modules/highcharts/highcharts";
import { DashboardItemWidgetType } from "@app/shared/common/widget/widget-consts";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ChartService } from "@app/shared/common/dashboard-charts/chart.service";

xrange(Highcharts);

@Component({
    selector: "app-xrange-chart",
    templateUrl: "./xrange-chart.component.html",
    styleUrls: ["./xrange-chart.component.scss"],
})
export class XRangeChartComponent extends AppComponentBase implements OnChanges {
    @Input() divId: string;
    @Input() chartName: string;
    @Input() completedEventId: string;

    @Input() series: Highcharts.SeriesOptionsType[];
    @Input() yCategories: string[];
    @Input() tooltipFormatter: (arg: Highcharts.TooltipFormatterContextObject) => string;

    highchart: Highcharts.Chart;
    options: Options;

    constructor(injector: Injector, private _chartService: ChartService) {
        super(injector);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.series) {
            this.initChart();
            setTimeout(() => {
                abp.event.trigger(this.completedEventId, {
                    isCompleted: true,
                    highChart: this.highchart,
                    type: DashboardItemWidgetType.chart,
                });
            }, 1000);
        }
    }

    getChartOptions(): Options {
        let that = this;
        return {
            chart: {
                type: "xrange",
            },
            title: {
                text: null,
            },
            credits: {
                enabled: false,
            },
            legend: {
                enabled: false,
            },
            exporting: {
                enabled: false,
            },
            xAxis: {
                ordinal: false,
                type: "datetime",
            },
            yAxis: {
                startOnTick: false,
                title: {
                    text: null,
                },
                categories: this.yCategories,
                reversed: true,
                gridLineWidth: 0,
            },
            series: this.series,
            tooltip: {
                formatter: function (tooltip): string {
                    return that.tooltipFormatter(this);
                },
            },
        };
    }

    setOptions() {
        this.options = this.getChartOptions();
    }

    update() {
        this.setOptions();
        this.highchart.update(this.options);
    }

    initChart() {
        this.setOptions();
        let chart = document.querySelector("#" + this.divId + " #" + this.chartName);

        // @ts-ignore
        this.highchart = Highcharts.chart(chart, this.options);

        // Retry işlemini gerçekleştiren bir fonksiyon tanımlayın
        this._chartService.retryUntilSuccess(
            1000, // İnterval (ms)
            10, // Maksimum deneme sayısı
            () => Highcharts.charts.find((v) => v["renderTo"].id == this.chartName) !== undefined, // Koşul
            () => {
                const xRangeChart = Highcharts.charts.find((v) => v["renderTo"].id == this.chartName);
                if (xRangeChart) {
                    xRangeChart.reflow();
                }
            }
        );

        setTimeout(() => {
            abp.event.trigger(this.completedEventId, {
                isCompleted: true,
                highChart: this.highchart,
                type: DashboardItemWidgetType.chart,
            });
        }, 1000);
    }
}
