import { Injectable, Injector } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { HubConnection } from "@microsoft/signalr";
import * as signalR from "@node_modules/@microsoft/signalr";
import { LocalStorageService } from "@shared/utils/local-storage.service";
import { AppConsts } from "@shared/AppConsts";

@Injectable()
export class PreprocessingSignalrService extends AppComponentBase {
    preprocessingHub: HubConnection;

    constructor(injector: Injector) {
        super(injector);
    }

    GetConnection() {
        let that = this;
        new LocalStorageService().getItem(AppConsts.authorization.encrptedAuthTokenName, function(err, value) {
            let encryptedAuthToken = value?.token;
            const url = `${AppConsts.remoteServiceWsUrl}/signalr-preprocessing?${AppConsts.authorization.encrptedAuthTokenName}=${encodeURIComponent(encryptedAuthToken)}`;
            that.preprocessingHub = new signalR.HubConnectionBuilder()
                .configureLogging(signalR.LogLevel.Information)
                .withAutomaticReconnect()
                .withUrl(url, {
                    skipNegotiation: true,
                    transport: signalR.HttpTransportType.WebSockets
                })
                .build();
            that.preprocessingHub.start().then(function() {
                console.log("SignalR Connected!");
            }).catch(function(err) {
                console.log(err);
                return console.error(err.toString());
            });
        });
    }

    init(): void {
        this.GetConnection();
    }

    close() {
        this.preprocessingHub?.stop();
    }
}
