<div class="frame-43 screen">
    <div class="stack-2 stack-7">
        <div class="col-md-3">
            <div class="widget-data-grid-dense">
                <div class="data-grid-head data-grid">
                    <div class="table-cell-2 table-cell-3">
                        <div class="stack-3 stack-7">
                            <div class="th-text publicsans-bold-charade-12px">NAME</div>
                        </div>
                    </div>
                </div>
                <div
                    class="box-list"
                    cdkDropList
                    cdkDropListOrientation="vertical"
                    (cdkDropListDropped)="dragDrop($event)">
                    <div class="drag-box" *ngFor="let column of columns" cdkDrag>
                        <div (mousedown)="seriesClick(column.id)" (click)="seriesClick(column.id)"
                             [class]="this.activeId === column.id ? 'data-grid-row-1 data-grid':'data-grid-row data-grid'">
                            <div class="table-cell">
                                <div class="stack">
                                    <div [class]="this.activeId === column.id ? 'avatar-1':'avatar'">
                                        <div class="container">
                                            <img
                                                [class]="this.activeId === column.id ? 'iconsic_replace-active':'iconsic_replace'"
                                                src="assets/common/images/edit-widget/series/icons-ic-replace.svg"
                                                alt="icons/ic_replace">
                                        </div>
                                    </div>
                                    <div
                                        [class]="this.activeId === column.id ? 'name2-1 publicsans-semi-bold-pale-sky-14px':'name2 publicsans-semi-bold-pale-sky-14px'">{{column.name}}</div>
                                </div>
                            </div>
                            <div class="table-cell-1 table-cell-3">
                                <article class="icon-button-3" (click)="removeColumn(column.id)">
                                    <img
                                        [class]="this.activeId === column.id ? 'icon-active':'icon'"
                                        src="assets/common/images/edit-widget/series/icons-ic-trash.svg"
                                        alt="icons/ic_trash">
                                </article>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container">
                    <div class="row">
                            <div class="col-8">
                                <select class="form-select" [(ngModel)]="selectedBaseComponent">
                                    <option
                                        *ngFor="let component of baseComponents"
                                        [value]="component.id"
                                    >
                                        {{ component.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-4 mt-2">
                                <div class="button" (click)="addBaseComponent()">
                                    <div class="label">Add data</div>
                                    <img class="icon" src="assets/common/images/edit-widget/series/end-icon.svg"
                                         alt="end icon">
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
