import { NgModule } from '@angular/core';
import { HotTableModule } from '@handsontable/angular';
import { ProxyTableComponent } from '@app/shared/common/proxy-table/proxy-table.component';

@NgModule({
    declarations: [
        ProxyTableComponent
    ],
    imports: [HotTableModule],
    exports: [ProxyTableComponent]
})
export class ProxyTableModule { }
