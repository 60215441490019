<div class="frame-44 screen">
    <div class="stack-1 stack-3">
        <div class="col-md-3">
            <div class="user-account-update-user-form-1">
                <div class="specification publicsans-bold-charade-12px">SELECTION</div>
                <div class="stack">
                    <div class="text-field">
                        <div class="input">
                            <div class="container">
                                <div class="label-focus label-2 publicsans-normal-manatee-12px">Breakdown</div>
                                <div class="content">
                                    <div class="name1 publicsans-normal-charade-16px">Selected asset children</div>
                                </div>
                            </div>
                            <div class="space-m"></div>
                        </div>
                        <img class="select-arrow" src="assets/common/images/edit-widget/data-source/select-arrow.svg" alt="select arrow">
                    </div>
                </div>
                <div class="stack">
                    <div class="text-field">
                        <div class="input">
                            <div class="container">
                                <div class="label-focus label-2 publicsans-normal-manatee-12px">Relationship to use</div>
                                <div class="content"><div class="name1 publicsans-normal-charade-16px">Wind turbines</div></div>
                            </div>
                            <div class="space-m"></div>
                        </div>
                        <img class="select-arrow" src="assets/common/images/edit-widget/data-source/select-arrow.svg" alt="select arrow">
                    </div>
                </div>
            </div>

        </div>
        <div class="col-md-5">
            <div class="user-account-update-user-form publicsans-bold-charade-12px">
                <div class="specification">FILTER</div>
                <div class="stack-2 stack-3">
                    <div class="text-field-1">
                        <div class="input-1">
                            <div class="container-1">
                                <div class="label-focus label-2 publicsans-normal-manatee-12px">Asset set filter</div>
                                <div class="content-1">
                                    <p class="name1-1 publicsans-normal-charade-16px">Wind turbine without relative wind direction</p>
                                </div>
                            </div>
                            <div class="space-m"></div>
                        </div>
                        <img class="select-arrow-1" src="assets/common/images/edit-widget/data-source/select-arrow.svg" alt="select arrow">
                    </div>
                </div>
                <div class="frame-59 frame-1">
                    <div class="specification-1">QUICK FILTER</div>
                    <div class="frame-3 frame-1">
                        <article class="chip">
                            <div class="label-container label-2"><div class="label publicsans-normal-charade-13px">Lorem</div></div>
                        </article>
                        <article class="chip-1">
                            <div class="label-container label-2"><div class="label publicsans-normal-charade-13px">Ipsum</div></div>
                        </article>
                        <article class="chip">
                            <div class="label-container label-2"><div class="label publicsans-normal-charade-13px">Dolor</div></div>
                        </article>
                        <article class="chip">
                            <div class="label-container label-2">
                                <div class="label publicsans-normal-charade-13px">Lorem dolor</div>
                            </div>
                        </article>
                    </div>
                    <div class="frame-3 frame-1">
                        <article class="chip">
                            <div class="label-container label-2">
                                <div class="label publicsans-normal-charade-13px">İpsum sit amet lorem</div>
                            </div>
                        </article>
                        <article class="chip">
                            <div class="label-container label-2">
                                <div class="label publicsans-normal-charade-13px">İpsum sit amet lorem</div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>

        </div>
        <div class="col-md-4">
            <div class="user-account-update-user-form">
                <div class="specification publicsans-bold-charade-12px">ADDITIONAL FILTER</div>
                <div class="frame">
                    <div class="file_-manager-filters publicsans-bold-charade-14px">
                        <article class="f-button-2 f-button-3">
                            <p class="label-1 valign-text-middle label-2">01 - 03 Jun 2020</p>
                            <img class="end-icon" src="assets/common/images/edit-widget/data-source/start-icon.svg" alt="end icon">
                        </article>
                        <article class="f-button">
                            <div class="label-1 valign-text-middle label-2">All types</div>
                            <img class="end-icon" src="assets/common/images/edit-widget/data-source/end-icon-1.svg" alt="end icon">
                        </article>
                        <article class="f-button">
                            <div class="label-1 valign-text-middle label-2">Lorem</div>
                            <img class="end-icon" src="assets/common/images/edit-widget/data-source/end-icon-1.svg" alt="end icon">
                        </article>
                    </div>
                    <div class="frame">
                        <div class="file_-manager-filters publicsans-bold-charade-14px">
                            <article class="f-button-1 f-button-3">
                                <div class="label-1 valign-text-middle label-2">Lorem</div>
                                <img class="end-icon" src="assets/common/images/edit-widget/data-source/end-icon-1.svg" alt="end icon">
                            </article>
                            <article class="f-button-1 f-button-3">
                                <div class="label-1 valign-text-middle label-2">Lorem</div>
                                <img class="end-icon" src="assets/common/images/edit-widget/data-source/end-icon-1.svg" alt="end icon">
                            </article>
                            <article class="f-button-1 f-button-3">
                                <div class="label-1 valign-text-middle label-2">Lorem</div>
                                <img class="end-icon" src="assets/common/images/edit-widget/data-source/end-icon-1.svg" alt="end icon">
                            </article>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
