import { Injectable } from "@angular/core";

@Injectable()
export class SessionStorageService {
    getItem(key: string, callback?: (value: any) => void): void {
        const value = sessionStorage.getItem(key);
        if (callback) {
            callback(value);
        }
    }

    getItemAsync(key: string): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                const value = sessionStorage.getItem(key);
                resolve(value);
            } catch (error) {
                reject(error);
            }
        });
    }

    setItem(key: string, value: any, callback?: () => void): void {
        try {
            sessionStorage.setItem(key, value);
            if (callback) {
                callback();
            }
        } catch (error) {
            console.error("Failed to set item in sessionStorage", error);
        }
    }

    removeItem(key: string, callback?: () => void): void {
        try {
            sessionStorage.removeItem(key);
            if (callback) {
                callback();
            }
        } catch (error) {
            console.error("Failed to remove item from sessionStorage", error);
        }
    }
}

