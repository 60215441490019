import { Injectable, Injector } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";

@Injectable()
export class ChartService extends AppComponentBase {
    constructor(injector: Injector) {
        super(injector);
    }

    // Perform the reflow operation using the retry Until Success function when a certain condition is met
    retryUntilSuccess(interval: number, maxAttempts: number, condition: () => boolean, action: () => void) {
        let attempts = 0;

        function retry() {
            setTimeout(() => {
                if (!condition() && attempts < maxAttempts) {
                    attempts++;
                    retry();
                } else if (condition()) {
                    action();
                }
            }, interval);
        }

        retry();
    }
}
