<div class="bottom-draweru58-settings">
    <div class="frame-43 frame">
        <div class="stack">
            <div class="user-account-update-user-form">
                <div class="title-icon">
                    <div class="icon-button">
                        <div class="ic_tabler_stack-2"></div>
                    </div>
                    <div class="title publicsans-bold-charade-14px">STACK CONTENTS</div>
                    <div class="icon-button">
                        <img class="iconsic_info_outline"
                             src="/assets/common/images/edit-widget/settings/icons-ic-info-outline.svg"
                             alt="icons/ic_info_outline"/>
                    </div>
                </div>
                <div class="frame-16 frame">
                    <div class="frame-163 frame">
                        <article class="switch">
                            <div class="switch-container switch-3">
                                <div class="switch-1 switch-3">
                                    <div class="thumb"></div>
                                </div>
                            </div>
                            <div class="label-end label-4 publicsans-normal-charade-14px">Bar charts</div>
                        </article>
                        <article class="switch">
                            <div class="switch-container switch-3">
                                <div class="switch-2 switch-3">
                                    <div class="thumb"></div>
                                </div>
                            </div>
                            <div class="label-end label-4 publicsans-normal-charade-14px">Line charts</div>
                        </article>
                        <article class="switch">
                            <div class="switch-container switch-3">
                                <div class="switch-2 switch-3">
                                    <div class="thumb"></div>
                                </div>
                            </div>
                            <div class="label-end label-4 publicsans-normal-charade-14px">Area charts</div>
                        </article>
                        <article class="switch">
                            <div class="switch-container switch-3">
                                <div class="switch-2 switch-3">
                                    <div class="thumb"></div>
                                </div>
                            </div>
                            <div class="label-end label-4 publicsans-normal-charade-14px">Scatter plots</div>
                        </article>
                    </div>
                </div>
                <div class="frame-16 frame">
                    <div class="alert">
                        <div class="icon-container">
                            <img class="iconsic_info" src="/assets/common/images/edit-widget/settings/icons-ic-info.svg"
                                 alt="icons/ic_info"/>
                        </div>
                        <div class="text"><p class="description">This is an Info alert — check it out!</p></div>
                    </div>
                </div>
            </div>
            <div class="user-account-update-user-form">
                <div class="title-icon">
                    <div class="icon-button">
                        <div class="ic_tabler_layout-grid"></div>
                    </div>
                    <div class="title publicsans-bold-charade-14px">SMALL MULTIPLES</div>
                    <div class="icon-button">
                        <img class="iconsic_info_outline"
                             src="/assets/common/images/edit-widget/settings/icons-ic-info-outline-1.svg"
                             alt="icons/ic_info_outline"/>
                    </div>
                </div>
                <div class="frame-16 frame">
                    <div class="switch">
                        <div class="switch-container switch-3">
                            <div class="switch-1 switch-3">
                                <div class="thumb"></div>
                            </div>
                        </div>
                        <div class="label-end label-4 publicsans-normal-charade-14px">Enable</div>
                    </div>
                    <div class="frame-160 frame">
                        <div class="stack-1">
                            <div class="text-field">
                                <div class="input">
                                    <div class="container-1">
                                        <div class="label-focus label-4">Based on</div>
                                        <div class="content">
                                            <div class="name1">Please select</div>
                                        </div>
                                    </div>
                                    <div class="space-s"></div>
                                </div>
                                <img class="select-arrow"
                                     src="/assets/common/images/edit-widget/settings/select-arrow.svg"
                                     alt="select arrow"/>
                            </div>
                        </div>
                        <div class="alert">
                            <div class="icon-container">
                                <img class="iconsic_info"
                                     src="/assets/common/images/edit-widget/settings/icons-ic-info-1.svg"
                                     alt="icons/ic_info"/>
                            </div>
                            <div class="text"><p class="description">This is an Info alert — check it out!</p></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="user-account-update-user-form">
                <div class="title-icon">
                    <div class="icon-button">
                        <div class="ic_tabler_chart-infographic"></div>
                    </div>
                    <div class="title publicsans-bold-charade-14px">LEGEND</div>
                    <div class="icon-button">
                        <img class="iconsic_info_outline"
                             src="/assets/common/images/edit-widget/settings/icons-ic-info-outline-2.svg"
                             alt="icons/ic_info_outline"/>
                    </div>
                </div>
                <div class="frame-16 frame">
                    <div class="switch">
                        <div class="switch-container switch-3">
                            <div class="switch-2 switch-3">
                                <div class="thumb"></div>
                            </div>
                        </div>
                        <div class="label-end label-4 publicsans-normal-charade-14px">Enable</div>
                    </div>
                    <div class="frame-160 frame">
                        <div class="text-field-1">
                            <div class="input-1">
                                <div class="container-1">
                                    <div class="label-focus label-4">Based on</div>
                                    <div class="content">
                                        <div class="name1-1">Please select</div>
                                    </div>
                                </div>
                                <div class="space-s"></div>
                            </div>
                            <img class="select-arrow"
                                 src="/assets/common/images/edit-widget/settings/select-arrow-1.svg"
                                 alt="select arrow"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
