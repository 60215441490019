import { Injectable, Injector } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { LocalStorageService } from "@shared/utils/local-storage.service";
import * as Highcharts from "@node_modules/highcharts/highstock";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import html2canvas from "@node_modules/html2canvas";
import pptxgen from "@node_modules/pptxgenjs";
import {
    ReportSection,
    ReportSectionType,
    SingleItem400PxCharts,
    TwoItem400PxTable,
} from "@app/main/reports/report-overview/report-overview.model";
import htmlToPdfmake from "html-to-pdfmake";
import { CreateOrEditReportDto } from "@shared/service-proxies/service-proxies";
import * as _ from "lodash";
import * as htmlToText from "html-to-text";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable()
export class ReportOverviewService extends AppComponentBase {
    private PdfMaxWidth = 650;
    private PdfMaxHeight = 450;
    private PdfMinHeight = 350;
    private PdfLeftMargin = 50;
    private PdfTopMargin = 150;
    private PdfHeaderTopMargin = 10;
    private PdfBulletGraphWidgetTopMargin = 150;
    private defaultPdfTemplatePage: string;
    public defaultPageUrl: string = "assets/common/images/report/senkron-template/default-page.jpg";
    public coverPageUrl: string = "assets/common/images/report/senkron-template/cover-page.jpg";

    singleItem400PxCharts = Object.values(SingleItem400PxCharts);
    twoItem400PxTable = Object.values(TwoItem400PxTable);
    contentList = [];

    constructor(injector: Injector, private _localStorageService: LocalStorageService) {
        super(injector);
    }

    setOverflowVisibleForClasses(classNames: string[]) {
        classNames.forEach((className) => {
            const element = document.querySelector("." + className) as HTMLElement;
            if (element) {
                element.style.overflow = "visible";
            }
        });
    }

    async getLocalStorageItemAsync(key, callback) {
        return new Promise((resolve) => {
            this._localStorageService.getItem(key, (err, data) => {
                callback(data);
                resolve(data);
            });
        });
    }

    async getChartImage(chart: Highcharts.Chart, chartName?: string | undefined): Promise<string> {
        if (!chart) {
            return "";
        }

        chart.title.update({
            text: "",
        });

        let width = 1052;
        let height = 823;
        if (chartName == "data_analysis_chart") {
            width = 1512;
            height = 588;
        } else if (chartName == "bulletgraph") {
            width = chart.chartWidth;
            height = chart.chartHeight;
        }

        // Get Actual SVG of a chart
        let svgString = chart.getSVG({
            exporting: {
                sourceWidth: width,
                sourceHeight: height,
            },
        });

        // Use DOMParser to parse new svg element from svgString
        let parser = new DOMParser();
        let svgElem = parser.parseFromString(svgString, "image/svg+xml").documentElement;

        // Use toDataURL extension to generate Base64 string
        let s = new XMLSerializer().serializeToString(svgElem);

        let encodedData = window.btoa(unescape(encodeURIComponent(s)));
        let svgBaseComponent = "data:image/svg+xml;base64," + encodedData;

        // Yeni bir img elementi oluştur
        let imgElement = document.createElement("img");

        // img elementinin src özelliğini asd değişkenine ata
        imgElement.src = svgBaseComponent;
        imgElement.style.position = "absolute";
        imgElement.style.left = "-9999px";
        imgElement.style.top = "-9999px";

        // Div'i belgeye ekleyin
        document.body.appendChild(imgElement);

        const canvas = await html2canvas(imgElement, {
            scale: 3,
            useCORS: true,
        });
        // imgElement.style.display = "none";
        const imgData = canvas.toDataURL("image/png");

        return imgData;
    }

    convertHtmlToText(htmlString: string): string {
        return Array.from(new DOMParser().parseFromString(htmlString, "text/html").body.childNodes)
            .map((node) => {
                if (node.nodeName === "H1") {
                    return node.textContent + ""; // Başlık ve bir alt satıra geç
                } else if (node.nodeName === "P") {
                    return Array.from(node.childNodes)
                        .map((childNode) => {
                            if (childNode.nodeName === "BR") {
                                return ""; // Satır atla
                            } else {
                                return childNode.textContent;
                            }
                        })
                        .join("");
                }
                return "";
            })
            .join("\n"); //
    }

    async generatePDF(sections: ReportSection[]): Promise<any> {
        // Resmin URL'si

        let defaultPageImgBase64 = await this.imgToBase64(this.defaultPageUrl);

        const dd = {
            background: {
                image: defaultPageImgBase64,
                width: 841.89, // Sayfanın genişliği (yatay konumda)
                height: 595.28, // Sayfanın yüksekliği (yatay konumda)
                margin: [0, 0, 0, 0], // [sol, üst, sağ, alt]
                // fit: [100, 50], // [Genişlik, Yükseklik] oranları
            },
            pageOrientation: "landscape",
            content: [],
            pageMargins: [0, 0, 0, 0], // [sol, üst, sağ, alt],
        };

        const promises = [];

        // Her bir bölüm için içeriği oluşturun
        for (let j = 0; j < sections.length; j++) {
            const item = sections[j];
            const contentArray = []; // Her "item" için content'i depolamak için bir dizi oluşturun

            let pageHeader: string = "";

            if (item.components.length == 1 && !this.singleItem400PxCharts.includes(item.components[0].customWidgetName)) {
                this.PdfTopMargin = 50;
            } else {
                this.PdfTopMargin = 100;
            }

            if (item.isHeaderActive) {
                pageHeader = item.header;
            } else {
                pageHeader = "";
            }

            dd.content.push(
                {
                    text: {
                        text: pageHeader,
                        color: item.components[0].type == "Cover" ? "#4CA034" : "#000",
                        fontSize: 32,
                        bold: true,
                        alignment: "center",
                        margin: [0, this.PdfHeaderTopMargin], // [sol, üst, sağ, alt]
                    },
                    absolutePosition: { x: 50, y: 50 }, // Metni resmin üzerine yerleştirmek için ayarlar
                },
                {
                    margin: [0, this.PdfTopMargin, 0, 0], // [sol, üst, sağ, alt]
                    columns: contentArray, // Bu "item" için contentArray'i kullanın
                }
            );
            if (j != sections.length - 1) {
                dd.content.push(
                    {
                        text: "",
                        pageBreak: "after",
                    } // Sayfa bölümünü bitir ve yeni sayfa bölümünü başlat
                );
            }

            for (let i = 0; i < item.components.length; i++) {
                let component = item.components[i];

                const promise = new Promise(async (innerResolve) => {
                    let html;

                    if (component.type == ReportSectionType.Text) {
                        // Metindeki <p> etiketlerini kaldırın
                        html = htmlToPdfmake(component.TextContent);
                        // Sonucu gösterin
                        contentArray.push({
                            width: item.components.length > 1 ? 400 : 800,
                            stack: html,
                            margin: [this.PdfLeftMargin, 50, 0, 0],
                        });
                    } else if (component.type == ReportSectionType.Widget || component.type == ReportSectionType.DataAnalysis) {
                        let chartWidth: number;
                        let chartHeight: number;
                        if (item.twoColumnLayout) {
                            chartWidth = this.PdfMaxWidth / 2;
                            chartHeight = this.PdfMinHeight;
                            if (i == 1) {
                                chartWidth = 350;
                            }
                        } else {
                            chartWidth = this.PdfMaxWidth;
                            chartHeight = this.PdfMinHeight;
                        }

                        if (component.widgetName && component.widgetName == "Unit Summary") {
                            chartHeight = 0;
                        }

                        let topMargin = 50;

                        if (component.type == ReportSectionType.DataAnalysis) {
                            topMargin = 50;
                        }
                        let chartStack = [];

                        if (!_.isNil(component.isWidgetHeaderNameHideForPdf) && component.isWidgetHeaderNameHideForPdf == false) {
                            chartStack.push({
                                text: component.customWidgetName,
                                alignment: "center",
                                fontSize: 22,
                                bold: true,
                                margin: [0, 0, 0, 10], // [sol, üst, sağ, alt]
                            });
                        }
                        if (!_.isNil(component.isWidgetDateTextHideForPdf) && component.isWidgetDateTextHideForPdf == false) {
                            chartStack.push({
                                text: component.dateString,
                                fontSize: 18,
                                alignment: "center",
                                margin: [0, 0, 0, 0], // [sol, üst, sağ, alt]
                            });
                        }

                        if (this.singleItem400PxCharts.includes(component.customWidgetName) && !item.twoColumnLayout) {
                            chartWidth = 400;
                        } else if (!this.singleItem400PxCharts.includes(component.customWidgetName) && !item.twoColumnLayout) {
                            chartHeight = 450;
                        } else if (this.twoItem400PxTable.includes(component.customWidgetName)) {
                            chartHeight = 0;
                        }

                        if (component.widgetName && component.widgetName.includes("Bullet")) {
                            chartHeight = 100;
                            topMargin = this.PdfBulletGraphWidgetTopMargin;
                        }
                        if (!_.isNil(component.imgUrl)) {
                            chartStack.push({
                                image: component.imgUrl,
                                width: chartWidth,
                                height: chartHeight,
                                alignment: "center",
                                margin: [this.PdfLeftMargin, topMargin, 0, 0], // [sol, üst, sağ, alt]
                            });
                        } else {
                            // write error log
                            console.error("Image URL is not found for widget: " + component.customWidgetName);
                        }

                        if (component.showSubText && component.subText?.length > 0) {
                            html = htmlToPdfmake(component.subText, {
                                defaultStyles: {
                                    p: { fontSize: 10 },
                                    h1: { fontSize: 16 },
                                    h2: { fontSize: 15 },
                                    h3: { fontSize: 14 },
                                    h4: { fontSize: 13 },
                                    h5: { fontSize: 12 },
                                    h6: { fontSize: 11 },
                                },
                            });

                            chartStack.push({
                                width: item.components.length > 1 ? 350 : 750,
                                height: 250,
                                margin: [50, 0, 0, 0],
                                text: html,
                                alignment: "center",
                            } as any);
                        }

                        contentArray.push({ width: item.twoColumnLayout ? "50%" : "100%", stack: chartStack });
                    } else if (component.type == ReportSectionType.Image) {
                        const pageWidth = 841.89;
                        const pageHeight = 595.28;
                        const imageWidth = pageWidth;
                        const imageHeight = pageHeight * 0.9;
                        const marginX = (pageWidth - imageWidth) / 2;
                        const marginY = (pageHeight - imageHeight) / 2;

                        let chartStack = [
                            {
                                image: component.TextContent.length > 0 ? "" : component.imgUrl,
                                fit: [imageWidth, imageHeight], // Resmi belirtilen genişlik ve yüksekliğe sığdır
                                margin: [0, 0, 0, 0],
                            },
                        ];

                        contentArray.push({
                            width: item.components.length > 1 ? "50%" : "100%",
                            stack: chartStack,
                            absolutePosition: { x: marginX, y: marginY }, // Resmi sayfanın ortasına hizala
                        });
                    } else if (component.type == ReportSectionType.TableOfContents) {
                        let tocList = sections.filter((x) => x.components[0].type == ReportSectionType.Cover);

                        this.collectContentTitles(tocList);
                        const toc = this.createPdfContent();

                        // Sonucu gösterin
                        contentArray.push({
                            width: "95%",
                            stack: toc,
                            margin: [50, 70, 0, 0],
                        });
                    } else if (component.type == ReportSectionType.Cover) {
                        let chartStack: any = [
                            {
                                image: component.imgUrl,
                                absolutePosition: { x: 0, y: 0 },
                                width: 841.89,
                                height: 595.28,
                                margin: [0, 0, 0, 0],
                            },
                        ];

                        // Metni bölmek için bir fonksiyon oluşturun
                        function divideText(text, maxLength) {
                            let result = "";
                            while (text.length > maxLength) {
                                let spaceIndex = text.lastIndexOf(" ", maxLength);
                                result += text.substring(0, spaceIndex) + "<br>";
                                text = text.substring(spaceIndex + 1);
                            }
                            result += text;
                            return result;
                        }

                        // Metni bölerek ekleyin
                        let headerText = item.header;
                        let maxLength = 22; // Maksimum karakter uzunluğu
                        let dividedText = divideText(headerText, maxLength);

                        html = htmlToPdfmake(dividedText);

                        chartStack.push({
                            text: html,
                            alignment: "left",
                            color: "#4CA034",
                            fontSize: 40,
                            bold: true,
                            margin: [45, 200, 0, 0],
                        });

                        contentArray.push({
                            width: "100%",
                            stack: chartStack,
                        });
                    }

                    // @ts-ignore
                    innerResolve();
                });

                promises.push(promise);
            }
        }

        await Promise.all(promises);

        return dd;
    }

    async generatePPTX(sections: ReportSection[], report: CreateOrEditReportDto): Promise<any> {
        let pptx = new pptxgen();

        for (const item of sections) {
            let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

            let pageHeader: string = "";

            if (item.isHeaderActive) {
                pageHeader = item.header;
            }
            if (item.components.find((x) => x.type === ReportSectionType.Cover)) {
                pageHeader = "";
            } else {
                pageHeader = "";
            }

            slide.addText(pageHeader, {
                align: "center",
                x: 0,
                y: "3%",
                w: "100%", // Genişlik
                h: "10%", // Yükseklik
            });

            if (item.components.find((x) => x.type === ReportSectionType.Cover)) {
                slide.background = {
                    path: "assets/common/images/report/senkron-template/cover-page.jpg",
                };
            } else {
                slide.background = {
                    path: "assets/common/images/report/senkron-template/default-page.jpg",
                };
            }

            // İki bölme için yatay olarak ortadan ikiye böl
            let leftHalf = slide.addShape("rect", {
                x: 0,
                y: 0,
                w: "50%",
                h: "100%",
            });

            let rightHalf = slide.addShape("rect", {
                x: "50%",
                y: 0,
                w: "50%",
                h: "100%",
            });

            let fullPage = slide.addShape("rect", {
                x: "100%",
                y: 0,
                w: "50%",
                h: "100%",
            });

            for (const component of item.components) {
                let ppSlides = component.order == 1 ? leftHalf : rightHalf;
                let percentage: any = component.order == 1 ? 0 : "50%";
                const content = document.getElementById(component.id);

                let imgData: any;
                if (content) {
                    const canvas = await html2canvas(content, {
                        scale: 3,
                        useCORS: true,
                    });
                    imgData = canvas.toDataURL("image/png");
                }
                if (component.type === ReportSectionType.Text) {
                    const options = {
                        wordwrap: 130,
                        // ...
                    };
                    const text = htmlToText.convert(component.TextContent, {
                        wordwrap: 130,
                    });
                    // leftHalf veya rightHalf'e ekleyin
                    ppSlides.addText(text, {
                        align: "left",
                        x: percentage,
                        y: "25%",
                        w: "50%", // Genişlik
                        h: "50%", // Yükseklik
                        fontSize: 12,
                    });
                } else if (component.type === ReportSectionType.Widget || component.type === ReportSectionType.DataAnalysis) {
                    const widgetX = !item.twoColumnLayout ? "10%" : percentage;
                    const widgetWidth = !item.twoColumnLayout ? "80%" : "50%";

                    ppSlides.addImage({
                        data: imgData,
                        x: widgetX,
                        y: "15%",
                        w: widgetWidth,
                        h: !item.twoColumnLayout ? "70%" : "50%", // Yükseklik
                    });

                    if (!_.isNil(component.isWidgetHeaderNameHideForPdf) && !component.isWidgetHeaderNameHideForPdf) {
                        ppSlides.addText(component.widgetName, {
                            align: "center",
                            x: widgetX,
                            y: "10%",
                            w: widgetWidth, // Genişlik
                            fontSize: 15,
                        });
                    }

                    if (!_.isNil(component.isWidgetDateTextHideForPdf) && !component.isWidgetDateTextHideForPdf) {
                        ppSlides.addText(component.dateString, {
                            align: "center",
                            x: widgetX,
                            y: "15%",
                            w: widgetWidth, // Genişlik
                            fontSize: 15,
                        });
                    }

                    if (component.showSubText && component.subText?.length > 0) {
                        const subtext = this.convertHtmlToText(component.subText);
                        ppSlides.addText(subtext, {
                            align: "center",
                            x: widgetX,
                            y: "90%",
                            w: widgetWidth, // Genişlik
                            fontSize: 12,
                        });
                    }
                } else if (component.type === ReportSectionType.Image) {
                    // leftHalf veya rightHalf'e ekleyin
                    ppSlides.addImage({
                        data: component.imgUrl,
                        x: percentage,
                        y: "25%",
                        w: "50%", // Genişlik
                        h: "50%", // Yükseklik
                    });
                } else if (component.type === ReportSectionType.TableOfContents) {
                    let tocList = sections.filter((x) => x.components[0].type == ReportSectionType.Cover);
                    this.collectContentTitles(tocList);
                    const toc = this.createPdfContent();

                    const tocContent = this.contentList.map((item) => {
                        return [item.text, item.page];
                    });

                    fullPage.addText("Table of Contents", { x: 1, y: 1, fontSize: 24, bold: true });

                    const tableRows = [
                        [{ text: "Contents" }, { text: "Page" }],
                        ...tocContent,
                        // Diğer bölümler burada...
                    ].map((row) => row.map((cell) => cell));

                    fullPage.addTable(tableRows, {
                        x: 1,
                        y: 1.2,
                        border: { pt: 1, color: "000000" },
                        fill: { color: "FFFFFF" },
                        color: "000000",
                        fontSize: 12,
                        colW: [6, 1],
                    });
                } else if (component.type === ReportSectionType.Cover) {
                    const text = item.header;

                    fullPage.addText(text, {
                        align: "left",
                        x: 100,
                        y: "50%",
                        w: "50%", // Genişlik
                        fontSize: 32,
                        color: "#4CA034",
                        bold: true,
                    });
                }
            }
        }

        pptx.writeFile({ fileName: report.name });
    }

    imgToBase64(imageUrl: string): Promise<string> {
        return new Promise((resolve, reject) => {
            const img = new Image();

            // Resim yüklenince çalışacak fonksiyon
            img.onload = function () {
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");

                if (!context) {
                    reject(new Error("Canvas context could not be obtained."));
                    return;
                }

                canvas.width = img.width;
                canvas.height = img.height;

                // Resmi canvas'a çizin
                context.drawImage(img, 0, 0);

                // Base64 verisine dönüştürün
                const base64ImageData = canvas.toDataURL("image/jpeg");

                resolve(base64ImageData);
            };

            // Resim yüklenirken hata oluşursa
            img.onerror = function () {
                reject(new Error("Failed to load image."));
            };

            // Resmi yükle
            img.src = imageUrl;
        });
    }

    collectContentTitles(sections: ReportSection[]) {
        this.contentList = [];
        let tocTree = this.buildTree(sections.filter((x) => x.isTOCSelected === true && x.isHidden === false));

        tocTree = tocTree.filter((x) => x.components[0].type !== ReportSectionType.TableOfContents);

        for (const root of tocTree) {
            this.contentList.push({
                text: `${!_.isNil(root.header) ? root.header : ""}`,
                page: root.index + 1,
            });
            for (const child of root.children) {
                this.contentList.push({
                    text: `${!_.isNil(child.header) ? child.header : ""}`,
                    page: child.index + 1,
                });
            }
        }
    }

    // İçerik tablosunu oluşturmak için bir fonksiyon
    createTableOfContents() {
        const tocContent = this.contentList.map((item) => {
            return [item.text, item.page];
        });

        // Tablo tanımı
        const table = {
            table: {
                headerRows: 1,
                widths: ["*", "auto"],
                body: [
                    [
                        { text: "Contents", bold: true },
                        { text: "Page", bold: true },
                    ],
                    ...tocContent,
                ],
            },
        };

        return table;
    }

    // PDF içeriğini oluşturma
    createPdfContent() {
        // İçerik tablosunu oluştur
        const tableOfContents = this.createTableOfContents();

        // Tüm içerikleri birleştir
        return [tableOfContents];
    }

    buildTree(sections: ReportSection[]): ReportSection[] {
        const sectionMap: Record<number, ReportSection> = {};

        // Create a mapping of sections by their id
        sections.forEach((section) => {
            sectionMap[section.id] = section;
            section.children = [];
        });

        // Build the tree hierarchy
        const rootSections: ReportSection[] = [];
        sections.forEach((section) => {
            if (section.isRoot) {
                rootSections.push(section);
            } else {
                const parentSection = sectionMap[section.rootId];
                if (parentSection) {
                    parentSection.children.push(section);
                }
            }
        });

        return rootSections;
    }
}
