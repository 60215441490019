import { Injectable } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { XmlHttpRequestHelper } from '@shared/helpers/XmlHttpRequestHelper';
import { LocalStorageService } from '@shared/utils/local-storage.service';

@Injectable()
export class AppAuthService {
    logout(reload?: boolean, returnUrl?: string): void {
        let customHeaders = {
            [abp.multiTenancy.tenantIdCookieName]: abp.multiTenancy.getTenantIdCookie(),
            Authorization: 'Bearer ' + abp.auth.getToken(),
        };

        XmlHttpRequestHelper.ajax(
            'GET',
            AppConsts.remoteServiceBaseUrl + '/api/TokenAuth/LogOut',
            customHeaders,
            null,
            () => {
                this.deleteAllCookies();

                window.localStorage.clear();
                // Clear session storage
                window.sessionStorage.clear();
                caches.keys().then(keys => {
                    keys.forEach(key => caches.delete(key));
                });

                indexedDB.databases().then(dbs => {
                    dbs.forEach(db => indexedDB.deleteDatabase(db.name));
                });
                abp.auth.clearToken();
                abp.auth.clearRefreshToken();
                new LocalStorageService().removeItem(AppConsts.authorization.encrptedAuthTokenName, () => {
                    if (reload !== false) {
                        if (returnUrl) {
                            location.href = returnUrl;
                        } else {
                            location.href = '';
                        }
                    }
                });
            }
        );
    }

    deleteAllCookies() {
        // Get all cookies
        var cookies = document.cookie.split(";");

        // Loop through all cookies and delete them
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
        }
    }
}
