<div bsModal #createOrEditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="createOrEditModal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-xs">
        <div class="modal-content">
            <div class="dialogue-save-widget">
                <div class="dialog dialog-2 modal-header">
                    <div class="title">Would you like to save the data analysis as a widget?</div>
                    <a (click)="close()" class="close" style="cursor: pointer">
                        <div class="icon-button"><img class="icon"
                                                      src="/assets/dashboard/icons-ic-close.svg"
                                                      alt="icons/ic_close"/></div>
                    </a>
                </div>
                <div *ngIf="dashboard" class="content">
                    <p class="text">
                        <span class="span0">You will save the data analysis as a widget: </span><span
                        class="span1">“{{this.dashboard.name}}”<br/></span
                    ><span class="span2"></span>
                    </p>
                </div>

                <div class="content">
                    <div class="label-focus-share2 label-2-share2">Widget Name</div>
                    <div class="content-2-share2 content-3-share2">
                        <input type="text" class="form-control" id="widgetName" name="widgetName" [(ngModel)]="widgetName" >
                    </div>
                </div>

                <div class="help-text">
                    <img class="iconsic_info" src="/assets/dashboard/icons-ic-info.svg" alt="icons/ic_info"/>
                    <p class="helper-text" style="margin-top: 12px;">Save data analysis as a widget so you can use it on dashboards..</p>
                </div>
                <div class="modal-footer dialog-1 dialog-2">
                    <div class="frame-67">
                        <button type="button" (click)="close()" class="button-1111 button-3333" style="cursor: pointer">
                            <div class="label-1 valign-text-middle label-5555 ">{{l("Cancel")}}</div>
                        </button>
                        <button [disabled]="saving" (click)="saveAsWidget()" class="button-222 button-333" style="cursor: pointer">
                            <img class="start-icon-1" src="/assets/common/images/top-bar/img/save-dashboard-icon.svg" alt="start icon"/>
                            <div class="label-222 valign-text-middle label-555">{{l("Save")}}</div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
