import {Component, Input} from '@angular/core';
import {PowerPlant} from "@shared/service-proxies/service-proxies";
import {Options} from "@node_modules/@angular-slider/ngx-slider";
import {EditWidgetConfigDto} from "@app/state-management/models/edit-widget-config";
import * as ReportTrialsUtilities from "@app/main/report-trials/utilities";

@Component({
  selector: 'edit-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class EditSettingsComponent {
    @Input() powerPlant: PowerPlant;
    searchvalue = '';
    selected: boolean = false;
    isOrientation: boolean = false;
    value: number = 3;
    options: Options = {
        ceil: 8,
        floor: 0,
        showSelectionBar: true,
        showTicks: true,
    };
    config: EditWidgetConfigDto;
    public ShowOverlay(overlayName, animationName) {
        ReportTrialsUtilities.ShowOverlay(overlayName, animationName);
    };
}
