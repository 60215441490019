import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: 'progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {

    @Input() color: string
    @Input() barValue: number;
    @Input() precisionSize: number = 0;
    @Input() percentage: number = 0.0;

    ngOnInit() {

    }
}
