import { Component, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { AppComponentBase } from "@shared/common/app-component-base";

@Component({
  selector: 'app-no-permission',
  templateUrl: './no-permission.component.html',
  styleUrls: ['./no-permission.component.css']
})
export class NoPermissionComponent extends AppComponentBase  {

    constructor(injector: Injector, private router: Router
    ) {
        super(injector);
    }

    goToHome() {
        this.router.navigate(["/app/main/dashboards"]);

    }
}
