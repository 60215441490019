import {Component, Input} from '@angular/core';
import {Options} from "@node_modules/@angular-slider/ngx-slider";
import * as ReportTrialsUtilities from "@app/main/report-trials/utilities";
import {EditWidgetConfigDto} from "@app/state-management/models/edit-widget-config";
import {PowerPlant} from "@shared/service-proxies/service-proxies";

@Component({
  selector: 'edit-axes',
  templateUrl: './edit-axes.component.html',
  styleUrls: ['./edit-axes.component.scss']
})
export class EditAxesComponent {
    @Input() powerPlant: PowerPlant;
    searchvalue = '';
    selected: boolean = false;
    isOrientation: boolean = false;
    value: number = 3;
    highValue: number = 10;
    options: Options = {
        ceil: 8,
        floor: 0,
        showSelectionBar: true,
        showTicks: true,
    };
    config: EditWidgetConfigDto;
    public ShowOverlay(overlayName, animationName) {
        ReportTrialsUtilities.ShowOverlay(overlayName, animationName);
    };
}
