<div bsModal #createOrEditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="createOrEditModal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-xs">
        <div class="modal-content">
            <div class="dialogue-share-dashboard">
                <div class="dialog dialog-2 modal-header">
                    <img class="icon" src="/assets/dashboard/dashboard-share-modal-icon.svg" alt="icon-trash"/>
                    <div class="title">Share {{pageName}}?</div>
                    <a (click)="close()" class="close" style="cursor:pointer;">
                        <div class="icon-button"><img class="icon"
                                                      src="/assets/dashboard/icons-ic-close.svg"
                                                      alt="icons/ic_close"/></div>
                    </a>
                </div>
                <div *ngIf="dashboard" class="content">
                    <p class="text">
                        <span class="span0">You’re about to share: </span><span
                        class="span1">“{{this.dashboard.name}}”<br/></span
                    ><span class="span2"></span>
                    </p>
                </div>

                <div class="input-share2">
                    <div class="container-share2">
                        <div class="label-focus-share2 label-2-share2">Share with</div>
                        <div class="content-2-share2 content-3-share2">
                            <select class="form-select" name="TypeFilter" id="TypeFilterSelect" [(ngModel)]="typeFilter">
                                <option value="0">None</option>
                                <option value="10">Everyone</option>
                            </select>
                        </div>
                    </div>
                    <div class=" space-s-share2">
                    </div>
                </div>

                <div class="help-text">
                    <img class="iconsic_info" src="/assets/dashboard/icons-ic-info.svg" alt="icons/ic_info"/>
                    <p class="helper-text">Everyone in your company will be able to access this {{pageName.toLowerCase()}} thru
                        “Shared Library”. You can stop sharing via {{pageName.toLowerCase()}} Settings.</p>
                </div>
                <div class="modal-footer dialog-1 dialog-2">
                    <div class="button button-5"></div>
                    <div class="frame-67">
                        <button type="button" (click)="close()" class="button-share-1 button-share-3">
                            <div class="label-1 valign-text-middle label-5555 ">{{l("Cancel")}}</div>
                        </button>
                        <button [disabled]="saving" class="button-share button-333" (click)="share()">
                            <img class="start-icon-1" src="/assets/dashboard/dashboard-share-modal-button-icon.svg"
                                 alt="start icon"/>
                            <div class="label-222 valign-text-middle label-555">{{l("Share")}}</div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
