<div class="error403 screen">
    <div class="container">
        <div class="head">
            <h1 class="sorry-page-not-foun">No permission.</h1>
            <p class="sorry-we-couldnt-f">
                The page you're trying access has restricted access. Please refer to your system administrator
            </p>
        </div>
        <div class="x404-error-bro-1"></div>
        <div class="button">
            <div class="label" (click)="goToHome()">Go to Home</div>
        </div>
    </div>
    <div class="logo"><img class="unknown-3" src="/assets/common/images/onepact_logo_light-1.png" alt="OnePact" /></div>
</div>
